import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { validatePresence } from 'ember-changeset-validations/validators';
import error from 'boel/utils/error';
import ENV from 'boel/config/environment';
export default class AuthSuperbrugerIndstillingerArtiklerNewController extends Controller
{
  validations = {
    title: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    content: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
  };
  @service notifications;
  @service router;
  @service intl;

  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    yield changeset
      .save()
      .then(() =>
      {
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(this.intl.t('artikler.edit_success'));
        }
        this.router.transitionTo('auth.superbruger.indstillinger.artikler', {
          queryParams: { refresh: Date.now() },
        });
      })
      .catch((err) =>
      {
        const Message = error.get_message(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          }),
        );
      });
  }

  @action
  cancel()
  {
    this.model.unloadRecord();
    this.router.transitionTo('auth.superbruger.indstillinger.artikler');
  }
}
