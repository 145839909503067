import Service from '@ember/service';
import { inject } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
export default class FavoritterService extends Service {
  Favoritter;
  Favorittyper;
  Last_Modified;

  @inject store;

  constructor() {
    super(...arguments);

    this.FetchFavorites.perform();
    this.FetchFavorittyper.perform();
  }
  Update() {
    this.FetchFavorites.perform();
  }
  Find_Favorittype_Id(favorittype) {
    if (this.Favorittyper) {
      let fundne_favorittyper = this.Favorittyper.find(
        (element) => element.title == favorittype,
      );
      if (fundne_favorittyper) {
        return parseInt(fundne_favorittyper.id, 10);
      }
    }
    return -1;
  }
  @task
  @waitFor
  *FetchMyFavorites() {
    while (!this.FetchFavorites.isIdle) {
      yield timeout(1000);
    }
    if (this.Favoritter) {
      return this.Favoritter;
    }
  }
  @task
  @waitFor
  *FetchFavoritesInfo(favorittype) {
    while (!this.FetchFavorittyper.isIdle) {
      yield timeout(1000);
    }

    let favorit_type_id = this.Find_Favorittype_Id(favorittype);

    while (!this.FetchFavorites.isIdle) {
      yield timeout(1000);
    }

    if (this.Favoritter && favorit_type_id != -1) {
      return this.Favoritter.filter(
        (element) => element.favorittypeId === favorit_type_id,
      ).sort(function (a, b) {
        return a.id > b.id;
      });
    }
    return [];
  }
  @task
  @waitFor
  *createFavorite(favorittype, favoritid) {
    let Favorit_Id = parseInt(favoritid, 10);

    let favorit_type_id = this.Find_Favorittype_Id(favorittype);

    let favorit_type = this.Favorittyper.find(
      (element) => element.id == favorit_type_id,
    );

    if (favorit_type_id > 0 && favorit_type) {
      let Ny_Favorit = yield this.store.createRecord('favoritter', {
        favoritId: Favorit_Id,
        favorittypeId: favorit_type_id,
        favoritType: favorit_type,
        userId: -1,
      });
      yield Ny_Favorit.save();
      yield this.Update();
    }
  }
  @task
  @waitFor
  *destroyFavorite(Favorit) {
    yield Favorit.destroyRecord();
    this.Update();
  }
  @task
  @waitFor
  *FetchFavorite(favorittype, favoritid) {
    while (!this.FetchFavorittyper.isIdle) {
      yield timeout(1000);
    }
    let favorit_type_id = this.Find_Favorittype_Id(favorittype);

    if (this.Favoritter && favoritid) {
      let favoritid_int = parseInt(favoritid, 10);

      while (!this.FetchFavorites.isIdle) {
        yield timeout(1000);
      }

      return this.Favoritter.find(
        (element) =>
          element.favorittypeId == favorit_type_id &&
          element.favoritId == favoritid_int,
      );
    }
    return null;
  }

  @task({ drop: true })
  @waitFor
  *FetchFavorites() {
    let result = yield this.store.findAll('favoritter', {
      include: 'favorit-type',
    });
    if (result) {
      let tempResult = [];
      for (let i = 0; i < result.length; i++) {
        let obj = result.objectAt(i);
        tempResult.push(obj);
      }
      this.Favoritter = tempResult;
    }
    this.Last_Modified = new Date().getTime();
  }

  @task({ drop: true })
  @waitFor
  *FetchFavorittyper() {
    let result = yield this.store.findAll('favorittyper');
    if (result) {
      this.Favorittyper = [];
      for (let i = 0; i < result.length; i++) {
        let obj = result.objectAt(i);
        this.Favorittyper.push(obj);
      }
    }
  }
}
