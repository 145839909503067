import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { validatePresence } from 'ember-changeset-validations/validators';
import { task } from 'ember-concurrency';
import { debug } from '@ember/debug';
import error from 'boel/utils/error';
import DateUtils from 'boel/utils/DateUtils';
import ENV from 'boel/config/environment';
export default class AuthPatienterInsurancecasesEditController extends Controller
{
  @service router;
  @service intl;
  @service notifications;

  validation = {
    name: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    company: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    identifier: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    start: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    end: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
  };

  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    if (Array.isArray(changeset.get('start')))
    {
      changeset.set('start', changeset.get('start')[0]);
    }
    // Server does not handle timezone in date
    // and thus the timezoneoffset needs to be
    // added to the datetime.
    changeset.set('start', DateUtils.AddTimezoneOffset(changeset.get('start')));

    if (Array.isArray(changeset.get('end')))
    {
      changeset.set('end', changeset.get('end')[0]);
    }
    // Server does not handle timezone in date
    // and thus the timezoneoffset needs to be
    // added to the datetime.
    changeset.set('end', DateUtils.AddTimezoneOffset(changeset.get('end')));

    try
    {
      yield changeset
        .save()
        .then(() =>
        {
          if (ENV.APP.ShowNotifications) {
            this.notifications.success(
              this.intl.t('patienter.insurancecase_edit_success'),
            );
          }
          this.router.transitionTo('auth.superbruger.bills', {
            queryParams: { refresh1: Date.now() },
          });
        })
        .catch((err) =>
        {
          const Message = error.get_message(err);
          this.notifications.error(
            this.intl.t('error.generic', {
              message: Message,
            }),
          );
        });
    } catch (Err)
    {
      debug(Err);
    }
  }
  @action
  cancel()
  {
    this.router.transitionTo('auth.superbruger.bills');
  }
}
