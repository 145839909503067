import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
export default class AuthAftalerBetalingerController extends Controller
{
  queryParams = ['refresh1'];
  @tracked refresh1;
  @service router;

  @action
  paid_all()
  {
    this.model.payments.forEach((element) =>
    {
      if (!element.paid)
      {
        element.paid = true;
        element.time = new Date();
        element.save();

        element.appointment.paid = element.paid;
        element.appointment.save();
      }
    });
  }
  @action
  print()
  {
    window.print();
  }
  @action
  cancel()
  {
    this.router.transitionTo('auth.aftaler');
  }
}
