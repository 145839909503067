/* import __COLOCATED_TEMPLATE__ from './inline-payments.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AppInlinePaymentsComponent extends Component
{
  @service store;
  @service currentUser;
}
