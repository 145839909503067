import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthRoute extends Route
{
  @service session;
  @service currentUser;
  @service intl;

  async beforeModel(transition) {
    await this.session.requireAuthentication(transition, 'login');
  }
}
