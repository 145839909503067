import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthPatienterAftalerDeleteRoute extends Route {
  @service store;

  model(params) {
    return this.store.findRecord('appointment', params.appointment_id, {
      include: 'outlet,patient,treatment,problem,action,appointmentstate,room',
    });
  }
}
