import Model, { attr } from '@ember-data/model';

export default class BehandlingsstatusModel extends Model {
  @attr('string', { defaultValue: '' })
  name;

  @attr
  created;

  @attr
  updated;
}
