import { helper } from '@ember/component/helper';

export default helper(function truncateCPR(positional /*, named*/) {
  var str = positional[0];
  var len = positional[1] ?? 6;

  if (str && str.length > len) {
    var new_str = str.substr(0, len + 1);

    while (new_str.length) {
      var ch = new_str.substr(-1);
      new_str = new_str.substr(0, -1);

      if (ch == ' ') {
        break;
      }
    }
    if (new_str == '') {
      new_str = str.substr(0, len);
    }

    return new_str + '-XXXX';
  }

  return str;
});
