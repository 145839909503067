import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import
  {
    validatePresence,
    validateFormat,
    validateLength,
  } from 'ember-changeset-validations/validators';
import error from 'boel/utils/error';
import ENV from 'boel/config/environment';

export default class AuthSuperbrugerIndstillingerAfdelingerEditController extends Controller
{
  @service notifications;
  @service intl;
  @service router;
  @service currentUser;

  validations = {
    name: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    addressStreet1: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    postalCode: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    city: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    phone: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    emailGeneral: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
      validateFormat({
        type: 'email',
        allowBlank: false,
        message: this.intl.t('validations.email', {
          description: 'Emailen',
        }),
      }),
    ],
    vatNumber: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
      validateLength({
        min: 8,
        max: 8,
        allowBlank: true,
        message: this.intl.t('validations.wrongLength', {
          description: 'CVR nummeret',
          is: 8,
        }),
      }),
    ],
  };
  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    yield changeset
      .save()
      .then((outlet) =>
      {
        if (this.currentUser.Is_CurrentActiveOutlet(outlet.id))
        {
          this.currentUser.Set_Colors(outlet);
        }
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(this.intl.t('afdelinger.edit_success'));
        }
        this.router.transitionTo('auth.superbruger.indstillinger.afdelinger', {
          queryParams: { refresh: Date.now() },
        });
      })
      .catch((err) =>
      {
        const Message = error.get_message(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          }),
        );
      });
  }
  @action
  cancel()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.afdelinger');
  }
}
