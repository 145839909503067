import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class AuthKabinestyringAftalerController extends Controller
{
  @service router;
  @service store;

  @action
  confirm_all()
  {
    this.model.aftaler.forEach((element) =>
    {
      if (!element.confirmed)
      {
        element.confirmed = true;
        element.save();
      }
    });
  }
  @action
  email()
  {
    //window.print();
  }
  @action
  print()
  {
    window.print();
  }
  @action
  cancel()
  {
    this.router.transitionTo('auth.kabinestyring');
  }
}
