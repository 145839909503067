/* import __COLOCATED_TEMPLATE__ from './manual-form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ENV from '../../config/environment';
import { task } from 'ember-concurrency';

export default class ManualForm extends Component {
  @task
  *uploadfile(file) {
    let response = yield file.upload(
      ENV.APP.namespaceRoot + '/uploadImage.ashx',
    );
    this.args.manual.file = response.body;
  }

  @action
  uploadDocument(file) {
    this.uploadfile.perform(file);
  }
}
