import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthAftalerInsurancecasesRoute extends Route {
  @service store;
  @service currentUser;

  queryParams = {
    refresh1: {
      refreshModel: true,
    },
  };
  model(params) {
    let patient_id = params.patient_id;

    return hash({
      insurancecases: this.store.query('insurancecase', {
        filter: "equals(patient.id,'" + patient_id + "')",
        include: 'patient,company,problem',
        sort: '-created',
      }),
      patient: this.store.findRecord('patient', patient_id),
    });
  }
}
