import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthAftalerBetalingerRoute extends Route {
  @service store;
  @service currentUser;
  queryParams = {
    refresh3: {
      refreshModel: true,
    },
    refresh1: {
      refreshModel: true,
    },
  };
  model(params) {
    let patient_id = params.patient_id;

    return hash({
      payments: this.store.query('payment', {
        filter: "equals(patient.id,'" + patient_id + "')",
        include: 'patient,outlet,appointment,appointment.patient,appointment.outlet,appointment.appointmentstate,appointment.action,appointment.room,appointment.problem,appointment.treatment,appointment.behandlingsstatus',
        sort: '-time',
      }),
      patient: this.store.findRecord('patient', patient_id),
    });
  }
}
