import DateUtils from 'boel/utils/DateUtils';
/**
 * Payment related helper functions
 */
export default class betalinger {
  /**
   * Determines whether a payment is insured and returns case, if it is.
   * @param {payment} payment
   * @param {insurancecase[]} insurance_cases
   * @returns {has_insurancecase, insurancecase}
   */
  static get_is_insured2(
    payment,
    insurance_cases,
    payments,
    is_appointment = false,
  ) {
    let has_insurancecase = false;
    let insurancecase = null;

    let time = is_appointment ? payment.time : payment.appointment.time;
    time = DateUtils.getDateWithoutTime(time);
    insurance_cases.forEach((aCase) => {
      if (
        time >= DateUtils.getDateWithoutTime(aCase.start) &&
        time <= DateUtils.getDateWithoutTime(aCase.end) &&
        payment.patient.id === aCase.patient.id
      ) {
        if (payments) {
          let counter = 0;
          let aTime;
          payments.forEach((p) => {
            aTime = is_appointment ? p.time : p.appointment.time;
            if (aTime < time) {
              if (
                aTime >= DateUtils.getDateWithoutTime(aCase.start) &&
                aTime <= DateUtils.getDateWithoutTime(aCase.end)
              ) {
                counter++;
              }
            }
          });
          if (counter < aCase.amount) {
            insurancecase = aCase;
            has_insurancecase = true;
          }
        } else {
          insurancecase = aCase;
          has_insurancecase = true;
        }
      }
    });

    return {
      has_insurancecase: has_insurancecase,
      insurancecase: insurancecase,
    };
  }
  static get_is_insured(
    payment,
    insurance_cases,
    payments,
    is_appointment = false,
  ) {
    // Gratis betalinger tæller ikke som forsikret
    if (payment.amount != 0) {
      if (insurance_cases && payments) {
        var cloned_payments = [];
        let antal = 0;
        let forsikring;

        for (let i2 = 0; i2 < payments.length; i2++) {
          let aClone = {
            id: payments[i2].id,
            paid: payments[i2].paid,
            amount: payments[i2].amount,
            insurancecase: payments[i2].insurancecase,
            time: is_appointment
              ? payments[i2].time
              : payments[i2].appointment.time,
          };
          cloned_payments.push(aClone);
        }
        cloned_payments = cloned_payments.sort((a, b) => {
          if (a.time)
          {
            return a.time.getTime() - b.time.getTime();
          }
          else {
            console.log("sort error: ",a,b);
            return false;
          }
        });
        
        insurance_cases = insurance_cases.slice();
        insurance_cases = insurance_cases.sort((a, b) => {
          return a.start.getTime() - b.start.getTime();
        });

        for (let i = 0; i < insurance_cases.length; i++) {
          forsikring = insurance_cases[i];
          antal = 0;
          for (let i2 = 0; i2 < cloned_payments.length; i2++) {
            if (cloned_payments[i2].insurancecase) {
              if (cloned_payments[i2].insurancecase.id == forsikring.id) {
                antal++;
              }
            }
          }
          for (let i2 = 0; i2 < cloned_payments.length; i2++) {
            if (antal >= forsikring.amount) {
              break;
            }
             // Gratis betalinger tæller ikke som forsikret
            if (cloned_payments[i2].amount != 0) 
            {
              if (cloned_payments[i2].insurancecase == null)
              {
                let inside_interval = DateUtils.In_DateInterval(
                  cloned_payments[i2].time,
                  forsikring.start,
                  forsikring.end,
                );
                if (
                  !cloned_payments[i2].has_insurancecase &&
                  inside_interval &&
                  !cloned_payments[i2].paid
                )
                {
                  if (cloned_payments[i2].id == payment.id) {
                    // Fundet betalingen
                    return {
                      insurancecase: forsikring,
                      has_insurancecase: true,
                    };
                  } 
                  else 
                  {
                    cloned_payments[i2].has_insurancecase = true;
                    antal = antal + 1;
                  }
                }
              }
              else {
                if (cloned_payments[i2].id == payment.id) {
                  return {
                    insurancecase: cloned_payments[i2].insurancecase,
                    has_insurancecase: true,
                  };
                }
              }
            }
          }
        }
      }
    }
    return {
      insurancecase: undefined,
      has_insurancecase: false,
    };
  }
  /**
   * Determines which background color a payment should have
   * Requires has_insurancecase to be defined on payment
   * @param {payment} payment
   * @returns {string} background class
   */
  static get_background_color(payment) {
    let background_class = '';
    if (payment.paid) {
      background_class = 'betalinger-betalt';
    } else if (payment.has_insurancecase || payment.insurancecase) {
      background_class = 'betalinger-forsikret-ikkebetalt';
    }
    return background_class;
  }
}
