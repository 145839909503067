import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';
export default class AuthSuperbrugerIndstillingerController extends Controller
{
  @service currentUser;

  @action
  focus_list()
  {
    var objects = document.querySelector('.table .bottom > a');
    if (objects)
    {
      objects.firstChild.focus();
    }
  }
}
