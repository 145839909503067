import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';
export default class AuthPatienterEditRoute extends Route {
  @service store;
  @service currentUser;

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);
    controller.set('chosenOutlet', model.patient.outlet);
  }
  async model(params) {
    
    const the_patient = await this.store.findRecord('patient', params.patient_id, {
      include: 'outlet,problem',
    });
    const all_problems = await this.store.findAll('problem');
    
    return hash({
      patient: the_patient,
      problems: all_problems,
    });
  }
}
