/* import __COLOCATED_TEMPLATE__ from './data-loader.hbs'; */
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import Component from '@glimmer/component';

const DEBOUNCE_MS = 500;

export default class DataLoader extends Component {
  @service store;

  lastFilterParams;
  
  async load()
  {
    let params = this.args.params;
    let params2 = this.args.params2;
    if (this.args.page) {
      Object.assign(params, { 'page[number]': this.args.page });
    }
    if (this.args.pagesize)
    {
      Object.assign(params, { 'page[size]': this.args.pagesize });
    }
    let items = await this.store.query(this.args.modelName, params);
   
    let items2 = null;
    if (this.args.modelName2)
    {
      items2 = await this.store.query(this.args.modelName2, params2);
    }
    let total = 0;

    if (items?.meta?.total) {
      total += items.meta.total;
    }
    if (items2?.meta?.total) {
      total += items2.meta.total;
    }
    if ((items?.meta?.total || items2?.meta?.total) && this.args.complete) {
      this.args.complete(total);
    }
    if (items?.meta && items2?.meta) {
      if (this.args.complete) {
        total = items.meta['total-resources'];
        total += items2.meta['total-resources'];
        this.args.complete(total);
      }
    }
    let itemsTotal = items;

    if (items2)
    {
      items2.forEach((element2) =>
      {
        var found = false;
        items.forEach((element) =>
        {
          if (element.id == element2.patient.id)
          {
            found = true;
          }
        });
        if (!found)
        {
          itemsTotal.push(element2.patient);
        }
      });
    }
    return itemsTotal;
  }

  @task({
    restartable: true,
  })
  *update()
  {
    if (!this.args.nodelay)
    {
      yield timeout(DEBOUNCE_MS);
    }
    return this.load();
  }
}
