import Service from '@ember/service';
import { inject } from '@ember/service';
import stringutils from 'boel/utils/stringutils';
import ENV from 'boel/config/environment';

export default class ConfirmationService extends Service {
  @inject store;
  @inject intl;
  @inject notifications;
  @inject session;

  Can_Be_Emailed(appointment) {
    const mail_format =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const mail = appointment.patient.email;
    return mail && mail.match(mail_format);
  }
  Can_Be_SMSed(appointment) {
    const cellphone_format = /^([+]\d{2})?\d{8}$/;

    const sms = appointment.patient.cellphone;
    return sms && sms.match(cellphone_format);
  }
  Can_Be_Confirmed(appointment) {
    return this.Can_Be_SMSed(appointment) || this.Can_Be_Emailed(appointment);
  }
  async Read_Messages(appointment, token, payment) {
    let result = '';
    const outlet_id = appointment.outlet.id;
    const now = new Date();
    let payment_amount = "";
    let payment_valuta = "";
    if (payment) {
      payment_amount = payment.amount;
      payment_valuta = payment.valuta;
    }
    let templateOptions = {
      '[OUTLETNAME]': appointment.outlet.name,
      '[OUTLETCVR]': appointment.outlet.vatNumber,
      '[OUTLETADDRESS1]': appointment.outlet.addressStreet1,
      '[OUTLETADDRESS2]': appointment.outlet.addressStreet2,
      '[OUTLETPOSTALCODE]': appointment.outlet.postalCode,
      '[OUTLETCITY]': appointment.outlet.city,
      '[OUTLETPHONE]': appointment.outlet.phone,
      '[OUTLETEMAIL]': appointment.outlet.emailGeneral,
      '[APPOINTMENTNUMBER]': '' + appointment.id,
      '[APPOINTMENTTIME]': new Intl.DateTimeFormat('da-DK', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(appointment.time),
      '[APPOINTMENTDATE]': new Intl.DateTimeFormat('da-DK').format(
        appointment.time,
      ),
      '[PAYMENTAMOUNT]': payment_amount,
      '[PAYMENTVALUTA]': payment_valuta,
      '[PATIENTFIRSTNAME]': appointment.patient.firstname,
      '[PATIENTLASTNAME]': appointment.patient.lastname,
      '[PATIENTFULLNAME]': appointment.patient.fullname,
      '[PATIENTEMAIL]': appointment.patient.email,
      '[PATIENTCELLPHONE]': appointment.patient.cellphone,
      '[PATIENTPHONE]': appointment.patient.telephone,
      '[PATIENTADDRESS]': appointment.patient.address,
      '[PATIENTPOSTALCODE]': appointment.patient.postalcode,
      '[PATIENTCITY]': appointment.patient.city,
      '[PATIENTCOUNTRY]': appointment.patient.country,
      '[NUTIME]': new Intl.DateTimeFormat('da-DK', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(now),
      '[NUDATE]': new Intl.DateTimeFormat('da-DK').format(now),
    };
    if (payment) {

    }
    const messages = await this.store.query('message', {
      filter: "equals(token,'" + token + "')",
      include: 'outlet',
      sort: '-outlet.id',
    });
    if (messages && Array.isArray(messages) && messages.length > 0) {
      messages.forEach((message) => {
        if (message.outlet == null || message.outlet.id == outlet_id) {
          result = stringutils.Udfyld_Skabelon(
            message.indhold,
            templateOptions,
          );
        }
      });
    }
    return result;
  }
  async MakeConfirm(appointmentid, email, sms, betalingskvittering) {
    var kvittering_link = ENV.APP.namespaceRoot;
    if (ENV.APP.namespace) {
      kvittering_link += '/' + ENV.APP.namespace;
    }    
    if (betalingskvittering)
    {
      kvittering_link += '/betalings_kvittering';
    }
    else {
      kvittering_link += '/appointment_kvittering';
    }
    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };
    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    let link = kvittering_link + '?appointment_id=' + appointmentid;
    if (email) {
      link = link + '&email=' + email;
    }
    if (sms) {
      link = link + '&sms=' + sms;
    }
    let response = await fetch(link, {
      dataType: 'text',
      method: 'GET',
      headers: headers,
      cache: 'no-cache',
    });
    if (response.ok) {
      if (ENV.APP.ShowNotifications) {
        this.notifications.success(
          this.intl.t('form_appointment.email_send_success'),
        );
      }
    } else {
      this.notifications.error(this.intl.t('form_appointment.email_send_fejl'));
    }
  }
  async confirm(appointment) {
    if (this.Can_Be_Confirmed(appointment)) {
      this.MakeConfirm(
        appointment.id,
        appointment.patient.email,
        appointment.patient.cellphone,
      );
    }
  }
}
