/* import __COLOCATED_TEMPLATE__ from './time.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked, cached } from '@glimmer/tracking';
import stringutils from '../../utils/stringutils';

export default class FilterTimeComponent extends Component {
  @service store;

  @tracked openinghours = [];

  @tracked selectedHour = null;
  @tracked selectedMinutes = null;

  @action
  async fetch_opening_hours() {
    this.openinghours = await this.store.query('openinghour', {
      filter: "equals(outlet.id,'" + this.args.outletid + "')",
    });
  }
  @action
  handle_time() {
    let tidspunkt = this.args.time;

    if (tidspunkt && this.Is_Valid(tidspunkt)) {
      this.selectedHour = stringutils.padLeadingZeros(tidspunkt.getHours(), 2);

      this.selectedMinutes = stringutils.padLeadingZeros(
        tidspunkt.getMinutes(),
        2,
      );
      this.args.validate(this.selectedMinutes ? true : false);
    } else {
      this.selectedHour = null;
      this.selectedMinutes = null;
      this.args.validate(false);
    }
  }
  @action
  insert() {
    this.handle_time();
    this.fetch_opening_hours();
  }
  @action
  update() {
    this.handle_time();
    this.fetch_opening_hours();
  }
  Is_weekday(tidspunkt, openinghour) {
    let result = false;
    let weekday = tidspunkt.getDay();

    switch (weekday) {
      case 0:
        result = openinghour.hassunday;
        break;
      case 1:
        result = openinghour.hasmonday;
        break;
      case 2:
        result = openinghour.hastuesday;
        break;
      case 3:
        result = openinghour.haswednesday;
        break;
      case 4:
        result = openinghour.hasthursday;
        break;
      case 5:
        result = openinghour.hasfriday;
        break;
      case 6:
        result = openinghour.hassaturday;
        break;
      default:
        result = false;
        break;
    }
    return result;
  }
  @action
  Is_Valid_Time(tidspunkt, openinghour, hour) {
    let result = false;
    let newDate = new Date(tidspunkt.getTime());
    if (openinghour) {
      // Valid start tidspunkt
      if (openinghour.startdate) {
        if (tidspunkt < openinghour.startdate) {
          return false;
        }
      }
      // Valid slut tidspunkt
      if (openinghour.enddate) {
        if (tidspunkt > openinghour.enddate) {
          return false;
        }
      }
      tidspunkt.setSeconds(0);
      // Valid klokkeslet
      newDate.setHours(openinghour.starttime.getHours());
      newDate.setMinutes(openinghour.starttime.getMinutes());
      newDate.setSeconds(0);

      if (hour)
      {
        result = tidspunkt.getHours() >= newDate.getHours();
      }
      else
      {
        result = tidspunkt.getHours() > newDate.getHours() ||
        (
          (tidspunkt.getHours() === newDate.getHours()) && 
          (tidspunkt.getMinutes() >= newDate.getMinutes())
        );
      }

      newDate.setHours(openinghour.endtime.getHours());
      newDate.setMinutes(openinghour.endtime.getMinutes());

      if (hour)
      {
        result = result && (tidspunkt.getHours() <= newDate.getHours());
      }
      else
      {
        result = result && 
        (
          (tidspunkt.getHours() < newDate.getHours()) ||
          (
            (tidspunkt.getHours() === newDate.getHours()) && 
            (tidspunkt.getMinutes() <= newDate.getMinutes())
          )
        );
      }
    }
    return result;
  }
  @action
  Is_Valid(tidspunkt, hours) {
    if (!this.args.disableHours) {
      return true;
    }
    let is_valid = false;
    this.openinghours.slice().forEach((openinghour) => {
      is_valid =
        is_valid ||
        (this.Is_weekday(tidspunkt, openinghour) &&
          this.Is_Valid_Time(tidspunkt, openinghour, hours));
    });

    return is_valid;
  }
  @action
  selectHours(hour) {
    this.selectedHour = hour.target.value;
    this.selectedMinutes = null;
    this.args.validate(false);
  }
  @action
  selectMinutes(minutes) {
    this.selectedMinutes = minutes.target.value;

    let tidspunkt = this.args.time;

    if (tidspunkt) {
      if (this.selectedHour) {
        const parsedHour = parseInt('' + this.selectedHour, 10);
        tidspunkt.setHours(parsedHour);
      }
      if (this.selectedMinutes) {
        const parsedMinutes = parseInt('' + this.selectedMinutes, 10);
        tidspunkt.setMinutes(parsedMinutes);
      }
      this.args.onChange(tidspunkt);
      this.args.validate(true);
    }
  }
  @cached
  get minutes() {
    let minutes = [];
    let selectedHour = this.selectedHour;

    if (this.args.time && selectedHour) {
      let tidspunkt = new Date(this.args.time.getTime());
      if (tidspunkt) {
        tidspunkt.setHours(selectedHour);
        for (let i = 0; i <= 55; i += 5) {
          tidspunkt.setMinutes(i);
          const disabled = !this.Is_Valid(tidspunkt);
          minutes.push({
            d: disabled,
            v: stringutils.padLeadingZeros(i, 2),
          });
        }
      }
    } else {
      for (let i = 0; i <= 55; i += 5) {
        minutes.push({
          d: true,
          v: stringutils.padLeadingZeros(i, 2),
        });
      }
    }
    return minutes;
  }
  @cached
  get hours() {
    let hours = [];
    if (this.args.time) {
      let tidspunkt = new Date(this.args.time.getTime());
      tidspunkt.setMinutes(0);
      for (let i = 0; i < 24; i++) {
        tidspunkt.setHours(i);
        if (this.Is_Valid(tidspunkt, true)) {
          hours.push({
            v: stringutils.padLeadingZeros(i, 2),
          });
        }
      }
    }
    return hours;
  }
}
