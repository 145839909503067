import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { validatePresence } from 'ember-changeset-validations/validators';
import { task } from 'ember-concurrency';
import { debug } from '@ember/debug';
import ENV from 'boel/config/environment';
import error from 'boel/utils/error';
export default class AuthAftalerBetalingerNewController extends Controller
{
  @service router;
  @service intl;
  @service notifications;

  validation = {
    amount: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
  };

  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    let patient_id = this.router.currentRoute.parent.params.patient_id;

    try
    {
      yield changeset
        .save()
        .then(() =>
        {
          if (ENV.APP.ShowNotifications) {
            this.notifications.success(this.intl.t('form_payment.new_success'));
          }
          this.router.transitionTo('auth.aftaler.betalinger', patient_id, {
            queryParams: { refresh1: Date.now() },
          });
        })
        .catch((err) =>
        {
          const Message = error.get_message(err);
          this.notifications.error(
            this.intl.t('error.generic', {
              message: Message,
            }),
          );
        });
    } catch (Err)
    {
      debug(Err);
    }
  }
  @action
  cancel()
  {
    this.model.newPayment.unloadRecord();
    let patient_id = this.router.currentRoute.parent.params.patient_id;
    this.router.transitionTo('auth.aftaler.betalinger', patient_id);
  }
}
