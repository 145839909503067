/* import __COLOCATED_TEMPLATE__ from './data-loader.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task, timeout } from 'ember-concurrency';

const DEBOUNCE_MS = 500;

export default class DataLoader extends Component {
  @service store;

  @task *load() {
    let params = this.args.params;
    if (this.args.page) {
      Object.assign(params, { 'page[number]': this.args.page });
    }
    if (this.args.pagesize)
    {
      Object.assign(params, { 'page[size]': this.args.pagesize });
    }

    let items = yield this.store.query(this.args.modelName, params);

    if (items?.meta?.total) {
      if (this.args.complete) {
        this.args.complete(items.meta.total);
      }
    }
    if (items?.meta) {
      if (this.args.complete) {
        this.args.complete(items.meta['total-resources']);
      }
    }
    return items;
  }

  @task({
    restartable: true,
  })
  *update()
  {
    yield timeout(DEBOUNCE_MS);
    return yield this.load.perform();
  }
}
