/* import __COLOCATED_TEMPLATE__ from './edit-markdown.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class EditMarkdownComponent extends Component {
  options;

  constructor() {
    super(...arguments);

    this.options = {
      spellChecker: false,
    };
  }
  @action
  onChange(res) {
    this.args.update(res);
  }
}
