import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { validatePresence } from 'ember-changeset-validations/validators';
import error from 'boel/utils/error';
import ENV from 'boel/config/environment';
export default class AuthSuperbrugerIndstillingerMessagesNewController extends Controller
{
  @service notifications;
  @service intl;
  @service router;
  @tracked chosenOutlet;

  validations = {
    name: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    token: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
  };

  @action
  choose(outlet)
  {
    this.chosenOutlet = outlet;
  }
  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    changeset.set('outlet', this.chosenOutlet);

    yield changeset
      .save()
      .then(() =>
      {
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(this.intl.t('messages.create_success'));
        }
        this.router.transitionTo('auth.superbruger.indstillinger.messages', {
          queryParams: { refresh: Date.now() },
        });
      })
      .catch((err) =>
      {
        const Message = error.get_message(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          }),
        );
      });
  }
  @action
  cancel()
  {
    this.model.unloadRecord();
    this.router.transitionTo('auth.superbruger.indstillinger.messages');
    return false;
  }
}
