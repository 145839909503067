import { tracked } from '@glimmer/tracking';
import DateUtils from 'boel/utils/DateUtils';

export default class Field {
  @tracked date;
  @tracked available;
  @tracked appointment;
  @tracked next_appointment;
  @tracked days;
  @tracked end_date;

  constructor(date, available, appointment, next_appointment) {
    this.date = date;
    this.available = available;
    this.appointment = appointment;
    this.next_appointment = next_appointment;

    if (!available && appointment != null) {
      this.days = appointment.bookingdays;
    } else {
      this.days = 1;
    }
    this.end_date = DateUtils.AddOrSubtractDays(this.date, this.days - 1, true);
  }
}
