import Model, { attr } from '@ember-data/model';

export default class LinkModel extends Model {
  @attr('string', { defaultValue: '' })
  titel;

  @attr('string', { defaultValue: '' })
  url;

  @attr('string', { defaultValue: '' })
  ikon;

  @attr('number')
  order;

  @attr
  created;

  @attr
  updated;
}
