/* import __COLOCATED_TEMPLATE__ from './article-form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class HelpArticleFormComponent extends Component {
  content;
  teaser;

  constructor() {
    super(...arguments);
    this.content = this.args.article.indhold ?? '';
    this.teaser = this.args.article.teaser ?? '';
  }
  @action
  updateContent(changedContent) {
    this.content = changedContent;
  }
  @action
  updateTeaser(changedTeaser) {
    this.teaser = changedTeaser;
  }
  @action
  save(changeset) {
    this.args.article.teaser = this.teaser;
    this.args.article.indhold = this.content;
    this.args.save(changeset);
  }
}
