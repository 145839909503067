/* import __COLOCATED_TEMPLATE__ from './outlet.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
export default class FilterOutletComponent extends Component {
  @action
  focus_select() {
    var objects = document.querySelector(
      '.filter_outlet .ember-basic-dropdown-trigger',
    );
    if (objects) {
      objects.firstChild.click();
    }
  }
}
