import Service, { inject as service } from '@ember/service';

export default class PreviousRouteService extends Service
{
  @service router;

  previousRouteName = null;
  previousRoute = null;
  
  constructor()
  {
    super(...arguments);
    this.router.on('routeWillChange', () =>
    {
      this.previousRoute = this.router.currentRoute;
      this.previousRouteName = this.router.currentRouteName;
    });
  }
  getPreviousRoute()
  {
    return this.previousRouteName;
  }
  getPreviousRouteInfo()
  {
    return this.previousRoute;
  }
}
