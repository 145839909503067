import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthPatienterJournalNewRoute extends Route {
  @service store;
  @service currentUser;

  async model(/* params */) {
    let patient_obj = this.modelFor('auth/aftaler/journal').patient;
    const outlet_id = this.currentUser.CurrentActiveOutletId;
    let journal_med_problem_array = await this.store.query('journal', {
      filter:
        "and(equals(patient.id,'" +
        patient_obj.id +
        "')" +
        ",not(or(equals(problemtext,null),equals(problemtext,''))))",
      sort: '-created',
    });
    let problem_text = '';
    if (journal_med_problem_array) {
      journal_med_problem_array = journal_med_problem_array.slice();
      if (journal_med_problem_array && journal_med_problem_array.length > 0) {
        problem_text = journal_med_problem_array[0].problemtext;
      }
    }
    return hash({
      newJournal: this.store.createRecord('journal', {
        patient: patient_obj,
        problemtext: problem_text,
      }),

      journaltypes: await this.store.findAll('journaltype'),
      therapists: await this.store.findAll('therapist', {
        filter: "equals(outlet.id,'" + outlet_id + "')",
        sort: 'name',
      }),
    });
  }
}
