import Model, { attr } from '@ember-data/model';

export default class PostalCodeModel extends Model {
  @attr('string', { defaultValue: '' })
  number;

  @attr('string', { defaultValue: '' })
  city;

  @attr('string', { defaultValue: '' })
  street;

  @attr('string', { defaultValue: '' })
  company;

  @attr('string', { defaultValue: '' })
  countryname;
}
