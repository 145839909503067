import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class converteddatetime extends Helper {
  @service intl;
  compute(positional) {
    var date_options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    var time_options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    if (positional && positional.length > 0) {
      let date = positional[0];
      return (
        this.intl.formatDate(date, date_options) +
        ' ' +
        this.intl.formatTime(date, time_options)
      );
    } else {
      return '';
    }
  }
}
