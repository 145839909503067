import Route from '@ember/routing/route';
import { service } from '@ember/service';
import ENV from 'boel/config/environment';

export default class AuthIndexRoute extends Route {
  @service session;
  @service router;
  @service currentUser;

  async beforeModel(transition) {
    if (transition && transition.to.name === 'auth.index') {
      if (this.currentUser.user.startroute) {
        this.router.transitionTo(this.currentUser.user.startroute);
      } else {
        if (this.currentUser.Is_Administrator) {
          this.router.transitionTo(ENV.APP.Admin_User_Start_Page);
        } else if (this.currentUser.Is_Superuser) {
          this.router.transitionTo(ENV.APP.Superuser_User_Start_Page);
        } else {
          this.router.transitionTo(ENV.APP.Normal_User_Start_Page);
        }
      }
    }
  }
}
