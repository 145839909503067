import { service } from '@ember/service';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import ENV from 'boel/config/environment';
import fetch from 'fetch';
import
  {
    isServerErrorResponse,
    isUnauthorizedResponse,
    isAbortError,
    isBadRequestResponse,
  } from 'ember-fetch/errors';
import { debug } from '@ember/debug';
export default class AuthSuperbrugerIndstillingerBrugereDeleteController extends Controller
{
  @service session;
  @service router;
  @service notifications;
  @service intl;

  @action
  delete()
  {
    const userName = this.model.userName;

    var headers = {
      'Content-Type': 'application/json',
    };
    if (userName)
    {
      if (this.session.isAuthenticated)
      {
        headers[
          'Authorization'
        ] = `Bearer ${this.session.data.authenticated.access_token}`;
      }

      fetch(ENV.APP.host + '/' + ENV.APP.namespace + '/auth/RemoveLogin', {
        headers: headers,
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
          Id: this.model.id,
          UserName: userName,
        }),
      })
        .then((response) =>
        {
          if (response.ok)
          {
            if (ENV.APP.ShowNotifications) {
              this.notifications.success(this.intl.t('brugere.delete_success'));
            }
            this.router.transitionTo('auth.superbruger.indstillinger.brugere', {
              queryParams: { refresh: Date.now() },
            });
          } else if (isUnauthorizedResponse(response))
          {
            this.notifications.info(this.intl.t('brugere.session_timeout'));
            this.session.invalidate();
          } else if (isServerErrorResponse(response))
          {
            this.notifications.error(this.intl.t('brugere.error'));
          } else if (isBadRequestResponse(response))
          {
            response.json().then((data) =>
            {
              const errorList = [];
              Object.keys(data).forEach((key) =>
              {
                if (!Object.hasOwnProperty(key))
                {
                  var value = data[key];
                  errorList.push(value);
                }
              });
              this.serverError = errorList;
            });
          } else if (isAbortError(response))
          {
            this.notifications.error(this.intl.t('retbruger.servererror'));
          }
        })
        .catch((error) =>
        {
          this.notifications.error(this.intl.t('brugere.netværks_fejl'));
          debug('Fejl under fetch: ');
          debug(error);
        });
    }
  }

  @action
  cancel()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.brugere');
  }
}
