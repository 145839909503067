import Model, { attr } from '@ember-data/model';

export default class InsuranceCompanyModel extends Model {
  @attr('string', { defaultValue: '' })
  name;

  @attr('number', { defaultValue: 10000 })
  price;

  @attr('string', { defaultValue: '' })
  economicid;

  @attr('string', { defaultValue: 'Sagsnummer' })
  referencenavn;
  
  @attr
  created;

  @attr
  updated;
}
