import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthSuperbrugerIndstillingerAfdelingerOpeninghoursRoute extends Route {
  @service store;
  queryParams = {
    refreshhours: {
      refreshModel: true,
    },
  };

  model(params) {
    return hash({
      opening_hours: this.store.query('openinghour', {
        filter: "equals(outlet.id,'" + params.outlet_id + "')",
        include: 'outlet',
        sort: 'starttime',
      }),
      outlet: this.store.findRecord('outlet', params.outlet_id),
    });
  }
}
