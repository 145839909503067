import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerAfdelingerOpeninghoursNewRoute extends Route
{
  @service store;
  @service currentUser;
  @service router;

  async model()
  {
    let parent_model = this.modelFor('auth/superbruger/indstillinger/afdelinger/openinghours');

    if (parent_model)
    {
      let newRecord = this.store.createRecord('openinghour', {
        starttime: new Date(),
        endtime: new Date(),
        outlet: parent_model.outlet,
      });
      return newRecord;
    }
  }
}
