import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';

export default class LogModel extends Model {
  @service intl;

  @attr('string', { defaultValue: '' })
  overskrift;

  @attr('string', { defaultValue: '' })
  kilde;

  @attr('string', { defaultValue: '' })
  status;

  @attr('string', { defaultValue: '' })
  besked;

  @attr
  tidspunkt;

  @attr('string', { defaultValue: '' })
  input;

  @attr('number', { defaultValue: 1 })
  statustype;

  @attr('number', { defaultValue: -1 })
  outletid;

  @attr('string', { defaultValue: '' })
  templatevalues;

  @attr('string', { defaultValue: '' })
  reference;
}
