import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerTherapistsNewRoute extends Route {
  @service currentUser;
  @service store;

  async model() {
    const outlet = await this.currentUser.CurrentActiveOutlet;

    return this.store.createRecord('therapist', {
      outlet: outlet,
    });
  }
}
