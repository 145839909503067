import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthKabinestyringJournalNewRoute extends Route {
  @service store;
  @service currentUser;

  async model() {
    const parent_params = this.paramsFor('auth.kabinestyring.journal');

    let patient_obj = await this.store.findRecord(
      'patient',
      parent_params.patient_id,
    );
    const outlet_id = this.currentUser.CurrentActiveOutletId;

    return hash({
      newJournal: await this.store.createRecord('journal', {
        patient: patient_obj,
      }),
      problems: await this.store.findAll('problem'),
      journaltypes: await this.store.findAll('journaltype'),
      therapists: await this.store.query('therapist', {
        filter: "equals(outlet.id,'" + outlet_id + "')",
        sort: 'name',
      }),
    });
  }
}
