import Service from '@ember/service';
import { inject } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'boel/config/environment';
import { action } from '@ember/object';

export default class KabinestyringhubService extends Service {
  @tracked connection;
  @tracked state;
  @tracked refresh;
  @tracked Redirecting = false;
  @inject notifications;
  @inject currentUser;
  debug = true;

  lockResolve;
  lockReject;

  unique_lock_name_prefix = 'Boel_Client';
  unique_lock_name;

  @action
  async close() {
    this.Redirecting = true;

    try {
      try {
        await this.lockResolve();
      } catch {}
      this.lockResolve = null;
      this.lockReject = null;
    } catch (err) {
      console.log('Catch: ' + err);
      await this.connection.invoke('log_on_server', 'close-exception', err);
    }

    this.connection.stop();
  }
  get Current_State() {
    var refresh = this.refresh;
    if (this.connection.q === 'Disconnected') {
      this.start();
    }
    return this.state;
  }
  get Connection() {
    return this.connection;
  }
  async start() {
    let that = this;

    if (that.debug) {
      console.log('Starting connection...');
    }

    try {
      await that.connection.start().then(function () {
        if (that.debug) {
          console.log('connection: ', that.connection);
          console.log(
            'Now connected, connection ID=' + that.connection.connectionId,
          );
        }
      });
    } catch (err) {
      if (that.debug) {
        console.error('Could not Connect!: ' + err);
      }
      setTimeout(() => that.start(), ENV.APP.RETRYINTERVALSECONDS);
    }
  }
  @action
  async invoke_action(Interval, Message, Outlet_ID) {
    console.log('invoke_action on server');

    try {
      await this.connection.invoke(
        'invoke_action',
        Interval,
        Message,
        Outlet_ID,
      );
    } catch (err) {
      console.log('Catch: ' + err);
      await this.connection.invoke('log_on_server', 'close-exception', err);
    }
  }
  Create_Connection() {
    let that = this;

    that.unique_lock_name =
      that.unique_lock_name_prefix + '_' + that.domain + '_' + that.key;

    let qs = 'outletid=' + that.currentUser.user.outletid;

    that.connection = new signalR.HubConnectionBuilder()
      .withUrl(ENV.APP.SIGNALRENDPOINT + '/kabinestyringHub?' + qs)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (that.Redirecting) {
            return null;
          } else {
            return 30000;
          }
        },
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();
    that.connection.keepAliveIntervalInMilliseconds = 1000 * 30;
    that.connection.serverTimeoutInMilliseconds = 1000 * 60 * 10;

    //
    // Event handlere
    //
    that.connection.onreconnecting((error) => {
      console.error(`Connection lost due to error "${error}". Reconnecting.`);
    });
    that.connection.onclose(async (error) => {
      if (!that.Redirecting) {
        console.error(
          `Connection closed due to error "${error}". Try refreshing this page to restart the connection.`,
        );
        await that.Create_Connection();
      }
    });

    //
    // Metoder
    //
    that.connection.on('notify', (message) => {
      console.log('notify_method: ' + message);
      that.notifications.info(message);
    });
    that.connection.on('log', (message) => {
      console.log('log_method: ' + message);
    });

    //
    // Start
    //
    that.start();
  }
  constructor() {
    super(...arguments);
    this.Create_Connection();

    // Web Lock to keep tab awake and avoid an unexpected connection closure.
    try {
      if (navigator && navigator.locks && navigator.locks.request) {
        const promise = new Promise((res, rej) => {
          this.lockResolve = res;
          this.lockReject = rej;
        });

        navigator.locks.request(
          this.unique_lock_name,
          (lock) => promise, // Now lock will be held until either resolve() or reject() is called.
        );
      }
    } catch (err) {
      console.log('Web Lock: ', err);
    }
  }
}
