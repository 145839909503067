/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ENV from '../../../config/environment';
import { tracked } from '@glimmer/tracking';
import { debug } from '@ember/debug';
import { validatePresence } from 'ember-changeset-validations/validators';
import fetch from 'fetch';
import {
  isServerErrorResponse,
  isUnauthorizedResponse,
} from 'ember-fetch/errors';

export default class LoginFormComponent extends Component {
  @service notifications;
  @service session;
  @service router;
  @service intl;

  @tracked errorMessage = '';

  identification = null;
  password = null;

  validations = {
    identification: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    password: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
  };

  @tracked visGlemtAdgangskode = false;
  @action
  toggle() {
    this.visGlemtAdgangskode = !this.visGlemtAdgangskode;
  }

  @action
  nulstil() {
    this.errorMessage = '';

    const nulstilLink = ENV.APP.namespace + '/api/Account/nulstilFase1';
    const brugerObj = { identification: this.identification };
    const bruger = brugerObj.identification;

    fetch(nulstilLink, {
      method: 'POST',
      body: JSON.stringify({
        data: {
          User: bruger,
        },
      }),
    })
      .then((response) => {
        if (response.ok) {
          this.router.transitionTo('nulstil', {
            queryParams: { emailSendt: true },
          });
        } else if (isUnauthorizedResponse(response)) {
          this.notifications.info(this.intl.t('login.session_timeout'));
          this.session.invalidate();
        } else if (isServerErrorResponse(response)) {
          this.notifications.error(this.intl.t('login.server_error'));
        }
      })
      .catch((error) => {
        this.notifications.error(this.intl.t('login.network_error'));

        debug('Fejl under fetch: ');
        debug(error);
      });
  }

  @action
  async authenticate(changeset) {
    let identification = changeset.get('identification');
    let password = changeset.get('password');

    try {
      await this.session.authenticate(
        'authenticator:oauth2',
        identification,
        password,
      );
    } catch (error) {
      if (error && error.status) {
        if (error.responseJSON) {
          this.errorMessage = error.responseJSON.error_description;

          if (!this.errorMessage) {
            this.errorMessage = error.responseJSON.error;
          }
        }
        if (error.status === 400) {
          if (!this.errorMessage) {
            this.errorMessage = this.intl.t('login.invalid_login');
          }
        } else if (error.status == 404) {
          if (!this.errorMessage) {
            this.errorMessage = this.intl.t('login.not_found');
          }
        } else if (error.status == 500) {
          if (!this.errorMessage) {
            this.errorMessage = this.intl.t('login.server_error');
          }
        } else {
          if (!this.errorMessage) {
            this.errorMessage = this.intl.t('login.unknown_error');
          }
        }
      } else {
        let Message = '' + (error.error || error);
        if (Message.includes('TypeError')) {
          Message = this.intl.t('login.type_error');
        }
        this.errorMessage = Message;
        debug(Message);
      }
    }
    if (this.session.isAuthenticated) {
      //this.router.transitionTo('auth.index');
    }
  }
}
