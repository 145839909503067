import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthAftalerNewnopatientRoute extends Route {
  @service store;
  @service currentUser;

  async model(/*params*/) {
    let user_outlet = await this.store.findRecord(
      'outlet',
      this.currentUser.CurrentActiveOutletId,
    );

    let amount = 0;
    let today = new Date();

    let newAppointment = await this.store.createRecord('appointment', {
      time: today,
      outlet: user_outlet,
      amount: amount,
    });

    const states = await this.store.findAll('appointmentstate');
    const treatments = await this.store.findAll('treatment');
    const problems = await this.store.findAll('problem');

    return hash({
      problems: problems,
      states: states,
      treatments: treatments,

      newAppointment: newAppointment,
      closedperiods: this.store.query('closedperiod', {
        filter: "equals(outlet.id,'" + user_outlet.id + "')",
      }),
      openinghours: this.store.query('openinghour', {
        filter: "equals(outlet.id,'" + user_outlet.id + "')",
      }),
    });
  }
}
