import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerBehandlingerNewRoute extends Route {
  @service store;

  model() {
    return this.store.createRecord('treatment');
  }
}
