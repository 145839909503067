import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthPatienterInsurancecasesEditRoute extends Route {
  
  @service store;

  async model(params) {

    const all_insurancefirms = await this.store.findAll('insurancefirm');
    
    const all_problems = await this.store.findAll('problem');
    
    return hash({
      insurancefirms: all_insurancefirms,
      problems: all_problems,
      insurancecase: this.store.findRecord(
        'insurancecase',
        params.insurancecase_id,
        {
          include: 'patient,problem,company',
        }
      ),
    });
  }
}
