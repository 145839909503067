import Service from '@ember/service';
import { inject } from '@ember/service';
import { action } from '@ember/object';

export default class PatienterService extends Service {
  @inject store;

  @action
  async cpr_search(cpr_nummer) {
    let found_patient = null;

    if (cpr_nummer) {
      let patienter = await this.store.query('patient', {
        filter: "equals(cpr,'" + cpr_nummer + "')",
        include: 'outlet,associations,associations.outlet',
      });
      patienter = patienter.slice();
      if (patienter && patienter.length > 0) {
        found_patient = patienter[0];
      }
    }
    return found_patient;
  }
  @action
  async cellphone_search(cellphone) {
    let found_patient = null;

    if (cellphone && cellphone.length >= 8) {
      let patienter = await this.store.query('patient', {
        filter: "equals(cellphone,'" + cellphone + "')",
        include: 'outlet,associations,associations.outlet',
      });

      patienter = patienter.slice();
      if (patienter && patienter.length > 0) {
        found_patient = patienter[0];
      }
    }
    return found_patient;
  }
  @action
  kan_tilknyttes(found_patient, currentUser) {
    let kan_tilknyttes = true;
    if (found_patient.outlet.id == currentUser.CurrentActiveOutletId) {
      kan_tilknyttes = false;
    } else {
      if (found_patient.associations && found_patient.associations.length > 0) {
        found_patient.associations.forEach((item) => {
          if (found_patient.outlet.id == item.outlet.id) {
            kan_tilknyttes = false;
          }
        });
      }
    }
    return kan_tilknyttes;
  }
}
