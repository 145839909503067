import Service from '@ember/service';
import { inject } from '@ember/service';
import stringutils from 'boel/utils/stringutils';

export default class KeymessageService extends Service
{
  @inject store;
  @inject intl;
  @inject session;
  
  async Read_Messages(appointment, room, token)
  {
    let result = '';
    const outlet_id = appointment.outlet.id;
    const now = new Date();

    let templateOptions = {
      '[OUTLETNAME]': appointment.outlet.name,
      '[OUTLETCVR]': appointment.outlet.vatNumber,
      '[OUTLETADDRESS1]': appointment.outlet.addressStreet1,
      '[OUTLETADDRESS2]': appointment.outlet.addressStreet2,
      '[OUTLETPOSTALCODE]': appointment.outlet.postalCode,
      '[OUTLETCITY]': appointment.outlet.city,
      '[OUTLETPHONE]': appointment.outlet.phone,
      '[OUTLETEMAIL]': appointment.outlet.emailGeneral,
      '[APPOINTMENTNUMBER]': '' + appointment.id,
      '[APPOINTMENTTIME]': new Intl.DateTimeFormat('da-DK', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(appointment.time),
      '[APPOINTMENTDATE]': new Intl.DateTimeFormat('da-DK').format(
        appointment.time,
      ),
      '[PATIENTFIRSTNAME]': appointment.patient.firstname,
      '[PATIENTLASTNAME]': appointment.patient.lastname,
      '[PATIENTFULLNAME]': appointment.patient.fullname,
      '[PATIENTEMAIL]': appointment.patient.email,
      '[PATIENTCELLPHONE]': appointment.patient.cellphone,
      '[PATIENTPHONE]': appointment.patient.telephone,
      '[PATIENTADDRESS]': appointment.patient.address,
      '[PATIENTPOSTALCODE]': appointment.patient.postalcode,
      '[PATIENTCITY]': appointment.patient.city,
      '[PATIENTCOUNTRY]': appointment.patient.country,
      '[NUTIME]': new Intl.DateTimeFormat('da-DK', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(now),
      '[NUDATE]': new Intl.DateTimeFormat('da-DK').format(now),
      '[ROOMNAME]': room.name,
      '[ROOMNAMESHORT]': room.nameShort,
    };

    const messages = await this.store.query('message', {
      filter: "equals(token,'" + token + "')",
      include: 'outlet',
      sort: '-outlet.id',
    });
    if (messages && Array.isArray(messages) && messages.length > 0)
    {
      messages.forEach((message) =>
      {
        if (message.outlet == null || message.outlet.id == outlet_id)
        {
          result = stringutils.Udfyld_Skabelon(
            message.indhold,
            templateOptions,
          );
        }
      });
    }
    return result;
  }
  
}
