import Route from '@ember/routing/route';
import { service } from '@ember/service';
export default class AuthSuperbrugerIndstillingerBrugereRoute extends Route {
  @service currentUser;
  @service router;

  beforeModel(/* transition */) {
    if (!this.currentUser.Is_Superuser) {
      this.router.transitionTo('auth.index');
    }
  }
  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    controller.set('chosenOutlet', this.currentUser.CurrentActiveOutlet);
    controller.set('outletId', this.currentUser.CurrentActiveOutletId);
  }
}
