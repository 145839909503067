import Route from '@ember/routing/route';
import { service } from '@ember/service';
import DateUtils from 'boel/utils/DateUtils';
import ENV from 'boel/config/environment';
export default class AuthSuperbrugerLogRoute extends Route {
  @service store;
  queryParams = {
    searchword: {
      refreshModel: true,
    },
    refresh: {
      refreshModel: true,
    },
    valgtStartdato: {
      refreshModel: true,
    },
    valgtSlutdato: {
      refreshModel: true,
    },
  };
  start;
  start_iso;
  slut;
  slut_iso;

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);
    controller.set('valgtStartdato', this.start_iso);
    controller.set('valgtStartdatoObj', this.start);
    controller.set('valgtSlutdato', this.slut_iso);
    controller.set('valgtSlutdatoObj', this.slut);
  }

  async model(params) {
    this.start = params.valgtStartdato;

    const default_period_length = ENV.APP.Logs_Default_Period_Length;

    if (!this.start) {
      this.start = DateUtils.AddOrSubtractDays(
        new Date(),
        default_period_length,
        false,
      );
    } else {
      this.start = new Date(this.start);
    }
    this.start_iso = DateUtils.Get_ISO_Date(this.start);
    this.slut = params.valgtSlutdato;
    if (!this.slut) {
      this.slut = new Date();
      this.slut = DateUtils.AddOrSubtractDays(this.slut, 1, true);
    } else {
      this.slut = new Date(this.slut);
    }
    this.slut_iso = DateUtils.Get_ISO_Date(this.slut);

    let search_string = '';
    if (params.searchword) {
      search_string =
        ",or(contains(overskrift,'" +
        params.searchword +
        "'),contains(input,'" +
        params.searchword +
        "'),contains(kilde,'" +
        params.searchword +
        "')";
    }
    search_string =
      "and(greaterOrEqual(tidspunkt,'" +
      this.start_iso +
      "'),lessThan(tidspunkt,'" +
      this.slut_iso +
      "')";

    if (params.searchword) {
      search_string =
        search_string +
        ",or(contains(overskrift,'" +
        params.searchword +
        "'),contains(input,'" +
        params.searchword +
        "'),contains(kilde,'" +
        params.searchword +
        "'))";
    }

    search_string = search_string + ')';

    return this.store.query('log', {
      filter: search_string,
      sort: '-tidspunkt',
    });
  }
}
