import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerRoute extends Route {
  @service router;
  @service currentUser;

  beforeModel(transition) {
    if (!this.currentUser.Is_Superuser) {
      this.router.transitionTo('auth.index');
    } else if (
      transition &&
      transition.to.name === 'auth.superbruger.indstillinger.index'
    ) {
      this.router.transitionTo('auth.superbruger.indstillinger.brugere');
    }
  }
}
