import Route from '@ember/routing/route';
import { service } from '@ember/service';
import DateUtils from 'boel/utils/DateUtils';
import ENV from 'boel/config/environment';

export default class AuthSuperbrugerBillsRoute extends Route {
  queryParams = {
    refresh: {
      refreshModel: true,
    },
    valgtStartdato: {
      refreshModel: false,
    },
    valgtSlutdato: {
      refreshModel: false,
    },
  };

  @service currentUser;
  @service store;

  isodate;
  outlet;

  async setupController(controller, model, transition) {
    super.setupController(controller, model, transition);
    
    const default_period_length = ENV.APP.Diagrams_Default_Period_Length;

    let start = null;
    let slut = null;

    if (!start) {
      start = DateUtils.AddOrSubtractDays(
        new Date(),
        default_period_length,
        false,
      );
    } else {
      start = new Date(start);
    }
    let start_iso = DateUtils.Get_ISO_Date(start);

    if (!slut) {
      slut = new Date();
      slut = DateUtils.AddOrSubtractDays(slut, 1, true);
    } else {
      slut = new Date(slut);
    }
    let slut_iso = DateUtils.Get_ISO_Date(slut);

    controller.set('valgtStartdato', start_iso);
    controller.set('valgtStartdatoObj', start);
    controller.set('valgtSlutdato', slut_iso);
    controller.set('valgtSlutdatoObj', slut);

    const outlet = await this.currentUser.CurrentActiveOutlet;
    const outletid = this.currentUser.CurrentActiveOutletId;
    
    controller.set('outletId', outletid);
    controller.set('chosenOutlet', outlet);
  }
  async model() {
    return await this.store.query('insurancecase', {
      include: 'patient,company,problem',
      sort: 'start',
    });
  }
}
