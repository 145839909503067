import Model, { attr } from '@ember-data/model';

export default class InsuranceCompanyModel extends Model {
  @attr('string', { defaultValue: '' })
  name;

  @attr('number', { defaultValue: 10000 })
  price;

  @attr('string', { defaultValue: '' })
  economicId;

  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  created;

  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  updated;
}