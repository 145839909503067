/* import __COLOCATED_TEMPLATE__ from './inline-add-insurancecase.hbs'; */
import Component from '@glimmer/component';
import { tracked, cached } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { validatePresence } from 'ember-changeset-validations/validators';
import lookupValidator from 'ember-changeset-validations';
import { Changeset } from 'ember-changeset';
import error from 'boel/utils/error';
import DateUtils from 'boel/utils/DateUtils';
import { action } from '@ember/object';
import ENV from 'boel/config/environment';

export default class AppInlineAddInsurancecaseComponent extends Component
{
  @service store;
  @service intl;
  @service notifications;
  
  @tracked insurancecase;
  
  validation = {
    company: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    identifier: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    start: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    end: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
  };
  changeset;
  constructor()
  { 
    super(...arguments)
    this.update();
  }
  @action
  tilbage()
  {
    this.changeset.validate();

    if (!this.changeset.isDirty || this.changeset.isValid)
    {
      this.args.tilbage();
    }
  }
  @action
  update()
  {
    if (this.args.isNew)
    {
      this.insurancecase = this.store.createRecord('insurancecase', {
        patient: this.args.patient,
        start: new Date(),
        end: DateUtils.AddOrSubtractDays(new Date(), 184, true)
      });
    }
    else
    {
      this.insurancecase = this.args.insurancecase;
    }
    this.changeset = Changeset(this.insurancecase, lookupValidator(this.validation), this.validation);
  }
  @action
  before_unload(event)
  {
    if (this.changeset.get('isDirty'))
    {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    }
  }
  @action
  will_destroy()
  {
    window.removeEventListener('beforeunload', this.before_unload);

    if (this.changeset.isDirty)
    { 
      this.save();
    }
  }
  @action
  choose_company(el, form, new_value)
  { 
    el.setValue(new_value);
    this.changeset.set('company', new_value);
    this.changeset.set('price', new_value.price);
  }
  @cached
  get labelname()
  {
    let label = this.intl.t("insurance_companies.default_reference_name");
    if (this.insurancecase.company && this.insurancecase.company.referencenavn)
    { 
      label = this.insurancecase.company.referencenavn;
    }
    return label + " *";
  }
  @action
  async save()
  {
    if (Array.isArray(this.changeset.get('start')))
    {
      this.changeset.set('start', this.changeset.get('start')[0]);
    }
    this.changeset.set('start', DateUtils.getDateWithoutTime(this.changeset.get('start')));
    if (Array.isArray(this.changeset.get('end')))
    {
      this.changeset.set('end', this.changeset.get('end')[0]);
    }
    this.changeset.set('end', DateUtils.getDateWithoutTime(this.changeset.get('end')));
    try
    {
      await this.changeset.save()
      if (this.args.isNew)
      {
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(
            this.intl.t('patienter.insurancecase_new_success'),
          );
        }
      }
      else
      { 
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(
            this.intl.t('patienter.insurancecase_edit_success'),
          );
        }
      }
    }
    catch(err)
    {
      const Message = error.get_message(err);
      this.notifications.error(
        this.intl.t('error.generic', {
          message: Message,
        }),
      );
    }
  }
}
