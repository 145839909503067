import Model, { attr, hasMany } from '@ember-data/model';

export default class BundleModel extends Model {
  @attr('string')
  name;

  @attr('string')
  ikon;

  @hasMany('article', {
    inverse: null,
    async: false,
  })
  articles;

  @attr('number')
  order;
}
