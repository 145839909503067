import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { validatePresence } from 'ember-changeset-validations/validators';
import error from 'boel/utils/error';
import ENV from 'boel/config/environment';
export default class AuthSuperbrugerIndstillingerAftalestatusEditController extends Controller
{
  @service notifications;
  @service intl;
  @service router;

  validations = {
    name: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
  };
  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    yield changeset
      .save()
      .then(() =>
      {
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(this.intl.t('aftalestatus.edit_success'));
        }
        this.router.transitionTo(
          'auth.superbruger.indstillinger.aftalestatus',
          {
            queryParams: { refresh: Date.now() },
          },
        );
      })
      .catch((err) =>
      {
        const Message = error.get_message(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          }),
        );
      });
  }

  @action
  cancel()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.aftalestatus');
  }
}
