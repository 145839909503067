import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class RapporterDiagrammerController extends Controller
{
  @tracked valgtStartdatoObj = null;
  @tracked valgtSlutdatoObj = null;
  @tracked valgtStartdato = null;
  @tracked valgtSlutdato = null;
  @tracked chosenOutlet = null;
  @tracked chosenPatient = null;
  @tracked chosenStatus = 2;
  @tracked chosenInsurance = 2;
  @tracked refresh;
  @tracked outletId = -1;
  @tracked patientId = -1;

  @action
  chooseInsurance(insurance)
  {
    this.chosenInsurance = insurance;
  }
  @action
  chooseStatus(status)
  {
    this.chosenStatus = status;
  }
  @action
  chooseOutlet(outlet)
  {
    this.chosenOutlet = outlet;

    let id = -1;

    if (outlet)
    {
      id = outlet.id;
    }
    this.outletId = id;
  }
  @action
  choosePatient(patient)
  {
    this.chosenPatient = patient;

    let id = -1;

    if (patient)
    {
      id = patient.id;
    }
    this.patientId = id;
  }
  @action
  setDateRange(erStartdato, datoArray)
  {
    let dato = datoArray && datoArray.length > 0 ? datoArray[0] : null;

    if (erStartdato)
    {
      if (dato)
      {
        this.valgtStartdatoObj = dato;
        this.valgtStartdato = dato
          ? dato.getFullYear() +
          '-' +
          (dato.getMonth() + 1) +
          '-' +
          dato.getDate()
          : '';
      }
      else
      {
        this.valgtStartdato = new Date().getFullYear() + '-01-01';
        this.valgtStartdatoObj = new Date(this.valgtStartdato);
      }
    }
    else
    {
      if (dato)
      {
        this.valgtSlutdatoObj = dato;
        this.valgtSlutdato = dato
          ? dato.getFullYear() +
          '-' +
          (dato.getMonth() + 1) +
          '-' +
          dato.getDate()
          : '';
      }
      else
      {
        const slut_date_obj = new Date();
        const slut_date_string = slut_date_obj.getFullYear() +
          '-' +
          (slut_date_obj.getMonth() + 1) +
          '-' +
          slut_date_obj.getDate();

        this.valgtSlutdatoObj = slut_date_obj;
        this.valgtSlutdato = slut_date_string;
      }
    }
  }
}
