import OAuth2PasswordGrant from 'ember-simple-auth/authenticators/oauth2-password-grant';
import ENV from 'boel/config/environment';

export default OAuth2PasswordGrant.extend({
  serverTokenEndpoint: ENV.APP.serverTokenEndpoint,
  serverTokenRevocationEndpoint: ENV.APP.serverTokenRevocationEndpoint,

  authenticate(identification, password) {
    let scope = [];
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Credentials: 'include',
    };

    return this._super(identification, password, scope, headers);
  },
});
