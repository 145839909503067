/* import __COLOCATED_TEMPLATE__ from './patient-exists.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AppPatientExistsComponent extends Component {
  @service store;
  @service currentUser;
  @service router;

  @action
  async tilknyt() {
    if (this.args.found_patient && this.args.kan_tilknyttes) {
      let association = await this.store.createRecord('patientsOutlet', {
        outlet: this.currentUser.CurrentActiveOutletId,
        patient: this.args.found_patient.id,
      });
      association.save();

      this.router.transitionTo(
        'auth.patienter.edit',
        this.args.found_patient.id,
        {
          queryParams: { refresh: Date.now() },
        },
      );
    }
  }
}
