import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthKabinestyringJournalRoute extends Route {
  @service store;
  @service currentUser;
  queryParams = {
    refresh5: {
      refreshModel: true,
    },
  };
  async model(params) {
    let patient_id = params.patient_id;
    let patient = await this.store.findRecord('patient', patient_id);

    return hash({
      journals: await this.store.query('journal', {
        filter: "equals(patient.id,'" + patient_id + "')",
        include: 'patient,problem,therapist,journaltype',
        sort: '-created',
      }),
      patient: patient,
    });
  }
}
