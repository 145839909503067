/* import __COLOCATED_TEMPLATE__ from './inline-insurance-list.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ENV from 'boel/config/environment';

export default class AppInlineInsuranceListComponent extends Component
{
  @service store;
  @service notifications;
  @service intl;
  @tracked insurancecases;

  @action
  async update()
  {
    const patient_id = this.args.patient.id;
    let insurancecases = await this.store.query('insurancecase', {
      filter: "equals(patient.id,'" + patient_id + "')",
      include: 'patient,company,problem',
      sort: '-start',
    });
    const now = new Date();
    insurancecases.forEach((insurancecase) => { 
      insurancecase.is_valid = (now >= insurancecase.start && now <= insurancecase.end);
    });
    this.insurancecases = insurancecases;
  }
  @action
  async delete(insurancecase)
  { 
    if (insurancecase)
    { 
      await insurancecase.destroyRecord();
      if (ENV.APP.ShowNotifications) {
        this.notifications.info(this.intl.t('inline_insurance_list.deleted_insurancecase'));
      }
    }
  }
}
