import { helper } from '@ember/component/helper';

export default helper(function isodate(positional /*, named*/) {
  if (positional && positional.length > 0) {
    let date = new Date(positional[0]);
    return date.toISOString();
  } else {
    return '';
  }
});
