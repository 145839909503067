/* import __COLOCATED_TEMPLATE__ from './form-appointment-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked, cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import ENV from 'boel/config/environment';
import fetch from 'fetch';
import { debug } from '@ember/debug';
import DateUtils from 'boel/utils/DateUtils';
export default class AppFormAppointmentListComponent extends Component {
  @service router;
  @service store;
  @service currentUser;
  @service session;
  @service notifications;
  @service intl;

  @tracked Visnings_Type = 0;
  @tracked Periode_Type = 0;

  @cached
  get today() {
    return DateUtils.Today_As_String();
  }
  @cached
  get future() {
    return this.args.appointments.filter((element) => {
      return element.time >= this.today;
    }).length;
  }
  @cached
  get previous() {
    return this.args.appointments.filter((element) => {
      return element.time < this.today;
    }).length;
  }
  @cached
  get all() {
    return this.args.appointments.length;
  }

  @action
  async email(appointment_id) {
    var kvittering_link = ENV.APP.namespaceRoot;
    if (ENV.APP.namespace) {
      kvittering_link += '/' + ENV.APP.namespace;
    }
    kvittering_link += '/appointment_kvittering';

    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };
    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    fetch(kvittering_link + '?appointment_id=' + appointment_id, {
      dataType: 'text',
      method: 'GET',
      headers: headers,
      cache: 'no-cache',
    })
      .then((response) => {
        if (response.ok) {
          if (ENV.APP.ShowNotifications) {
            this.notifications.success(
              this.intl.t('form_appointment.email_send_success'),
            );
          }
          this.router.transitionTo(this.router.currentRoute.parent.name);
        } else {
          this.notifications.error(
            this.intl.t('form_appointment.email_send_fejl'),
          );
        }
      })
      .catch((arg1, arg2) => {
        this.notifications.error(
          this.intl.t('form_appointment.email_send_fejl'),
        );
        debug('arg1: ' + arg1);
        debug('arg2: ' + arg2);
      });
  }
  @action
  UpdateConfirmed(appointment) {
    appointment.confirmed = !appointment.confirmed;
    appointment.save();
  }
  @action
  UpdateOutside(appointment) {
    appointment.outside = !appointment.outside;
    appointment.save();
  }
}
