/* import __COLOCATED_TEMPLATE__ from './appointment-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AppAppointmentListComponent extends Component {

  @service router;

  @tracked aftaler;
  @tracked searchword;

  @action
  update() {
    this.aftaler = this.args.appointments;
    this.searchword = this.args.searchword;
  }
  @action
  search(item) {
    let result = false;

    if (item && this.searchword) {
      let searchword = this.searchword;
      if (searchword) {
        searchword = searchword.toLowerCase();

        result = item.patient.fullname.toLowerCase().includes(searchword);
        result =
          result || item.patient.email.toLowerCase().includes(searchword);
        result = result || item.patient.cpr.toLowerCase().includes(searchword);
        result =
          result || item.patient.cellphone.toLowerCase().includes(searchword);
      }
    }
    return result ? 'text-success' : '';
  }
  @action
  navigate_row(appointment) {
    this.router.transitionTo(
      'auth.aftaler.edit',
      appointment.patient.id,
      appointment.id,
    );
    /*this.router.transitionTo(
      'auth.aftaler.edit',
        {
          'patient_id': appointment.patient.id,
          'appointment_id': appointment.id,
        }
    );*/
  }
  @action
  UpdateConfirmed(appointment) {
    appointment.confirmed = !appointment.confirmed;
    appointment.save();
  }
  @action
  UpdateOutside(appointment) {
    appointment.outside = !appointment.outside;
    appointment.save();
  }
}
