import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class AuthPatienterBetalingerController extends Controller {
  @service router;

  @action
  paid_all() {
    this.model.payments.forEach((element) => {
      if (!element.paid) {
        element.paid = true;
        element.time = new Date();
        element.save();
      }
    });
  }
  @action
  print() {
    window.print();
  }
  @action
  cancel() {
    this.router.transitionTo('auth.patienter');
  }
}
