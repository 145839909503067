import Controller from '@ember/controller';
import { tracked, cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';


export default class AuthSuperbrugerBillsController extends Controller
{
  queryParams = ['refresh'];

  @service router;
  @service excelexport;
  @service store;
  @service currentUser;

  @tracked valgtStartdatoObj = null;
  @tracked valgtSlutdatoObj = null;
  @tracked valgtStartdato = null;
  @tracked valgtSlutdato = null;

  @tracked chosenOutlet = null;
  @tracked outletId;
  @tracked refresh;
  @tracked searchWord = null;
  @tracked chosenStatus = 0;

  @cached
  get create_params()
  {
    let Not_Important = this.refresh; // Trigger refresh

    let params = {
      include: 'outlet,patient,appointment,appointment.problem,insurancecase',
      sort: 'appointment.time',
    };

    let filterArg = '', Filter_Count = 0;

    // Outlet filter
    if (this.outletId && this.outletId != -1)
    {
      filterArg = filterArg + "equals(outlet.id,'" + this.outletId + "')";
      Filter_Count = Filter_Count + 1;
    }
   // filterArg = filterArg + "not(equals(patient.patient-type-id,'9'))";
    //Filter_Count = Filter_Count + 1;

    // String filter
    if (this.searchWord)
    {
      if (Filter_Count > 0)
      {
        filterArg = filterArg + ',';
      }
      filterArg =
        filterArg +
        "contains(patient.fullname,'" +
        this.searchWord +
        "')";
      Filter_Count = Filter_Count + 1;
    }
    // Status
    if (this.chosenStatus != 2)
    {
      if (Filter_Count > 0)
      {
        filterArg = filterArg + ',';
      }
      filterArg =
        filterArg + "equals(paid,'" + (this.chosenStatus === 1) + "')";
      Filter_Count = Filter_Count + 1;
    }
    // Date filter
    let date_filter_string = '',
      date_filter_string_1 = '',
      date_filter_string_2 = '';
    let date_filtercount = 0;

    if (this.valgtStartdato)
    {
      date_filter_string_1 =
        "greaterOrEqual(appointment.time,'" + this.valgtStartdato + "')";
      date_filtercount++;
    }
    if (this.valgtSlutdato)
    {
      date_filter_string_2 = "lessThan(appointment.time,'" + this.valgtSlutdato + "')";

      date_filtercount++;
    }
    if (date_filtercount === 1)
    {
      date_filter_string = date_filter_string_1 + date_filter_string_2;
    } else if (date_filtercount === 2)
    {
      date_filter_string = date_filter_string_1 + ',' + date_filter_string_2;
    }
    if (date_filtercount > 0)
    {
      if (Filter_Count > 0)
      {
        filterArg = filterArg + ',';
      }
      filterArg = filterArg + date_filter_string;
      Filter_Count = Filter_Count + date_filtercount;
    }
    // End date filter

    // Confirmed
    /*if (Filter_Count > 0) {
      filterArg = filterArg + ',';
    }
    filterArg = filterArg + "equals(confirmed,'true')";
    Filter_Count = Filter_Count + 1;*/
    // End confirmed

    if (Filter_Count > 1)
    {
      filterArg = 'and(' + filterArg + ')';
    }
    if (filterArg)
    {
      Object.assign(params, { filter: filterArg });
    }

    return params;
  }
  @action
  chooseOutlet(outlet)
  {
    this.chosenOutlet = outlet;

    let id = -1;

    if (outlet)
    {
      id = outlet.id;
    } else
    {
      id = -1;
    }
    this.outletId = id;
  }
  @action
  setSearchValue(searchText)
  {
    this.searchWord = searchText;
  }
  @action
  chooseStatus(newStatus)
  {
    this.chosenStatus = newStatus;
  }
  @action
  setDateRange(erStartdato, datoArray)
  {
    let dato = datoArray && datoArray.length > 0 ? datoArray[0] : null;

    if (erStartdato)
    {
      this.valgtStartdatoObj = dato;
      this.valgtStartdato = dato
        ? dato.getFullYear() +
        '-' +
        (dato.getMonth() + 1) +
        '-' +
        dato.getDate()
        : '';
    } else
    {
      this.valgtSlutdatoObj = dato;
      this.valgtSlutdato = dato
        ? dato.getFullYear() +
        '-' +
        (dato.getMonth() + 1) +
        '-' +
        dato.getDate()
        : '';
    }
  }
  @action
  exceleksport(klasseNavn, filnavn, titel)
  {
    return new Promise((resolve, reject) =>
    {
      try
      {
        this.excelexport.export_table_to_csv(klasseNavn, filnavn, titel);
        resolve(true);
      } catch (err)
      {
        reject(new Error('Fejl: ' + err));
      }
    });
  }
}
