import Model, { attr } from '@ember-data/model';

export default class ReportModel extends Model {
  @attr('string')
  description;

  @attr('string')
  title;

  @attr('string')
  options;

  @attr('string')
  charttype;
}
