import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { validatePresence } from 'ember-changeset-validations/validators';
import { task } from 'ember-concurrency';
import { debug } from '@ember/debug';
import ENV from 'boel/config/environment';
import error from 'boel/utils/error';
export default class AuthPatienterAftalerEditController extends Controller {
  @service router;
  @service intl;
  @service notifications;

  validation = {
    problem: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
  };
  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset) {
    if (Array.isArray(changeset.get('time'))) {
      changeset.set('time', changeset.get('time')[0]);
    }
    // Server does not handle timezone in date
    // and thus the timezone offset needs to be
    // added to the time.
    changeset.set('time', changeset.get('time'));
    
    let patient_id = this.router.currentRoute.parent.params.patient_id;
    try {
      yield changeset
        .save()
        .then(() => {
          if (ENV.APP.ShowNotifications) {
            this.notifications.success(this.intl.t('aftaler.edit_success'));
          }
          this.router.transitionTo('auth.patienter.aftaler', patient_id, {
            queryParams: { refresh: Date.now() },
          });
        })
        .catch((err) => {
          const Message = error.get_message(err);
          this.notifications.error(
            this.intl.t('error.generic', {
              message: Message,
            })
          );
        });
    } catch (Err) {
      debug(Err);
    }
  }
  @action
  cancel() {
    let patient_id = this.router.currentRoute.parent.params.patient_id;
    this.router.transitionTo('auth.patienter.aftaler', patient_id);
  }
}
