import Service from '@ember/service';
import { inject } from '@ember/service';
import { action } from '@ember/object';
import ENV from 'boel/config/environment';

export default class AftalestatusService extends Service {
  @inject store;

  @action
  async FindAftaleStatus(patient_id) {
    var State = null;

    if (patient_id > -1) {
      let Nyeste_Aftale = await this.store.query('appointment', {
        filter: "equals(patient.id,'" + patient_id + "')",
        sort: '-time',
        'page[size]': 1,
        'page[number]': 1,
      });

      // Ved booking af eksisterende patient hvor det er l�ngere end 1 �r siden sidst,
      // markeres bookingen som "Gl.".
      // Ved booking til ny patient, markeres bookingen som "Ny".

      if (Nyeste_Aftale != null && Nyeste_Aftale.length > 0) {
        let year_ago = new Date();
        year_ago.setFullYear(year_ago.getFullYear() - 1);

        if (Nyeste_Aftale.time < year_ago) {
          State = await this.store.findRecord(
            'appointmentstate',
            ENV.APP.Appointment_State_Gammel_Id,
          );
        } else {
          State = await this.store.findRecord(
            'appointmentstate',
            ENV.APP.Appointment_State_Normal_Id,
          );
        }
      } else {
        State = await this.store.findRecord(
          'appointmentstate',
          ENV.APP.Appointment_State_Ny_Id,
        );
      }
    }
    return State;
  }
}
