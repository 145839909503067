/* import __COLOCATED_TEMPLATE__ from './load-remover.hbs'; */
import Component from '@glimmer/component';

export default class AppLoadRemoverComponent extends Component {
  removeLoadingIndicator() {
    let loadingIndicatorClass = 'ember-load-indicator';
    const elems = document.querySelectorAll(`.${loadingIndicatorClass}`);
    /**
     * Very important to iterate over the NodeList this way,
     * and remove the DOM elements via removeChild to maintain ie11
     * compatibility
     */
    for (let i = 0; i < elems.length; i++) {
      elems[i].parentNode.removeChild(elems[i]);
    }
  }
}
