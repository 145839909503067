import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';
import DateUtils from 'boel/utils/DateUtils';

export default class AuthUdlejningRoute extends Route {
  queryParams = {
    pid: {
      refreshModel: true,
    },
    date: {
      refreshModel: true,
    },
    edita: {
      refreshModel: true,
    },
  };

  @service router;
  @service intl;
  @service currentUser;
  @service store;

  datetime;
  isodate;
  outlet;
  patient;
  appointment;
  
  calendar_width_in_days;
  
  async setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    let initial;
    if (this.datetime) {
      initial = new Date(this.datetime);
    } else if (this.isodate) {
      initial = new Date(this.isodate);
    } else {
      initial = new Date();
    }
    let start = initial,
      tidspunkt_slut = DateUtils.AddOrSubtractDays(
        start,
        this.calendar_width_in_days,
        true,
      );

    const Start_isoDate = DateUtils.Get_ISO_Date(start);
    const Slut_isoDate = DateUtils.Get_ISO_Date(tidspunkt_slut);

    controller.set('ChosenAppointment', this.appointment);
    controller.set('tidspunkt', Start_isoDate);
    controller.set('tidspunkt2', Slut_isoDate);
    controller.set('ChosenPatient', this.patient);
    controller.set('chosenOutlet', this.outlet);
    controller.set('outletId', this.outletid);
    controller.set('ChosenDay', initial);

    if (!controller.get('ChosenTime')) {
      controller.set('ChosenTime', initial);
    }
  }
  beforeModel()
  { 
    if (!this.currentUser.CurrentActiveOutlet.hasroomsforrent)
    { 
      this.router.transitionTo('auth.aftaler');
    }
  }
  async model(params)
  {
    this.calendar_width_in_days = 7;
    if (params && params.pid && params.pid != -1) {
      this.patient = await this.store.findRecord('patient', params.pid, {
        include: 'outlet,problem',
      });
    } else {
      this.patient = null;
    }

    this.isodate = params.date;
    this.datetime = params.datetime;
    this.outlet = await this.currentUser.CurrentActiveOutlet;
    this.outletid = this.currentUser.CurrentActiveOutletId;

    if (params.edita) {
      this.appointment = await this.store.findRecord(
        'appointment',
        params.edita,
        { include: 'outlet,patient' },
      );
    } else {
      this.appointment = null;
    }
    var rooms_for_rent = await this.store.query('room', {
      filter:
        "and(equals(outlet.id,'" +
        this.outlet.id +
        "'),equals(bookable,'true'))",
      sort: 'name',
    });

    return hash({
      rooms: rooms_for_rent,
    });
  }
}
