import Model, { attr, belongsTo } from '@ember-data/model';

export default class InsurancecaseMode extends Model {
  @attr('string', { defaultValue: '' })
  name;

  @attr('string', { defaultValue: '' })
  identifier;

  @attr('number', { defaultValue: 0 })
  amount;

  @attr('number', { defaultValue: 0 })
  price;

  @attr('date')
  start;

  @attr('date')
  end;

  @belongsTo('insurancefirm', {
    inverse: null,
    async: false,
  })
  company;

  @belongsTo('patient', {
    inverse: null,
    async: false,
  })
  patient;

  @belongsTo('problem', {
    inverse: null,
    async: false,
  })
  problem;

  @attr('string', { defaultValue: '' })
  problemtext;
  
  @attr
  created;

  @attr
  updated;
}
