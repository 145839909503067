import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthAftalerEditRoute extends Route {
  @service store;
  @service currentUser;

  async model(params) {
    let user_outlet = await this.store.findRecord(
      'outlet',
      this.currentUser.CurrentActiveOutletId,
    );

    const states = await this.store.findAll('appointmentstate');
    const treatments = await this.store.findAll('treatment');
    const problems = await this.store.findAll('problem');

    const Closed_Periods = await this.store.query('closedperiod', {
      filter: "equals(outlet.id,'" + user_outlet.id + "')",
    });

    const Opening_Hours = await this.store.query('openinghour', {
      filter: "equals(outlet.id,'" + user_outlet.id + "')",
    });
    const Appointment = await this.store.findRecord(
      'appointment',
      params.appointment_id,
      {
        include: 'outlet,patient,treatment,problem,appointmentstate',
      },
    );
    return hash({
      problems: problems,
      states: states,
      treatments: treatments,

      outlet: user_outlet,
      appointment: Appointment,

      closedperiods: Closed_Periods,

      openinghours: Opening_Hours,
    });
  }
}
