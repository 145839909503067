import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthPatienterRoute extends Route {

  @service currentUser;

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    controller.set('chosenOutlet', this.currentUser.CurrentActiveOutlet);
    controller.set('outletId', this.currentUser.CurrentActiveOutletId);
  }
}
