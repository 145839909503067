/* import __COLOCATED_TEMPLATE__ from './form-message.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AppFormMessageComponent extends Component {
  @tracked chosenOutlet;

  constructor() {
    super(...arguments);
    this.chosenOutlet = this.args?.room?.outlet;
  }
  @action
  choose(outlet) {
    this.chosenOutlet = outlet;
  }
}
