import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerBrugereAssociationsRoute extends Route {
  @service store;

  is_selected(associations, outlet_id) {
    if (associations) {
      let filtered = associations.filter((association) => {
        const current_outlet = association.outlet;
        const current_outlet_id = current_outlet.id;
        return current_outlet_id === outlet_id;
      });
      if (filtered != null && filtered.length > 0) {
        return filtered.firstObject;
      }
    }
    return null;
  }
  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    let state = [];

    if (model.outlets) {
      model.outlets.forEach((element) => {
        let aState = {
          name: element.name,
          id: element.id,
          outlet: element,
        };
        let association = this.is_selected(model.associations, element.id);
        aState.selected = association != null;
        aState.association = association;
        aState.saved_selected = aState.selected;

        state.push(aState);
      });
    }
    controller.set('state', state);
  }
  async model(params) {
    const account = await this.store.findRecord('account', params.account_id);
    const associations = await this.store.query('association', {
      filter: "equals(bruger,'" + account.stringid + "')",
      include: 'outlet',
      sort: 'outlet.name',
    });
    const outlets = await this.store.query('outlet', {
      sort: 'name',
    });
    return hash({
      account: account,
      associations: associations,
      outlets: outlets,
    });
  }
}
