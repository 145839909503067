/* import __COLOCATED_TEMPLATE__ from './search.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class FilterSearchComponent extends Component
{
  @tracked search_value;
  
  timeout;
  focusTimeout;
  val;
  inactive = false;
  alloutlets = false;

  @action
  update()
  { 
    this.search_value = this.args.searchWord;

    this.focus();
  }
  @action
  submit_change()
  { 
    this.args.onChange(this.val, this.inactive, this.alloutlets);
  }
  @action
  change_alloutlets(event)
  {
    if (event && event.target)
    {
      this.alloutlets = event.target.checked;

      if (this.timeout)
      { 
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(this.submit_change, 1500);
    }
  }
  @action
  change_inactive(event)
  {
    if (event && event.target)
    {
      this.inactive = event.target.checked;

      if (this.timeout)
      { 
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(this.submit_change, 1500);
    }
  }
  @action
  change(event)
  {
    this.val = event.target.value;
    if (this.timeout)
    { 
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.submit_change, 1500);
  }
  @action
  focus()
  {
    this.focusTimeout = setTimeout(function ()
    {
      var objects = document.getElementsByClassName('filter_search_input');
      if (objects && objects.length > 0)
      {
        objects[0].focus({ focusVisible: true });
      }
    }, 1000);
  }
  @action
  willDestroy()
  {
    super.willDestroy(...arguments);
    if (this.timeout)
    {
      clearTimeout(this.timeout);
    }
    if (this.focusTimeout)
    {
      clearTimeout(this.focusTimeout);
    }
  }
}
