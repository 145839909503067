/* import __COLOCATED_TEMPLATE__ from './card-export.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Promise } from 'rsvp';
import { isPresent } from '@ember/utils';
import ENV from 'boel/config/environment';
import { tracked, cached } from '@glimmer/tracking';
import { debug } from '@ember/debug';

var request = function (_this, id, name, PDF) {
  let data = _this.params;

  const url = ENV.APP.host + '/' + ENV.APP.namespace + '/data';
  let version = ENV.APP.version;
  if (!version) {
    version = 'Ikke defineret';
  }
  let { access_token } = _this.session.data.authenticated;

  return new Promise(function (resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.responseType = 'arraybuffer';

      if (isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
      }
      xhr.onload = function () {
        if (this.status === 200) {
          resolve(true);
          var filename = name;
          var type = xhr.getResponseHeader('Content-Type');
          var ContentDisposition = '';
          try {
            ContentDisposition = xhr.getResponseHeader('Content-Disposition');
          } catch (err) {
            debug(err);
          }

          if (ContentDisposition) {
            let parts = ContentDisposition.split('filename=');
            if (parts && parts.length > 1) {
              if (parts[1]) {
                filename = decodeURIComponent(parts[1]);
              }
            }
          }

          if (filename && filename.length > 0) {
            let extPosition = filename.indexOf('.');
            if (extPosition == -1) {
              if (PDF) {
                filename += '.pdf';
              } else {
                filename += '.xlsx';
              }
            }
          }
          var blob =
            typeof File === 'function'
              ? new File([this.response], filename, { type: type })
              : new Blob([this.response], { type: type });

          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
          } else {
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);

            if (filename) {
              // use HTML5 a[download] attribute to specify filename
              var a = document.createElement('a');
              // safari doesn't support this yet
              if (typeof a.download === 'undefined') {
                window.location = downloadUrl;
              } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
              }
            } else {
              window.location = downloadUrl;
            }
            setTimeout(function () {
              URL.revokeObjectURL(downloadUrl);
            }, 100); // cleanup
          }
        } else {
          reject(
            new Error(
              'getJSON: `' +
                url +
                '` failed with status: [' +
                this.status +
                ']',
            ),
          );
        }
      };
      xhr.setRequestHeader('Content-type', 'application/vnd.api+json');
      xhr.send(data);
    } catch (err) {
      reject(err);
    }
  });
};
export default class AppCardExportComponent extends Component {
  rootURL = ENV.rootURL;

  valgtStartdato = null;
  valgtSlutdato = null;

  @tracked isPdf = false;
  @tracked isLoading = false;

  @service session;

  @cached
  get params() {
    let valgtStartdato = null;
    if (Date.parse(this.args.valgtStartdato)) {
      valgtStartdato = this.args.valgtStartdato;
    }
    let valgtSlutdato = null;
    if (Date.parse(this.args.valgtSlutdato)) {
      valgtSlutdato = this.args.valgtSlutdato;
    }
    let outlet_str = this.args.outlet;
    let outlet_id = parseInt(outlet_str, 10);
    let patient_str = this.args.patient;
    let patient_id = parseInt(patient_str, 10);

    let data = '{ "data" : {	"type": "dataexport","attributes": {';
    data += '"id" : 1\n';

    let rapportID = this.args.rapport.id;

    if (rapportID) {
      data += ',"rapport-id" : ' + rapportID + '\n';
    }
    if (outlet_id) {
      data += ',"outlet_id" : ' + outlet_id + '\n';
    }
    if (patient_id) {
      data += ',"patient_id" : ' + patient_id + '\n';
    }

    if (ENV.APP.version) {
      data += ',"version"  : "' + ENV.APP.version + '"\n';
    }
    if (this.args.valgtStartdato) {
      data += ',"valgt-startdato" : "' + valgtStartdato + '"\n';
    }
    if (this.args.valgtSlutdato) {
      data += ',"valgt-slutdato"  : "' + valgtSlutdato + '"\n';
    }
    data += ' }}}';
    return data;
  }
  @action
  gem(id, pdf) {
    if (id) {
      this.isPdf = pdf;
      return request(this, id, 'data.xlsx', pdf);
    }
  }
}
