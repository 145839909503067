/* import __COLOCATED_TEMPLATE__ from './form-user.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';
export default class AppFormUserComponent extends Component {
  @tracked errorMessage = '';
  @tracked serverError = [];
  @tracked userRole;
  @service store;

  constructor() {
    super(...arguments);
    this.userRole = this.args.model.userRole;
  }
  @action
  Remove_Association(Association_Id) {
    const association = this.store.peekRecord('association', Association_Id);
    if (association) {
      association.destroyRecord();
    }
  }
  @action
  updateRole(el) {
    if (el && el.value) {
      this.userRole = el.value;
    }
  }
}
