import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthUdlejningKeyRoute extends Route
{
  @service store;
  
  setupController(controller, model, transition)
  {
    super.setupController(controller, model, transition);
    controller.set('room', model.room);
    controller.set('appointment', model.appointment);
  }
  @action
  async model(params)
  { 
    return {
      room: await this.store.findRecord('room',params.room_id),
      appointment: await this.store.findRecord('appointment', params.appointment_id)
    }
  }
}
