import { helper } from '@ember/component/helper';
import ENV from '../config/environment';
import { get } from '@ember/object';

export default helper(function environment(params) {
  let result;
  if (params && params.length > 0) {
    let itemName = params[0];

    result = get(ENV, itemName);
  }
  return result;
});
