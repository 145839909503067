import Controller from '@ember/controller';
import ENV from 'boel/config/environment';
import { action } from '@ember/object';
import { service } from '@ember/service';
export default class AuthSuperbrugerIndstillingerSettingsDeleteController extends Controller
{
  @service notifications;
  @service intl;
  @service router;

  @action
  delete()
  {
    this.model.destroyRecord().then(() =>
    {
      if (ENV.APP.ShowNotifications) {
        this.notifications.success(this.intl.t('trimme.delete_success'));
      }
      this.router.transitionTo('auth.superbruger.indstillinger.settings', {
        queryParams: { refresh: Date.now() },
      });
    });
  }

  @action
  cancel()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.settings');
  }
}
