/* import __COLOCATED_TEMPLATE__ from './product-list.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class HelpProductListComponent extends Component {
  @service store;
  @service articles;

  @tracked produkter = [];

  @tracked
  isLoading = false;

  constructor() {
    super(...arguments);

    this.produkter = [];
    this.getProdukter();
  }
  async getProdukter() {
    this.isLoading = true;

    this.produkter = await this.articles.FindArtikler.perform(
      '',
      'product',
      '',
      '',
    );

    this.isLoading = false;
  }
}
