import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerMessagesEditRoute extends Route {
  @service store;

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);
    controller.set('chosenOutlet', model.outlet);
  }
  model(params) {
    return this.store.findRecord('message', params.message_id, {
      include: 'outlet',
    });
  }
}
