import Model, { attr } from '@ember-data/model';

export default class ProductModel extends Model {
  @attr('string', { defaultValue: '' })
  name;
  @attr('string', { defaultValue: '' })
  number;

  @attr('string', { defaultValue: '' })
  code;

  @attr('string', { defaultValue: '' })
  height;

  @attr('string', { defaultValue: '' })
  width;

  @attr('string', { defaultValue: '' })
  depth;

  @attr('string', { defaultValue: '' })
  drilling;

  @attr('string', { defaultValue: '' })
  profile;

  @attr('string', { defaultValue: '' })
  company;
}
