import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthPatienterBetalingerNewRoute extends Route {
  @service store;
  @service currentUser;

  async model(/* params */) {
    let patient_obj = this.modelFor('auth/patienter/betalinger').patient;
    let user_outlet = await this.currentUser.CurrentActiveOutlet;

    return hash({
      newPayment: this.store.createRecord('payment', {
        outlet: user_outlet,
        patient: patient_obj,
      }),
    });
  }
}
