import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import DateUtils from 'boel/utils/DateUtils';
import ENV from 'boel/config/environment';

export default class AuthAftalerDeleteController extends Controller
{
  @service router;
  @service notifications;
  @service intl;
  @service store;
  
  @task
  *deleteTask()
  {
    let time = this.model.time;
    let patient_id = this.model.patient.id;
    let isoDateTime = time.toISOString();
    let appointment_id = "";
    
    let appointments = yield this.store.query('appointment', {
      filter: "and(equals(patient.id,'" + patient_id + "'),greaterThan(time,'" +
        isoDateTime +
        "'))",
      include: 'patient,problem',
      "page[size]": 1,
      "page[number]": 1
    });
    if (appointments && Array.isArray(appointments) && appointments.length > 0)
    { 
      appointment_id = appointments[0].id;
    }
    
    yield this.model.destroyRecord().then(() =>
    {
      if (ENV.APP.ShowNotifications) {
        this.notifications.success(this.intl.t('aftaler.delete_success'));
      }
      this.router.transitionTo('auth.aftaler', {
        queryParams: { refresh: Date.now(), chosen: appointment_id },
      });
    });
  }
  @action
  cancel()
  {
    this.router.transitionTo('auth.aftaler');
  }
}
