import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class convertedday extends Helper {
  @service intl;

  compute(positional) {
    if (positional && positional.length > 0) {
      let date = positional[0];

      return new Intl.DateTimeFormat('da-dk', { weekday: 'long' }).format(date);
    } else {
      return '';
    }
  }
}
