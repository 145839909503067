import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { validatePresence } from 'ember-changeset-validations/validators';
import { task } from 'ember-concurrency';
import { debug } from '@ember/debug';
import error from 'boel/utils/error';
import ENV from 'boel/config/environment';
export default class AuthKabinestyringAftalerNewController extends Controller
{
  @service router;
  @service store;
  @service intl;
  @service notifications;

  validation = {
    problem: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
  };

  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    let treatment = changeset.get('treatment');
    if (treatment != null)
    {
      let patient_id = this.router.currentRoute.parent.params.patient_id;

      if (Array.isArray(changeset.get('time')))
      {
        changeset.set('time', changeset.get('time')[0]);
      }

      try
      {
        yield changeset
          .save()
          .then(() =>
          {
            if (ENV.APP.ShowNotifications) {
              this.notifications.success(this.intl.t('aftaler.new_success'));
            }
            this.router.transitionTo('auth.kabinestyring.aftaler', patient_id, {
              queryParams: { refresh2: Date.now() },
            });
          })
          .catch((err) =>
          {
            const Message = error.get_message(err);
            this.notifications.error(
              this.intl.t('error.generic', {
                message: Message,
              }),
            );
          });
      } catch (Err)
      {
        debug(Err);
      }
    }
  }
  @action
  cancel()
  {
    this.model.newAppointment.destroyRecord();
    let patient_id = this.router.currentRoute.parent.params.patient_id;
    this.router.transitionTo('auth.kabinestyring.aftaler', patient_id);
  }
}
