import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class PatientModel extends Model {
  @attr('string', { defaultValue: '' })
  firstname;

  @attr('string', { defaultValue: '' })
  middlename;

  @attr('string', { defaultValue: '' })
  lastname;

  @attr('string', { defaultValue: '' })
  fullname;

  @attr('string', { defaultValue: '' })
  address;

  @attr('string', { defaultValue: '' })
  postalcode;

  @attr('string', { defaultValue: '' })
  city;

  @attr('number', { defaultValue: 550 })
  amount;
  
  @attr('string', { defaultValue: 'Danmark' })
  country;

  @attr('string', { defaultValue: 'dansk' })
  language;

  @attr('string', { defaultValue: '' })
  telephone;

  @attr('string', { defaultValue: '' })
  cellphone;

  @attr('string', { defaultValue: '' })
  email;

  @attr('string', { defaultValue: '' })
  email2;

  @attr('boolean', { defaultValue: false })
  noemail;

  @attr('string', { defaultValue: '' })
  cpr;

  @attr('string', { defaultValue: '' })
  note;

  @attr('boolean', { defaultValue: false })
  sdmedlem;

  @attr('boolean', { defaultValue: false })
  inactive;
  
  @attr('boolean', { defaultValue: false })
  sother;

  @attr('number', { defaultValue: 1 })
  patientTypeId;
  
  @attr
  created;

  @attr
  updated;

  @belongsTo('appointmentstate', {
    inverse: null,
    async: false,
  })
  appointmentstate;
  
  @hasMany('patients-outlet', {
    inverse: null,
    async: false,
  })
  associations;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @belongsTo('problem', {
    inverse: null,
    async: false,
  })
  problem;

  @belongsTo('addressstatus', {
    inverse: null,
    async: false,
  })
  addressstatus;

  @attr('string', { defaultValue: '' })
  problemtext;

  @attr('number', { defaultValue: 0 })
  numberoftreatments;
}
