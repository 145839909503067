/* import __COLOCATED_TEMPLATE__ from './form-closedperiods-edit.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class AppFormClosedperiodsEditComponent extends Component {
  @action
  nulstilPeriode() {
    this.args.periode.start = null;
    this.args.periode.end = null;
  }
  @action
  changeDates(arg) {
    if (arg.length > 1) {
      this.args.periode.start = arg[0];
      this.args.periode.end = arg[1];
    }
  }
}
