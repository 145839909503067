import Model, { attr, belongsTo } from '@ember-data/model';

export default class SettingModel extends Model {
  @attr('string')
  token;

  @attr('string')
  title;

  @attr('string')
  value;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @attr
  created;

  @attr
  updated;
}
