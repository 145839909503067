import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthAftalerNewpatientRoute extends Route {
  queryParams = {
    patient_navn: {
      refreshModel: true,
    },
  };
  @service store;
  @service currentUser;

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    controller.set('chosenOutlet', this.currentUser.CurrentActiveOutlet);
  }
  async model(/*params*/) {
    let user_outlet = this.currentUser.CurrentActiveOutlet;

    const the_patient = await this.store.createRecord('patient', {
      outlet: user_outlet,
    });

    const all_problems = await this.store.findAll('problem');

    return hash({
      patient: the_patient,
      problems: all_problems,
    });
  }
}
