/* import __COLOCATED_TEMPLATE__ from './favorit-markering.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class FavoritMarkeringComponent extends Component {
  @service store;
  @service currentUser;
  @service favoritter;

  @tracked IsFavorite;
  @tracked Favorit;

  favorittype;
  favoritid;

  constructor() {
    super(...arguments);

    this.favorittype = this.args.favorittype;
    this.favoritid = this.args.favoritid;
    this.IsFavorite = false;
    this.FetchFavoritesInfo(this.favoritid, this.favorittype);
  }

  async FetchFavoritesInfo(favoritid, favorittype) {
    this.Favorit = await this.favoritter.FetchFavorite.perform(
      favorittype,
      favoritid,
    );

    this.IsFavorite = this.Favorit != null && this.Favorit != undefined;
  }
  @action
  async ToggleMarker(e) {
    e.stopPropagation();
    e.preventDefault();
    e.stopImmediatePropagation();

    this.IsFavorite = !this.IsFavorite;

    if (!this.IsFavorite && this.Favorit) {
      // delete
      this.favoritter.destroyFavorite.perform(this.Favorit);
      this.IsFavorite = false;
    } else if (this.IsFavorite) {
      // create
      await this.favoritter.createFavorite.perform(
        this.favorittype,
        this.favoritid,
      );

      this.Favorit = await this.favoritter.FetchFavorite.perform(
        this.favorittype,
        this.favoritid,
      );
    }
    return false;
  }
}
