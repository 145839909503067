import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';
import DateUtils from 'boel/utils/DateUtils';

export default class AuthAftalerRoute extends Route
{
  queryParams = {
    refresh2: {
      refreshModel: true,
    },
    pid: {
      refreshModel: true,
    },
    date: {
      refreshModel: true,
    },
    edita: {
      refreshModel: true,
    },
    chosen: {
      refreshModel: true,
    },
  };

  @service router;
  @service intl;
  @service currentUser;
  @service store;

  datetime;
  isodate;
  outlet;
  patient;
  appointment;
  chosen_appointment;
  
  async setupController(controller, model, transition) 
  {  
    super.setupController(controller, model, transition);
         
    let initial;
    if (this.datetime) {
      initial = new Date(this.datetime);
    } else if (this.isodate) {
      initial = new Date(this.isodate);
    } else {
      initial = new Date();
    }
    let start = initial;
    let tidspunkt_slut = DateUtils.AddOrSubtractDays(start, 1, true);

    const Start_isoDate = DateUtils.Get_ISO_Date(start);
    const Slut_isoDate = DateUtils.Get_ISO_Date(tidspunkt_slut);

    if (this.patient)
    { 
      controller.set('searchWord', this.patient.fullname);
    }
    controller.set('ChosenAppointment', this.appointment);
    if (controller.get('tidspunkt') !== Start_isoDate) {
      controller.set('tidspunkt', Start_isoDate);
    }
    if (controller.get('tidspunkt2') !== Slut_isoDate) {
      controller.set('tidspunkt2', Slut_isoDate);
    }

    controller.set('ChosenPatient', this.patient);

    if (controller.get('outletId') !== this.outletid) {
      controller.set('outletId', this.outletid);
      controller.set('chosenOutlet', this.currentUser.CurrentActiveOutlet);
    }
    controller.set('ChosenDay', initial);

    if (!controller.get('ChosenTime')) {
      controller.set('ChosenTime', initial);
    }
    if (this.chosen_appointment && this.chosen_appointment != "-1")
    {
      let appointment = await this.store.findRecord('appointment', this.chosen_appointment, {
        include: 'outlet,patient,treatment,problem,action,appointmentstate,room',
      });
      controller.set('selected_appointment', appointment);
      if (!appointment)
      {
        controller.set('chosen', undefined);
      }
    }
    else
    { 
      controller.set('chosen', undefined);
    }
  }
  async model(params)
  {
    if (params && params.pid && params.pid != -1) {
      this.patient = await this.store.findRecord('patient', params.pid, {
        include: 'outlet,problem,addressstatus',
      });
    } else {
      if (params.pid != -1)
      {
        this.patient = null;
      }
    }
    this.chosen_appointment = params.chosen;
    this.isodate = params.date;
    this.datetime = params.datetime;
    this.outlet = this.currentUser.CurrentActiveOutlet;
    this.outletid = this.currentUser.CurrentActiveOutletId;
    if (params.edita) {
      this.appointment = await this.store.findRecord(
        'appointment',
        params.edita,
        { include: 'outlet,patient' },
      );
    } else {
      this.appointment = null;
    }
    var journaltypes = await this.store.findAll('journaltype');
    var therapists;

    therapists = await this.store.findAll('therapist', {
      filter: "equals(outlet.id,'" + this.outletid + "')",
      sort: 'name',
    });

    return hash({
      journaltypes: journaltypes,
      therapists: therapists,
    });
  }
}
