/* import __COLOCATED_TEMPLATE__ from './form-outlet.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class AppFormOutletComponent extends Component {
  @action
  Choose_Postal_Code(number, city) {
    this.args.outlet.postalCode = number;
    this.args.outlet.city = city;
  }
  @action
  chosen(el, color_object) {
    if (el && color_object && color_object.hex) {
      el.setValue(color_object.hex);
    }
  }
}
