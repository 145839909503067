/* import __COLOCATED_TEMPLATE__ from './inline-add-journal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { Changeset } from 'ember-changeset';
import DateUtils from 'boel/utils/DateUtils';

export default class AppInlineAddJournalComponent extends Component {
  
  @service store;
  @service notifications;
  @service intl;
  @service printThis;
  
  @tracked model;

  validations = {};
  @tracked changeset;
  
  @action
  before_unload(event)
  {
    if (this.changeset.get('isDirty'))
    {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    }
  }
  constructor(...args) {
    super(...args); 
    window.addEventListener('beforeunload', this.before_unload, false);
  }
  @action
  print_journal()
  { 
    const selector = '#journal-list';
    const options = {
      printDelay: 500,
    };
    this.printThis.print(selector, options);
  }
  @action
  flyt_til_maaling()
  { 
    if (this.changeset)
    {
      const entry = this.changeset.get('entry');
      if (entry)
      {
        this.changeset.set('result', entry);
        this.changeset.set('entry', '');
      }
    }
  }
  @action update()
  { 
    this.journal = this.args.journal;
    this.patient = this.args.patient;

    this.model = {
      problemtext: '',
      journaltype: null,
      therapist: null,
      entry: '',
      result: '',
      time: DateUtils.Get_Date_String(new Date())
    };
    this.changeset = Changeset(this.model, this.validations);
    if (!this.args.isNew) {
      this.model.problemtext = this.args.journal.problemtext;
      this.model.journaltype = this.args.journal.journaltype;
      this.model.therapist = this.args.journal.therapist;
      this.model.entry = this.args.journal.entry;
      this.model.result = this.args.journal.result;
      if (this.args.journal.time)
      {
        this.model.time = DateUtils.Get_Date_String(this.args.journal.time);
      }
      else {
        if (this.args.journal.created)
        {
          this.model.time = DateUtils.Get_Date_String(this.args.journal.created);
        }
      }      
    }
    this.model = this.model;
  }
  @action
  async submit(changeset) {
    if (this.args.isNew) {
      await this.create(changeset);
    } else {
      await this.save(changeset);
    }
  }
  @action
  tilbage()
  {
    this.args.tilbage();
  }
  @action
  will_destroy()
  { 
    window.removeEventListener('beforeunload', this.before_unload);
    if (this.changeset.get('isDirty'))
    { 
      this.submit(this.changeset);
    }
  }
  @action
  async create(changeset) {
    if (this.args.patient) {
      let journaltype;
      if (changeset.get('journaltype')) {
        journaltype = changeset.get('journaltype');
      }
      let therapist;
      if (changeset.get('therapist')) {
        therapist = changeset.get('therapist');
      }

      let NewJournalEntry = await this.store.createRecord('journal', {
        problemtext: changeset.get('problemtext'),
        result: changeset.get('result'),
        entry: changeset.get('entry'),
        journaltype: journaltype,
        therapist: therapist,
        patient: this.args.patient,
        time: DateUtils.Get_Date_From_String(changeset.get('time')),
      });

      try {
        await NewJournalEntry.save();
        this.notifications.success(
          this.intl.t('inline_journal.create_success'),
        );
       // this.args.tilbage();
      } catch (err) {
        this.notifications.error(err);
      }
    }
  }
  @action
  async save(changeset) {
    if (this.patient) {
      let journaltype;
      if (changeset.get('journaltype')) {
        journaltype = changeset.get('journaltype.id');
      }
      let therapist;
      if (changeset.get('therapist')) {
        therapist = changeset.get('therapist.id');
      }
      try {
        let journal = await this.store.findRecord(
          'journal',
          this.journal.id,
        );

        if (journal) {
          journal.problemtext = changeset.get('problemtext');
          journal.entry = changeset.get('entry');
          journal.result = changeset.get('result');
          journal.time = DateUtils.Get_Date_From_String(changeset.get('time'));
          if (journaltype) {
            journal.journaltype = await this.store.findRecord(
              'journaltype',
              journaltype,
            );
          }
          if (therapist) {
            journal.therapist = await this.store.findRecord(
              'therapist',
              therapist,
            );
          }
          await journal.save();

          this.notifications.success(
            this.intl.t('inline_journal.edit_success'),
          );
        }
      } catch (err) {
        this.notifications.error(err);
      }
    }
  }
}
