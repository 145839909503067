import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class converteddate extends Helper {
  @service intl;
  compute(positional) {
    var date_options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    if (positional && positional.length > 0) {
      let date = positional[0];
      return this.intl.formatDate(date, date_options);
    } else {
      return '';
    }
  }
}
