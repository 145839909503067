import { resolve } from 'rsvp';
import Service from '@ember/service';
import { inject } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { debug } from '@ember/debug';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class CurrentUserService extends Service {
  @inject session;
  @inject store;
  @inject router;

  subscribers = [];
  user;

  @tracked _CurrentActiveOutletId;
  @tracked _CurrentActiveOutlet;
  @tracked _closedperiods;

  @action
  Is_CurrentActiveOutlet(OutletId) {
    return this._CurrentActiveOutletId == OutletId;
  }
  @action
  async ChangeOutlet(OutletId) {
    if (OutletId && OutletId != -1 && (OutletId != this._CurrentActiveOutletId)) {
      let temp_outlet = await this.store.findRecord('outlet', OutletId);

      const closedperiods = await this.store.query('closedperiod', {
        filter: "equals(outlet.id,'" + OutletId + "')",
      });
      if (temp_outlet) {
        this._closedperiods = closedperiods;
        this._CurrentActiveOutletId = OutletId;
        this._CurrentActiveOutlet = temp_outlet;
        this.Set_Colors(temp_outlet);

        this.subscribers.forEach((subscriber) => {
          try {
            subscriber(temp_outlet);
          } 
          catch (err) {
            debug('ChangeOutlet - Error while calling subscribers: ', err);
          }
        });
      }
    }
  }
  get CurrentClosedPeriods() {
    return this._closedperiods;
  }
  get CurrentActiveOutlet() {
    return this._CurrentActiveOutlet;
  }
  get CurrentActiveOutletId() {
    return this._CurrentActiveOutletId;
  }
  get Is_Superuser() {
    if (!this.user) {
      return false;
    }
    return (
      this.user.userRole === 'Superbruger' ||
      this.user.userRole === 'Administrator'
    );
  }

  get Is_Administrator() {
    if (!this.user) {
      return false;
    }
    return this.user.userRole === 'Administrator';
  }
  @action
  Set_Colors(outlet) {
    var r = document.querySelector(':root');
    r.style.setProperty('--main-bg-color', outlet.backgroundcolor);
    r.style.setProperty('--main-text-color', outlet.textcolor);
    r.style.setProperty('--active-bg-color', outlet.selectioncolor);
  }
  @action
  subscribe(subscriber) {
    if (subscriber) {
      if (!this.subscribers.includes(subscriber, 0)) {
        this.subscribers.push(subscriber);
      }
    }
  }
  @action
  async load() {
    try {
      let userId = this.session?.data?.authenticated?.userName;

      if (this.user && this.user.userName === userId) {
        return resolve();
      } 
      else 
      {
        if (!isEmpty (userId)) {
          let fullName = this.session.data.authenticated.fullName;
          let role = this.session.data.authenticated.userRole;
          let email = this.session.data.authenticated.email;
          let id = this.session.data.authenticated.id;
          let outletid = this.session.data.authenticated.outletid;
          let startroute = this.session.data.authenticated.startroute;

          if (isEmpty (outletid) || outletid == -1) {
            this.session.invalidate ();
          } 
          else 
          {
            this.user = this.store.createRecord('user', {
              userRole: role,
              userName: userId,
              fullName: fullName,
              startSide: -1,
              email: email,
              brugerid: id,
              startroute: startroute,
              outletid: outletid,
            });
            this._CurrentActiveOutletId = outletid;
            this.store
              .findRecord('outlet', this._CurrentActiveOutletId)
              .then((outlet) => {
                this._CurrentActiveOutlet = outlet;
                this.user.outlet = this._CurrentActiveOutlet;
                this.Set_Colors(this.user.outlet);
              })
              .catch ((err) => {
                debug('Error while fetching outlet: ', err);
                this.router.transitionTo('auth.logout');
              });

            this.store
              .query('closedperiod', {
                filter: "equals(outlet.id,'" + outletid + "')",
              })
              .then((output) => {
                this._closedperiods = output;
              })
              .catch((err) => {
                debug('Error while fetching closedperiod: ', err);
                this.router.transitionTo('auth.logout');
              });

            this.store
              .query('association', {
                filter: "equals(bruger,'" + id + "')",
                include: 'outlet',
                sort: 'outlet.name',
              })
              .then((output) => {
                this.user.associations = output.slice();
              })
              .catch((err) => {
                debug('Error while fetching associations: ', err);
                this.router.transitionTo('auth.logout');
              });

            this.user.isAdministrator = role === 'Administrator';
            this.user.isSuperuser =
              role === 'Superbruger' || role === 'Administrator';
            return resolve();
          }
        } 
        else 
        {
          if (this.session) {
            this.session.invalidate();
          } else {
            this.router.transitionTo('login');
          }
        }
      }
    } 
    catch (err) {
      debug('Load user error: ', err);
      if (this.session) 
      {
        this.session.invalidate();
      } 
      else 
      {
        this.router.transitionTo('login');
      }
    }
  }
}
