import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerAfdelingerNewRoute extends Route {
  @service store;

  model() {
    return this.store.createRecord('outlet');
  }
}
