import Model, { attr, hasMany } from '@ember-data/model';

export default class OutletModel extends Model {
  @attr('string', { defaultValue: '' })
  name;

  @attr('boolean', { defaultValue: false })
  showOnSite;

  @attr('string', { defaultValue: '' })
  addressStreet1;

  @attr('string', { defaultValue: '' })
  addressStreet2;

  @attr('string', { defaultValue: '' })
  postalCode;

  @attr('string', { defaultValue: '' })
  city;

  @attr('string', { defaultValue: '' })
  phone;

  @attr('string', { defaultValue: '' })
  emailGeneral;

  @attr('string', { defaultValue: '' })
  vatNumber;

  @attr('string', { defaultValue: '#4d559b' })
  backgroundcolor;

  @attr('string', { defaultValue: '#ffffff' })
  textcolor;

  @attr('string', { defaultValue: '#232c39' })
  selectioncolor;

  @attr('boolean', { defaultValue: true })
  danishHolidays;

  @attr('boolean', { defaultValue: false })
  hasroomsforrent;

  @attr('boolean', { defaultValue: true })
  closedSunday;

  @attr('boolean', { defaultValue: true })
  closedSaturday;

  @hasMany('patients-outlet', {
    inverse: null,
    async: false,
  })
  patients;

  @attr
  created;

  @attr
  updated;
}
