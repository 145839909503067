import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerTherapistsDeleteRoute extends Route {
  @service store;

  model(params) {
    return this.store.findRecord('therapist', params.therapist_id);
  }
}
