import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthAftalerInsurancecasesNewRoute extends Route {
  @service store;

  async model(/* params */) {
    let patient_obj = this.modelFor('auth/aftaler/insurancecases').patient;

    const all_insurance_firms = await this.store.findAll('insurancefirm');

    const all_problems = await this.store.findAll('problem');
    const New_Record = await this.store.createRecord('insurancecase', {
      patient: patient_obj,
      start: new Date(),
      end: new Date(),
    });

    return hash({
      insurancefirms: all_insurance_firms,
      problems: all_problems,
      newInsurancecase: New_Record,
    });
  }
}
