import Model, { attr, belongsTo } from '@ember-data/model';

export default class ClosedperiodModel extends Model {
  @attr('string', { defaultValue: '' })
  name;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @attr('date')
  start;

  @attr('date')
  end;

  created;

  updated;
}
