import Model, { attr, hasMany } from '@ember-data/model';
import { service } from '@ember/service';

export default class OrderModel extends Model {
  @service intl;

  @attr('date')
  uploadtime;

  @attr('date')
  sendtosupplierstime;

  @attr('string', { defaultValue: '' })
  customername;

  @attr('string', { defaultValue: '' })
  deliveryweek;

  @attr('string', { defaultValue: '' })
  deliveryaddress;

  @attr('string', { defaultValue: '' })
  deliverycity;

  @attr('string', { defaultValue: '' })
  deliverypostalcode;

  @attr('string', { defaultValue: '' })
  deliverycountry;

  @attr('string', { defaultValue: '' })
  deliverytelephone;

  @attr('string', { defaultValue: '' })
  deliveryemail;

  @attr('string', { defaultValue: '' })
  ordernumber;

  @attr('string', { defaultValue: '' })
  seller;

  @attr('string', { defaultValue: '' })
  sellerinitials;

  @attr('string', { defaultValue: '' })
  sellertitle;

  @attr('boolean', { defaultValue: false })
  hasbeensendtosuppliers;

  @attr('boolean', { defaultValue: false })
  sellertelephone;

  @attr('string', { defaultValue: '' })
  selleremail;

  @hasMany('orderline', { async: false })
  orderlines;

  get convertedsendtime() {
    var date_options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    var time_options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };
    return (
      this.intl.formatDate(this.sendtosupplierstime, date_options) +
      ' ' +
      this.intl.formatTime(this.sendtosupplierstime, time_options)
    );
  }
  get converteduploadtime() {
    var date_options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    var time_options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };
    return (
      this.intl.formatDate(this.uploadtime, date_options) +
      ' ' +
      this.intl.formatTime(this.uploadtime, time_options)
    );
  }
  get invalid() {
    let invalid =
      !this.ordernumber ||
      !this.customername ||
      !this.deliveryweek ||
      !this.deliveryaddress ||
      !this.seller;

    return invalid;
  }
  get ersendt() {
    return this.hasbeensendtosuppliers ? 'Ja' : 'Nej';
  }
}
