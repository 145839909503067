import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class convertedtimewithseconds extends Helper {
  @service intl;

  compute(positional) {
    var time_options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
    if (positional && positional.length > 0) {
      let date = positional[0];
      return this.intl.formatTime(date, time_options);
    } else {
      return '';
    }
  }
}
