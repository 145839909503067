import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'boel/config/environment';
import error from 'boel/utils/error';
import { debug } from '@ember/debug';

import fetch from 'fetch';
import {
  isServerErrorResponse,
  isUnauthorizedResponse,
} from 'ember-fetch/errors';

export default class AuthAftalerMergeController extends Controller {

    @service store;
    @service intl;
    @service session;
    @service router;
    @tracked error_message;

    @action
    async perform_merge_request()
    {
        let that = this;
        debug("merge",that.model.original_id, that.model.to_be_merged_id);

        var headers = {
            'Content-Type': 'application/vnd.api+json',
        };
        let link = ENV.APP.namespaceRoot + '/' + ENV.APP.namespace;
        link += "/merge?" + new URLSearchParams({
            original_id: that.model.original_id,
            to_be_merged_id: that.model.to_be_merged_id,
        });

        if (that.session.isAuthenticated) {
            headers[
              'Authorization'
            ] = `Bearer ${that.session.data.authenticated.access_token}`;
        }
        await fetch(link, {
            method: 'GET',
            headers: headers,
            cache: 'no-cache',
        })
        .then((response) => {
            if (response.ok) {
              that.error_message = "";
              that.router.transitionTo('/auth/aftaler?refresh2='+ new Date().getTime() +'&pid='+that.model.original_id);
            }  
            else if (isUnauthorizedResponse(response)) {                
              that.session.invalidate();

            } else if (isServerErrorResponse(response)) {                
              that.error_message = that.intl.t('merge_modal.server_error') + "" + response;
            }
        })
        .catch((err) => {
          const Message = error.get_message(err);
          that.error_message = that.intl.t('merge_modal.server_error') + "" + Message;          
        });
    }
}
