import Model, { attr } from '@ember-data/model';

export default class ArticleModel extends Model {
  @attr('string', { defaultValue: '' })
  title;

  @attr('string', { defaultValue: '' })
  indhold;

  @attr('string', { defaultValue: '' })
  teaser;

  @attr('string', { defaultValue: '' })
  tags;

  @attr('string', { defaultValue: '' })
  route;

  @attr('string', { defaultValue: '' })
  component;

  @attr('string', { defaultValue: '' })
  category;

  @attr('string', { defaultValue: '' })
  culture;

  @attr('number', { defaultValue: 10000 })
  order;

  @attr
  created;

  @attr
  updated;
}
