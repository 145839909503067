import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';

export default class AuthPatienterController extends Controller {
  queryParams = ['refresh'];

  @service router;

  @tracked Show_List_View = '1';
  @tracked chosenOutlet = null;
  @tracked outletId = -1;
  @tracked refresh;
  @tracked searchWord = null;

  get create_params2() {
    let Not_Important = this.refresh; // Trigger refresh

    let params = {
      include: 'outlet,patient',
    };
    if (this.outletId != -1 || this.searchWord) {
      let filterArg = '';
      let Filter_Count = 0;

      if (this.outletId && this.outletId != -1) {
          filterArg =
          filterArg +
          "equals(outlet.id,'" +
          this.outletId +
          "')";
        Filter_Count = Filter_Count + 1;
      }
      if (this.searchWord) {
        if (Filter_Count > 0) {
          filterArg = filterArg + ',';
        }
        filterArg =
          filterArg +
          "or(contains(patient.firstname,'" +
          this.searchWord +
          "'),contains(patient.middlename,'" +
          this.searchWord +
          "'),contains(patient.lastname,'" +
          this.searchWord +
          "'),contains(patient.cpr,'" +
          this.searchWord +
          "'))";
        Filter_Count = Filter_Count + 1;
      }
      if (Filter_Count > 1) {
        filterArg = 'and(' + filterArg + ')';
      }
      if (filterArg) {
        Object.assign(params, { filter: filterArg });
      }
    }
    return params;
  }
  get create_params() {
    let Not_Important = this.refresh; // Trigger refresh

    let params = {
      include: 'outlet',
      sort: 'firstname,middlename,lastname',
    };
    if (this.outletId != -1 || this.searchWord) {
      let filterArg = '';
      let Filter_Count = 0;

      if (this.outletId && this.outletId != -1) {
        filterArg =
          filterArg +
          "equals(outlet.id,'" +
          this.outletId +
          "')";
        Filter_Count = Filter_Count + 1;
      }
      if (this.searchWord) {
        if (Filter_Count > 0) {
          filterArg = filterArg + ',';
        }
        filterArg =
          filterArg +
          "or(contains(firstname,'" +
          this.searchWord +
          "'),contains(middlename,'" +
          this.searchWord +
          "'),contains(lastname,'" +
          this.searchWord +
          "'),contains(cpr,'" +
          this.searchWord +
          "'))";
        Filter_Count = Filter_Count + 1;
      }
      if (Filter_Count > 1) {
        filterArg = 'and(' + filterArg + ')';
      }
      if (filterArg) {
        Object.assign(params, { filter: filterArg });
      }
    }
    return params;
  }
  @action
  setSearchValue(searchText) {
    this.searchWord = searchText;
  }
  @action
  navigate_to_new() {
    this.router.transitionTo('auth.patienter.new');
  }
  @action
  chooseOutlet(outlet) {
    this.chosenOutlet = outlet;

    let id = -1;

    if (outlet) {
      id = outlet.id;
    }
    this.outletId = id;
  }
}
