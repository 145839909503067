/* import __COLOCATED_TEMPLATE__ from './form-closedperiods-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AppFormClosedperiodsListComponent extends Component
{
  @tracked perioder;
  
  @action
  update()
  { 
    this.perioder = this.args.perioder;
  }
  
}
