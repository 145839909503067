import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerInsurancecompaniesDeleteRoute extends Route {
  @service store;

  model(params) {
    return this.store.findRecord('insurancefirm', params.company_id);
  }
}
