import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked, cached } from '@glimmer/tracking';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerAfdelingerController extends Controller
{
  queryParams = ['refresh'];

  @service router;

  @tracked Show_List_View = '1';
  @tracked searchWord = null;
  @tracked refresh;

  @cached
  get create_params()
  {
    let Not_Important = this.refresh; // Trigger refresh

    let params = {
      sort: 'name',
    };
    if (this.outletId != -1 || this.searchWord)
    {
      let filterArg = '';
      let Filter_Count = 0;

      if (this.searchWord)
      {
        if (Filter_Count > 0)
        {
          filterArg = filterArg + ',';
        }
        filterArg =
          filterArg +
          "or(contains(name,'" +
          this.searchWord +
          "'),contains(address-street1,'" +
          this.searchWord +
          "'),contains(postal-code,'" +
          this.searchWord +
          "'),contains(city,'" +
          this.searchWord +
          "'),contains(phone,'" +
          this.searchWord +
          "'),contains(email-general,'" +
          this.searchWord +
          "'),contains(vat-number,'" +
          this.searchWord +
          "'),contains(address-street2,'" +
          this.searchWord +
          "'))";
        Filter_Count = Filter_Count + 1;
      }
      if (Filter_Count > 1)
      {
        filterArg = 'and(' + filterArg + ')';
      }
      if (filterArg)
      {
        Object.assign(params, { filter: filterArg });
      }
    }
    return params;
  }
  @action
  setVisningstype(nyVisning)
  {
    this.nulstil = true;
    this.visningsType = nyVisning;
    // this.applicationPage = 0;
  }
  @action
  navigate_to_new()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.afdelinger.new');
  }
  @action
  setSearchValue(searchText)
  {
    this.searchWord = searchText;
  }
}
