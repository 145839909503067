import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';

export default class AuthHelpController extends Controller
{

  @service session;
  @service currentUser;

  @tracked refresh;
  @tracked valgtArtikel;

  artikelId;

  @action
  chooseArtikel(artikel)
  {
    this.valgtArtikel = artikel;

    let id = null;

    if (artikel)
    {
      id = artikel.id;
    }

    this.artikelId = id;
  }
}
