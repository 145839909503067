import Controller from '@ember/controller';
import { tracked, cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';
import DateUtils from 'boel/utils/DateUtils';
import ENV from 'boel/config/environment';

export default class UdlejningController extends Controller
{
  queryParams = [
    'pid',
    'date',
    'datetime',
    'edita',
    'stamdata',
    'journal',
    'payments',
    'insurance'
  ];

  @service router;
  @service store;
  @service intl;
  @service confirmation;

  @service currentUser;
  @service notifications;

  @tracked pid;
  @tracked date;
  @tracked edita;
  @tracked stamdata;
  @tracked journal;
  @tracked payments;
  @tracked insurance;
  @tracked searchWord = '';
  @tracked chosen_journal;
  
  @tracked column;
  @tracked row;
  
  @tracked chosenOutlet;
  @tracked outletId;

  @tracked tidspunkt;
  @tracked tidspunkt2;

  @tracked ChosenTime;
  @tracked ChosenDay;
  @tracked ChosenPatient;
  @tracked ChosenAppointment;
  @tracked ChosenInsurancecase;
  @tracked NewInsurancecase;
  
  @tracked Visningstype = 1;
  @tracked ChosenRoom;
  
  @tracked current_patient_page = 1;
  @tracked patients_per_page = 100;
  @tracked selected_appointment;
  
  constructor(...args)
  {
    super(...args);
    this.currentUser.subscribe(this.chooseOutlet);
  }
  @action
  paging_changed(page)
  {
    this.current_patient_page = page;
  }
  @cached
  get State()
  {
    if (this.edita)
    {
      return 'EditAppointment';
    }
    if (this.pid)
    {
      if (this.stamdata && this.stamdata === 'true')
      {
        return 'ViewStamdata';
      }
      else if (this.journal && this.journal === 'true')
      {
        return 'ViewJournal';
      }
      else if (this.payments && this.payments === 'true')
      {
        return 'ViewPayment';
      }
      else if (this.insurance && this.insurance === 'true')
      {
        return 'ViewInsurance';
      }
      else
      {
        return 'ViewPatient';
      }
    } else if (
      this.ChosenPatient &&
      this.ChosenPatient.dirtyType === 'created'
    )
    {
      return 'NewPatient';
    } else
    {
      return 'Default';
    }
  }
  @cached
  get is_valid_booking()
  {
    return this.column != null && this.row != null;
  }
  @cached
  get Valid_Patient()
  {
    return (
      this.ChosenPatient != null && this.ChosenPatient.dirtyType !== 'created'
    );
  }
  @cached
  get create_params_patienter()
  {
    let params = {
      include: 'outlet',
      sort: 'fullname',
    };
    let filterArg = '';
    let Filter_Count = 0;

    if (this.searchWord)
    {
      let Search_Terms = this.searchWord.split(' ');

      if (Filter_Count > 0)
      {
        filterArg = filterArg + ',';
      }
      let Search_Filter = '';
      const prefix = 'and(';

      let terms = 0;
      Search_Terms.forEach((term) =>
      {
        if (term)
        {
          terms += 1;
          if (Search_Filter)
          {
            Search_Filter += ',';
          }
          Search_Filter +=
            "or(contains(fullname,'" +
            term +
            "'),contains(cellphone,'" +
            term +
            "'),contains(postalcode,'" +
            term +
            "'),contains(cpr,'" +
            term +
            "'))";
        }
      });
      if (terms > 1)
      {
        Search_Filter = prefix + Search_Filter + ')';
      }
      filterArg = filterArg + Search_Filter;
      Filter_Count = Filter_Count + 1;
    }

    if (Filter_Count > 1)
    {
      filterArg = 'and(' + filterArg + ')';
    }
    if (filterArg)
    {
      Object.assign(params, { filter: filterArg });
    }
    return params;
  }
  @cached
  get create_params()
  {
    let params = {
      include: 'outlet,problem,patient,appointmentstate,room',
      sort: 'time',
    };
    let filterArg = '',
      Filter_Count = 0;

    // Outlet filter
    if (this.chosenOutlet && this.chosenOutlet.id != -1)
    {
      filterArg =
        filterArg + "equals(outlet.id,'" + this.chosenOutlet.id + "')";
      Filter_Count = Filter_Count + 1;
    }
    if (Filter_Count > 0)
    {
      filterArg = filterArg + ',';
    }
    filterArg = filterArg + "equals(isroombooking,'true')";
    Filter_Count = Filter_Count + 1;

    // Date filter
    let date_filter_string =
      "and(greaterOrEqual(time,'" +
      this.tidspunkt +
      "'),lessThan(time,'" +
      this.tidspunkt2 +
      "'))";

    if (Filter_Count > 0)
    {
      filterArg = filterArg + ',';
    }
    filterArg = filterArg + date_filter_string;
    Filter_Count = Filter_Count + 1;
    // End date filter

    if (Filter_Count > 1)
    {
      filterArg = 'and(' + filterArg + ')';
    }
    if (filterArg)
    {
      Object.assign(params, { filter: filterArg });
    }
    return params;
  }
  @action
  glny(aftaler)
  {
    return aftaler.filter((appointment) =>
    {
      return appointment.appointmentstate == 3;
    }).length;
  }
  @action
  ny(aftaler)
  {
    return aftaler.filter((appointment) =>
    {
      return appointment.appointmentstate == 2;
    }).length;
  }
  @action
  changeDate(change)
  {
    var d = new Date(this.ChosenDay);

    if (d && change)
    {
      var add = change > 0;
      var amount = Math.abs(change);
      d = DateUtils.AddOrSubtractDays(d, amount, add);
      this.chooseDay(d);
    }
  }
  @action
  NavigateTo_NewPatient()
  {
    let user_outlet = this.currentUser.CurrentActiveOutlet;

    let p = this.store.createRecord('patient', {
      outlet: user_outlet,
    });
    this.ChosenPatient = p;
    this.view_stamdata(this.ChosenPatient);
  }
  @action
  setSearchValue(searchText)
  {
    if (searchText != this.searchWord)
    {
      this.searchWord = searchText;
      
      if (this.State != 'Default')
      {
        this.searchClicked();
      }
    }
  }
  @action
  async chooseOutlet(outlet)
  {
    if (outlet != this.chosenOutlet)
    {
      this.chosenOutlet = outlet;
      let id = -1;

      if (outlet)
      {
        id = outlet.id;
      }
      this.outletId = id;
      this.refresh = new Date().getTime();
    }
    if (this.ChosenAppointment)
    {
      if (this.ChosenAppointment.outlet != outlet)
      {
        this.ChosenAppointment.outlet = outlet;
        await this.ChosenAppointment.save();
      }
    }
  }
  @action
  validateTime(Is_Valid)
  {
    this.Time_Is_Valid = Is_Valid;
  }
  @action
  book(column, row)
  {
    this.column = column;
    this.row = row;

    if (this.ChosenAppointment != this.column.appointment)
    {
      this.ChosenAppointment = this.column.appointment;
    }
    if (!this.column.available && this.column.appointment != null)
    {
      if (
        this.column.appointment.patient != null &&
        this.ChosenPatient != this.column.appointment.patient
      )
      {
        this.ChosenPatient = this.column.appointment.patient;
        this.pid = this.ChosenPatient.id;
      }
    }
  }
  @action
  async confirm(appointment)
  {
    await this.confirmation.confirm(appointment);
  }
  @action
  chooseDay(dato)
  {
    const Start_isoDate = DateUtils.Get_ISO_Date(dato);

    if (this.date != Start_isoDate)
    {
      this.date = Start_isoDate;
      this.tidspunkt = Start_isoDate;
    }
    if (this.ChosenDay != dato)
    {
      this.ChosenDay = dato;
      this.datetime = dato;
      this.ChosenTime = dato;
    }
    this.column = null;
    this.row = null;
  }
  get Show_Save_Appointment()
  {
    return (
      this.ChosenAppointment && this.ChosenAppointment.time != this.ChosenDay
    );
  }
  @action
  navigate_to_new()
  {
    this.router.transitionTo('auth.aftaler.newnopatient');
  }
  @action
  cancel_new_appointment()
  {
    this.ChosenPatient.unloadRecord();
    this.ChosenPatient = null;
  }
  @action
  navigate_appointment_row(patient, appointment)
  {
    this.selected_appointment = appointment;
    this.view_patient(patient);
  }
  @action
  navigate_patient_row(patient)
  {
    if (this.pid != patient.id)
    {
      this.pid = patient.id;
    }
    if (this.ChosenPatient != patient)
    {
      this.ChosenPatient = patient;
    }
    if (this.edita != null)
    {
      this.edita = null;
    }
  }
  @action
  Dis_Choose_Appointment()
  {
    this.edita = null;
    this.ChosenAppointment = null;
  }
  @action
  journal_opdateret()
  {
    let that = this;
    that.ChosenPatient = that.ChosenPatient;
  }
  @action
  Choose_Journal(journal)
  {
    if (journal && this.chosen_journal != journal)
    {
      this.chosen_journal = journal;
    }
  }
  @action
  tilbage_fra_journal()
  {
    this.journal = null;
    this.chosen_journal = null;
  }
  @action
  unchoose_journal()
  {
    this.chosen_journal = null;
  }
  @action
  async Edit_Appointment(appointment)
  {
    this.edita = appointment.id;
    this.ChosenAppointment = appointment;
    let tidspunkt = new Date(appointment.time.getTime());
    let minutes = tidspunkt.getMinutes();

    while (minutes % 5 > 0)
    {
      minutes += 1;
    }
    if (minutes > 59)
    {
      minutes = null;
    }
    if (minutes != tidspunkt.getMinutes())
    {
      tidspunkt.setMinutes(minutes);
    }
    this.chooseDay(tidspunkt);
    this.chosenOutlet = appointment.outlet;
    this.outletId = appointment.outlet.id;
    this.chosenTime = tidspunkt;
    await this.currentUser.ChangeOutlet(this.outletId);
  }
  @action
  async Appointment_Created(/*appointment*/)
  {
    this.ChosenDay = this.ChosenDay;
    this.refresh = new Date().getTime();
    if (ENV.APP.ShowNotifications) {
      this.notifications.success(this.intl.t('inline_new_appointment.created'));
    }
  }
  @action
  async create_appointment(day_count, status)
  {
    try
    {
      const chosen_outlet = this.currentUser.CurrentActiveOutlet;

      const newAppointment = await this.store.createRecord('appointment', {
        outlet: chosen_outlet,
        problemtext: '',
        time: this.column.date,
        patient: this.ChosenPatient,
        isroombooking: true,
        bookingdays: day_count,
        appointmentstate: status,
        room: this.row.room,
      });
      await newAppointment.save();
      if (ENV.APP.ShowNotifications) {
        this.notifications.success(this.intl.t('inline_new_booking.created'));
      }
      this.Dis_Choose_Appointment();
      this.column.available = false;
      this.column.appointment = newAppointment;
      this.refresh = new Date().getTime();
    } catch (err)
    {
      this.notifications.error(err);
    }
  }
  @action
  async update_appointment(day_count, status)
  {
    try
    {
      this.column.appointment.bookingdays = day_count;
      this.column.appointment.appointmentstate = status;
      this.column.appointment.room = this.row.room;

      await this.column.appointment.save();
      if (ENV.APP.ShowNotifications) {
       this.notifications.success(this.intl.t('inline_new_booking.updated'));
      }
      this.Dis_Choose_Appointment();
      this.refresh = new Date().getTime();
    } catch (err)
    {
      this.notifications.error(err);
    }
  }
  @action
  async delete_appointment()
  {
    try
    {
      await this.column.appointment.destroyRecord();
      if (ENV.APP.ShowNotifications) {
        this.notifications.success(this.intl.t('inline_new_booking.deleted'));
      }
      this.Dis_Choose_Appointment();
      this.row = null;
      this.column = null;
      this.refresh = new Date().getTime();
    } catch (err)
    {
      this.notifications.error(err);
    }
  }
  @action
  searchClicked()
  {
    if (this.payments != null)
    {
      this.payments = null;
    }
    if (this.stamdata != null)
    {
      this.stamdata = null;
    }
    if (this.journal != null)
    {
      this.journal = null;
    }
    if (this.edita != null)
    {
      this.edita = null;
    }
    if (this.edita != null)
    {
      this.edita = null;
    }
    if (this.insurance != null)
    {
      this.insurance = null;
    }
    if (this.NewInsurancecase != null)
    {
      this.NewInsurancecase = null;
    }
    this.pid = null;
    this.ChosenPatient = null;
  }
  @action
  view_patient_back()
  {
    this.pid = null;
    this.ChosenPatient = null;
  }
  @action
  view_patient(patient)
  {
    if (patient)
    {
      if (this.edita != null)
      {
        this.edita = null;
      }
      if (this.payments != null)
      {
        this.payments = null;
      }
      if (this.journal != null)
      {
        this.journal = null;
      }
      if (this.insurance != null)
      {
        this.insurance = null;
      }
      if (this.stamdata != null)
      {
        this.stamdata = null;
      }
      if (this.pid != patient.id)
      {
        this.pid = patient.id;
      }
      if (this.ChosenPatient != patient)
      {
        this.ChosenPatient = patient;
      }
    }
  }
  @action
  view_insurance_back()
  {
    this.ChosenInsurancecase = null;
    this.insurance = null;
    this.NewInsurancecase = null;
  }
  @action
  view_journal(patient)
  {
    if (patient)
    {
      if (this.edita != null)
      {
        this.edita = null;
      }
      if (this.pid != patient.id)
      {
        this.pid = patient.id;
      }
      this.journal = true;

      if (this.payments != null)
      {
        this.payments = null;
      }
      if (this.stamdata != null)
      {
        this.stamdata = null;
      }
      if (this.insurance != null)
      {
        this.insurance = null;
      }
    }
  }
  @action
  view_stamdata(patient)
  {
    if (patient)
    {
      if (this.edita != null)
      {
        this.edita = null;
      }
      if (this.pid != patient.id)
      {
        this.pid = patient.id;
      }
      if (this.payments != null)
      {
        this.payments = null;
      }
      if (this.journal != null)
      {
        this.journal = null;
      }
      if (this.insurance != null)
      {
        this.insurance = null;
      }
      this.stamdata = true;
    }
  }
  @action
  view_payments(patient)
  {
    if (patient)
    {
      if (this.edita != null)
      {
        this.edita = null;
      }
      if (this.journal != null)
      {
        this.journal = null;
      }
      if (this.stamdata != null)
      {
        this.stamdata = null;
      }
      if (this.insurance != null)
      {
        this.insurance = null;
      }
      this.payments = true;
    }
  }
  @action
  view_insurance(patient, insurancecase, new_insurance)
  {
    if (patient)
    {
      if (this.edita != null)
      {
        this.edita = null;
      }
      if (this.journal != null)
      {
        this.journal = null;
      }
      if (this.stamdata != null)
      {
        this.stamdata = null;
      }
      if (this.payments != null)
      {
        this.payments = null;
      }
      if (this.pid != patient.id)
      {
        this.pid = patient.id;
      }
      if (this.ChosenPatient != patient)
      {
        this.ChosenPatient = patient;
      }
      this.insurance = true;
      this.ChosenInsurancecase = insurancecase;
      this.NewInsurancecase = new_insurance;
    }
  }
}
