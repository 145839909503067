import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class AuthAftalerPatientController extends Controller
{
  @service router;

  @action
  cancel()
  {
    this.router.transitionTo('auth.aftaler');
  }
}
