/* import __COLOCATED_TEMPLATE__ from './help-highscore.hbs'; */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import ENV from '../../config/environment';
import fetch from 'fetch';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  isServerErrorResponse,
  isUnauthorizedResponse,
} from 'ember-fetch/errors';

export default class HelpHighscoreComponent extends Component {
  @tracked newest;
  @tracked updated;
  @tracked top;
  @tracked isLoading;

  @service notifications;
  @service session;
  @service intl;

  constructor() {
    super(...arguments);

    this.isLoading = true;
    this.fetchHighscore.perform();
  }

  @task
  *fetchHighscore() {
    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };

    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;

      yield fetch(
        ENV.APP.host +
          '/' +
          ENV.APP.namespace +
          '/actions/highscore?include=newest,top,updated',
        {
          headers: headers,
          method: 'GET',
          cache: 'no-cache',
        },
      )
        .then((response1) => {
          this.isLoading = false;
          if (response1.ok) {
            response1.json().then((response) => {
              if (response?.data?.attributes?.newest) {
                this.newest = response.data.attributes.newest;
              } else {
                this.newest = response.newest;
              }
              if (response?.data?.attributes?.updated) {
                this.updated = response.data.attributes.updated;
              } else {
                this.updated = response.updated;
              }
              if (response?.data?.attributes?.top) {
                this.top = response.data.attributes.top;
              } else {
                this.top = response.top;
              }
            });
          } else if (isUnauthorizedResponse(response)) {
            this.notifications.info(this.intl.t('login.session_timeout'));
            this.session.invalidate();
          } else if (isServerErrorResponse(response)) {
            this.notifications.error(this.intl.t('login.server_error'));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.notifications.error(this.intl.t('login.network_error'));
          debug('Fejl under fetch: ');
          debug(error);
        });
    }
  }
}
