/* import __COLOCATED_TEMPLATE__ from './cpr-visning.hbs'; */
import Component from '@glimmer/component';

export default class AppCprVisningComponent extends Component
{
  get displayed_value()
  { 
    let input = this.args.cpr;
    input = input.replace(/\D/g, '').trim();
    
    let output = input;
    if (input && input.length > 6)
    { 
      output = input.substring(0, 6) + "-" + input.substring(6);
    }
    return output;
  }
}
