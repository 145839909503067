/* import __COLOCATED_TEMPLATE__ from './keymessage.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service'
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { debug } from '@ember/debug';
import { action } from '@ember/object';

export default class AppKeymessageComponent extends Component
{
  @service keymessage;
  @service router;
  @service printThis;
  
  @tracked loading_content_dk = true;
  @tracked loading_content_en = true;
  @tracked loading_content_de = true;
  @tracked dk_content;
  @tracked en_content;
  @tracked de_content;
  @tracked content_shown = 0;
  @tracked is_open = true;
  
  get Is_Loading_Content()
  { 
    return this.loading_content_dk || this.loading_content_en || this.loading_content_de;
  }
  @action
  print()
  { 
    let selector = '.keymessage_dk';
    if (this.content_shown === 1) 
    {
      selector = '.keymessage_en';
    }
    else if (this.content_shown === 2) 
    {
      selector = '.keymessage_de';
    }
    const options = {
      printDelay: 500,
    };
    this.printThis.print(selector, options);
  }
  @action
  cancelConfirm()
  {
    this.is_open = false;
    this.args.cancelConfirm();
  }
  async Read_Messages()
  {
    this.loading_content_dk = true;
    try
    {
      this.keymessage
        .Read_Messages(this.args.appointment, this.args.room, 'UDLEJNING_NØGLEBREV_DK')
        .then((content) =>
        {
          this.dk_content = htmlSafe(content);
          this.loading_content_dk = false;
        });
    } catch (err)
    {
      debug(err);
      this.loading_content_dk = false;
    }
    this.loading_content_en = true;
    try
    {
      this.keymessage
        .Read_Messages(this.args.appointment, this.args.room, 'UDLEJNING_NØGLEBREV_EN')
        .then((content) =>
        {
          this.en_content = htmlSafe(content);
          this.loading_content_en = false;
        });
    } catch (err)
    {
      debug(err);
      this.loading_content_en = false;
    }
    this.loading_content_de = true;
    try
    {
      this.keymessage
        .Read_Messages(this.args.appointment, this.args.room, 'UDLEJNING_NØGLEBREV_DE')
        .then((content) =>
        {
          this.de_content = htmlSafe(content);
          this.loading_content_de = false;
        });
    } catch (err)
    {
      debug(err);
      this.loading_content_de = false;
    }
  }
  constructor()
  {
    super(...arguments);

    if (this.args.appointment && this.args.room)
    {
      this.Read_Messages();
      this.is_open = true;
    }
  }
}
