/* import __COLOCATED_TEMPLATE__ from './time-period.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class TimePeriod extends Component {
  @action
  nulstil() {
    this.args.periode.starttime = null;
    this.args.periode.endtime = null;
  }
  @action
  nulstilPeriode() {
    this.args.periode.startdate = null;
    this.args.periode.enddate = null;
  }
  @action
  changeDates(arg) {
    if (arg.length > 1) {
      this.args.periode.startdate = arg[0];
      this.args.periode.enddate = arg[1];
    }
  }
  @action
  changeStartKlokkeslet(arg1) {
    this.args.periode.starttime = arg1[0];
  }
  @action
  changeSlutKlokkeslet(arg1) {
    this.args.periode.endtime = arg1[0];
  }
}
