/* import __COLOCATED_TEMPLATE__ from './data-select.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from 'tracked-built-ins';

export default class DataSelectComponent extends Component {
  @service
  store;
  @service intl;

  @tracked options;

  constructor() {
    super(...arguments);
    this.options = this.search.perform();
  }
  @action
  customSuggestion(search_term) {
    return this.intl.t(this.args.createlabel, { search_term });
  }
  @task({
    restartable: true,
  })
  *search(searchTerm) {
    try
    {
      let searchWord = '';
      if (searchTerm != null && searchTerm != undefined && searchTerm.length > 0)
      { 
        searchWord = searchTerm;
      }
      let params = {
        ...this.args.params,
      };
      if (params['page']) {
        params['page[number]'] = params.page;
      }
      if (params['pagesize']) {
        params['page[size]'] = params.pagesize;
      }
      let filter_args = '';
      const regex = /@searchWord/gi;
      if (this.args.filterArg && searchWord) {
        filter_args = this.args.filterArg.replaceAll(regex, searchWord);

        params['filter'] = filter_args;
      } else
      {
        params['filter'] = "contains(" + this.args.filterfieldname + ",'" + searchWord + "')";
        params['searchWord'] = searchWord;
      }
      return this.store.query(this.args.modelName, params);
    } catch (err) {
      console.error(err);
    }
  }
  @action
  onChange(selected) {
    this.args.onChange(selected);
  }
}
