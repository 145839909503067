/* import __COLOCATED_TEMPLATE__ from './link-list.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class LinkList extends Component {
  @service
  store;

  @tracked
  alleLink = null;

  @tracked
  isLoading = false;

  constructor() {
    super(...arguments);

    this.alleLink = [];
    this.getLink();
  }

  getLink() {
    this.isLoading = true;
    this.store.findAll('link').then((alleLink) => {
      // because this is async you need to guard against the component being destroyed before the api call has finished because because `this.set` will throw an error.
      if (this.isDestroyed) {
        return;
      }
      this.isLoading = false;
      this.alleLink = alleLink;
    });
  }
}
