import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthPatienterJournalRoute extends Route {
  @service store;
  @service currentUser;
  queryParams = {
    refresh1: {
      refreshModel: true,
    },
  };
  model(params) {
    let patient_id = params.patient_id;
    return hash({
      journals: this.store.query('journal', {
        filter: "equals(patient.id,'" + patient_id + "')",
        include: 'patient,problem,therapist,journaltype',
        sort: '-created',
      }),
      patient: this.store.findRecord('patient', patient_id),
    });
  }
}
