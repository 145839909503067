/* import __COLOCATED_TEMPLATE__ from './kodebrev.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import { inject as service } from '@ember/service';
import ENV from 'boel/config/environment';
import fetch from 'fetch';
import {
  isServerErrorResponse,
  isUnauthorizedResponse,
} from 'ember-fetch/errors';
import { debug } from '@ember/debug';

export default class KnapKodebrevComponent extends Component {
  @service session;
  @service notifications;
  @service router;

  @tracked isLoading = false;
  @tracked isSuccess = false;
  @tracked isError = false;
  @tracked errorMessage = '';

  @action
  clear() {
    this.isLoading = false;
    this.isSuccess = false;
    this.isError = false;
    this.errorMessage = '';
  }
  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(user) {
    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };

    if (user) {
      if (this.session.isAuthenticated) {
        headers[
          'Authorization'
        ] = `Bearer ${this.session.data.authenticated.access_token}`;
      }
      yield fetch(
        ENV.APP.host + '/' + ENV.APP.namespace + '/Account/Kodebrev',
        {
          headers: headers,
          method: 'POST',
          cache: 'no-cache',
          body: JSON.stringify({
            data: {
              type: 'kodebrevModel',
              attributes: {
                UserId: user,
              },
            },
          }),
        },
      )
        .then((response) => {
          if (response.ok) {
            this.isLoading = false;
            this.isSuccess = true;
            if (ENV.APP.ShowNotifications) {
              this.notifications.success(this.intl.t('login.kodebrev_success'));
            }
            window.setTimeout(this.clear, 2000);
          } else if (isUnauthorizedResponse(response)) {
            this.notifications.info(this.intl.t('login.session_timeout'));
            this.session.invalidate();
          } else if (isServerErrorResponse(response)) {
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = this.intl.t('login.server_error');
            window.setTimeout(this.clear, 5000);
          }
        })
        .catch(function (error) {
          this.isLoading = false;
          this.isError = true;
          this.errorMessage = this.intl.t('login.network_error');
          window.setTimeout(this.clear, 5000);
          debug('Fejl under fetch: ');
          debug(error);
        });
    }
  }

  @action
  Execute() {
    this.isLoading = true;
    return this.saveTask.perform(this.args.bruger);
  }
}
