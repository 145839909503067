/* import __COLOCATED_TEMPLATE__ from './downloadicon.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class KnapperDownloadiconComponent extends Component {
  @tracked isLoading = false;
  @tracked isSuccess = false;
  @tracked isError = false;
  @tracked errorMessage = '';

  @action
  clear() {
    this.isLoading = false;
    this.isSuccess = false;
    this.isError = false;
    this.errorMessage = '';
  }
  @action
  ExecuteDownload() {
    this.isLoading = true;
    let promise = this.args.download();
    promise.then(
      (/*value*/) => {
        this.isLoading = false;
        this.isSuccess = true;
        window.setTimeout(this.clear, 2000);
      },
      (reason) => {
        this.isLoading = false;
        this.isError = true;
        this.errorMessage = reason;
        window.setTimeout(this.clear, 5000);
      },
    );
  }
}
