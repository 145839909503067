/* import __COLOCATED_TEMPLATE__ from './insurance-info.hbs'; */
import Component from '@glimmer/component';
import Sdk from 'boel/utils/sdk';

export default class AppInsuranceInfoComponent extends Component {
  get BackgroundColor() {
    const payment = this.args.payment;
    let result = '';

    if (payment && payment.lastStatusCode) {
      const is_error = Sdk.Is_Error(payment.lastStatusCode);
      if (is_error) {
        result = 'bg-danger text-white';
      } else {
        result = 'bg-secondary text-white';
      }
    }
    return result;
  }
}
