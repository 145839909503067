/* import __COLOCATED_TEMPLATE__ from './offline-detection.hbs'; */
/* eslint-disable require-yield */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class OfflineDetectionComponent extends Component {
  @tracked isOffline = false;

  constructor() {
    super(...arguments);
    this.isOffline = !window.navigator.onLine;

    window.addEventListener('offline', this.offline);
    window.addEventListener('online', this.online);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('offline', this.offline);
    window.removeEventListener('online', this.online);
  }
  @action
  offline() {
    this.isOffline = true;
  }
  @action
  online() {
    this.isOffline = false;
  }
}
