import Model, { attr, belongsTo } from '@ember-data/model';

export default class OpeninghourModel extends Model {
  @attr
  startdate;

  @attr
  enddate;

  @attr('date')
  starttime;

  @attr('date')
  endtime;

  @attr('boolean', { defaultValue: false }) hassunday;

  @attr('boolean', { defaultValue: false }) hasmonday;

  @attr('boolean', { defaultValue: false }) hastuesday;

  @attr('boolean', { defaultValue: false }) haswednesday;

  @attr('boolean', { defaultValue: false }) hasthursday;

  @attr('boolean', { defaultValue: false }) hasfriday;

  @attr('boolean', { defaultValue: false }) hassaturday;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @attr
  created;

  @attr
  updated;
}
