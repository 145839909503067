/* import __COLOCATED_TEMPLATE__ from './info-kabine-status.hbs'; */
import Component from '@glimmer/component';

export default class AppInfoKabineStatusComponent extends Component {
  get Committed() {
    return this.args.aftaler.filter((appointment) => {
      return appointment.committed;
    }).length;
  }
  get NotCommitted() {
    return this.args.aftaler.filter((appointment) => {
      return !appointment.committed;
    }).length;
  }
  get Outside() {
    return this.args.aftaler.filter((appointment) => {
      return appointment.outside;
    }).length;
  }
  get Inside() {
    return this.args.aftaler.filter((appointment) => {
      return !appointment.outside;
    }).length;
  }
}
