import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthAftalerMergeRoute extends Route {

    @service store;

    async model(params) {

        let original_id = params.patient_id;
        let to_be_merged_id = params.to_be_merged_id;
         
        let Is_Valid = original_id && to_be_merged_id;
        
        if (Is_Valid)
        {
            let original_patient = await this.store.findRecord('patient', original_id, { include: 'outlet'});
            let to_be_merged_patient = await this.store.findRecord('patient', to_be_merged_id, { include: 'outlet'});


            let original_appointments = this.store.query('appointment', {
                filter: "equals(patient.id,'" + original_id + "')"}                
            );
            let to_be_merged_appointments = this.store.query('appointment', {
                filter: "equals(patient.id,'" + to_be_merged_id + "')"}
            );           
        
            let original_journals = this.store.query('journal', {
                filter: "equals(patient.id,'" + original_id + "')"}
            );
            let to_be_merged_journals = this.store.query('journal', {
                filter: "equals(patient.id,'" + to_be_merged_id + "')"}
            ); 

            let original_insurancecases = this.store.query('insurancecase', {
                filter: "equals(patient.id,'" + original_id + "')"}
            );
            let to_be_merged_insurancecases = this.store.query('insurancecase', {
                filter: "equals(patient.id,'" + to_be_merged_id + "')"}
            ); 

            return hash({
                original_id: original_id,
                to_be_merged_id: to_be_merged_id,
                original_patient: original_patient,
                to_be_merged_patient: to_be_merged_patient,
                original_appointments: original_appointments,
                to_be_merged_appointments: to_be_merged_appointments,
                original_journals: original_journals,
                to_be_merged_journals: to_be_merged_journals,
                original_insurancecases: original_insurancecases,
                to_be_merged_insurancecases: to_be_merged_insurancecases
            });
        }
        else {
            return null;
        }
    }
}
