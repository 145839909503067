import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';
import ENV from 'boel/config/environment';
import { tracked } from '@glimmer/tracking';
import { debug } from '@ember/debug';
import fetch from 'fetch';
import
  {
    isServerErrorResponse,
    isUnauthorizedResponse,
  } from 'ember-fetch/errors';
export default class LoginController extends Controller
{
  @service notifications;
  @service session;
  @service router;
  @service intl;

  @tracked isSaving = false;
  @tracked errorMessage = '';
  @tracked visGlemtAdgangskode = false;

  @action
  toggle()
  {
    this.visGlemtAdgangskode = !this.visGlemtAdgangskode;
  }

  @action
  nulstil()
  {
    this.errorMessage = '';

    const nulstilLink = ENV.APP.namespace + '/api/Account/nulstilFase1';
    const brugerObj = { identification: this.identification };
    const bruger = brugerObj.identification;

    fetch(nulstilLink, {
      method: 'POST',
      body: JSON.stringify({
        data: {
          User: bruger,
        },
      }),
    })
      .then((response) =>
      {
        if (response.ok)
        {
          this.router.transitionTo('nulstil', {
            queryParams: { emailSendt: true },
          });
        } else if (isUnauthorizedResponse(response))
        {
          this.notifications.info(this.intl.t('login.session_timeout'));
          this.session.invalidate();
        } else if (isServerErrorResponse(response))
        {
          this.notifications.error(this.intl.t('login.server_error'));
        }
      })
      .catch((error) =>
      {
        this.notifications.error(
          this.intl.t('error.generic', {
            message: err,
          }),
        );

        debug('Fejl under fetch: ');
        debug(error);
      });
  }

  @action
  async authenticate(e)
  {
    e.preventDefault();

    let { identification, password } = this;
    let expirationTime = 14 * 24 * 60 * 60;
    this.session.store.cookieExpirationTime = expirationTime;

    try
    {
      await this.session.authenticate(
        'authenticator:oauth2',
        identification,
        password,
      );
    } catch (error)
    {
      let Message = error.error || error;
      if (Message.includes('TypeError'))
      {
        Message = 'Server er utilg�ngelig, pr�v igen senere.';
      }
      this.errorMessage = Message;
    }
  }
}
