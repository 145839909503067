/* import __COLOCATED_TEMPLATE__ from './side-menu.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import DateUtils from 'boel/utils/DateUtils';
export default class AppSideMenuComponent extends Component {
  @service currentUser;
  @service router;

  @action
  focus_menu() {
    var objects = document.querySelector('nav.sidemenu');

    if (objects) {
      objects.firstChild.focus();
    }
  }
  get Today()
  { 
    return DateUtils.Get_ISO_Date();
  }
  @action
  Appointments_clicked() {
    if (this.router.currentRouteName === 'auth.aftaler.index') {
      // Reset state when clicking on appointnments rounte when already in appointments route.
      this.router.transitionTo('auth.aftaler.index', {
        queryParams: {
          date: null,
          pid: null,
          refresh: null,
          payments: null,
          stamdata: null,
          journal: null,
          edita: null,
        },
      });
    }
  }
  @action
  focus_indstillinger_menu() {
    const IsCorrectPath =
      this.router.currentRoute.parent == 'auth.superbruger.indstillinger';

    if (!IsCorrectPath) {
      this.router.transitionTo(
        'auth.superbruger.indstillinger.auth.superbruger.indstillinger.behandlinger',
      );
    } else {
      var objects = document.querySelector('nav.navside');
      if (objects) {
        objects.firstChild.focus();
      }
    }
  }
  @action
  focus_bruger_menu() {
    var objects = document.querySelector('a.dropdown-toggle');

    if (objects) {
      objects.firstChild.click();
      objects = document.querySelector('.dropdown-menu > .dropdown-item');
      objects.focus();
    }
  }
}
