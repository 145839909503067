/* import __COLOCATED_TEMPLATE__ from './inline-form-appointment.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import ENV from 'boel/config/environment';
import fetch from 'fetch';
import { debug } from '@ember/debug';

export default class AppInlineFormAppointmentComponent extends Component {
  @service notifications;
  @service intl;
  @service store;
  @tracked refresh = 'blah';

  @action
  async confirm(appointment_id) {
    var kvittering_link = ENV.APP.namespaceRoot;
    if (ENV.APP.namespace) {
      kvittering_link += '/' + ENV.APP.namespace;
    }
    kvittering_link += '/appointment_kvittering';

    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };
    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    fetch(kvittering_link + '?appointment_id=' + appointment_id, {
      dataType: 'text',
      method: 'GET',
      headers: headers,
      cache: 'no-cache',
    })
      .then((response) => {
        if (response.ok) {
          if (ENV.APP.ShowNotifications) {
            this.notifications.success(
              this.intl.t('form_appointment.email_send_success'),
            );
          }
          this.router.transitionTo(this.router.currentRoute.parent.name);
        } else {
          this.notifications.error(
            this.intl.t('form_appointment.email_send_fejl'),
          );
        }
      })
      .catch((arg1, arg2) => {
        this.notifications.error(
          this.intl.t('form_appointment.email_send_fejl'),
        );
        debug('arg1: ' + arg1);
        debug('arg2: ' + arg2);
      });
  }
  @action
  save_appointment() {
    try {
      this.args.appointment.save();
      if (ENV.APP.ShowNotifications) {
        this.notifications.success(
          this.intl.t('inline_form_appointment.success'),
        );
      }
      this.args.tilbage();
    } catch (ex) {
      this.notifications.error(
        this.intl.t('inline_form_appointment.error', {
          error: ex,
        }),
      );
    }
  }
}
