/* import __COLOCATED_TEMPLATE__ from './form-journal-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import
{
    tracked
  } from 'tracked-built-ins';
import { service } from '@ember/service';

export default class AppFormJournalListComponent extends Component {
  
  @service store;
  @tracked journals;
  @tracked Is_Loading = true;
  
  @action
  async Fetch_journals()
  {
    if (this.args.patient)
    {
      const patient_id = this.args.patient.id;

      if (patient_id)
      {
        this.Is_Loading = true;
        let j = await this.store.query('journal', {
          filter: "equals(patient.id,'" + patient_id + "')",
          include: 'patient,problem,therapist,journaltype,patient.outlet',
          sort: '-time,-created',
        });
        this.journals = j;
      }
    }
    this.Is_Loading = false;
  }
}
