/* import __COLOCATED_TEMPLATE__ from './manualer-list.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ManualerList extends Component {
  @service store;

  @tracked manualer = [];

  @tracked
  isLoading = false;

  constructor() {
    super(...arguments);

    this.manualer = [];
    this.getManualer();
  }

  getManualer() {
    this.isLoading = true;
    this.store.query('bundle', {}).then((manualer) => {
      // because this is async you need to guard against the component being destroyed before the api call has finished because because `this.set` will throw an error.
      if (this.isDestroyed) {
        return;
      }
      this.isLoading = false;
      this.manualer = manualer;
    });
  }
}
