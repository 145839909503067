/* import __COLOCATED_TEMPLATE__ from './diagram-data.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class AppDiagramDataComponent extends Component {
  @action
  Row_Sum(data) {
    let sum = 0;
    if (data) {
      data.forEach((item) => {
        sum = sum + item;
      });
    }
    return sum;
  }
  @action
  Total_Sum() {
    let sum = 0;
    if (this.args.data && this.args.data.datasets) {
      this.args.data.datasets.forEach((item) => {
        if (item.data) {
          item.data.forEach((item2) => {
            sum = sum + item2;
          });
        }
      });
    }
    return sum;
  }
  @action
  Column_Sum(index) {
    let sum = 0;
    if (this.args.data && this.args.data.datasets) {
      this.args.data.datasets.forEach((item) => {
        if (item.data) {
          let current_index = 0;
          item.data.forEach((item2) => {
            if (current_index === index) {
              sum = sum + item2;
            }
            current_index = current_index + 1;
          });
        }
      });
    }
    return sum;
  }
}
