/* import __COLOCATED_TEMPLATE__ from './bruger-liste.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class AppBrugerListeComponent extends Component {
  @service store;
  @tracked brugere;

  @action
  fill_brugere(brugere_in) {
    if (brugere_in) {
      brugere_in.forEach(async (element) => {
        if (element.outletid != -1) {
          element.outlet = await this.store.findRecord(
            'outlet',
            element.outletid,
          );
        }
      });
    }
    this.brugere = brugere_in;
  }
  constructor() {
    super(...arguments);
    this.fill_brugere(this.args.brugere);
  }
}
