import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthSuperbrugerIndstillingerBrugereAssociationsController extends Controller
{
  @service store;
  @service router;

  @action
  async cancel()
  {
    this.router.transitionTo(
      this.router.currentRoute.parent.name + '.edit',
      this.model.account.id,
    );
  }
  @action
  async save()
  {
    if (this.state)
    {
      const bruger_id = this.model.account.stringid;
      this.state.forEach(async (aState) =>
      {
        // Save
        if (aState.selected && !aState.saved_selected)
        {
          let association = this.store.createRecord('association', {
            bruger: bruger_id,
            outlet: aState.outlet,
          });
          await association.save();
        } // Delete
        else if (!aState.selected && aState.saved_selected)
        {
          if (aState.association)
          {
            await aState.association.destroyRecord();
          }
        }
      });
    }
    this.router.transitionTo(
      this.router.currentRoute.parent.name + '.edit',
      this.model.account.id,
    );
  }
}
