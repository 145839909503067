/* import __COLOCATED_TEMPLATE__ from './postnummer-chooser.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import { service } from '@ember/service';

export default class AppPostnummerChooserComponent extends Component {
  @service store;
  @tracked selected_object;

  @action
  update() {
    if (this.selected_object) {
      this.selected_object.destroyRecord();
    }

    if (this.args.postalcode) {
      this.selected_object = this.store.createRecord('postalcode', {
        city: this.args.city ?? '',
        number: this.args.postalcode ?? '',
      });
    }
  }
  @action
  Choose_Postal_Object(Postal_Code) {
    if (Postal_Code) {
      this._city = Postal_Code.city;
      this._postal_code = Postal_Code.number;
      this.args.onChange(Postal_Code.number, Postal_Code.city);
    }
  }
  @action
  Choose_Postal_Code(input) {
    let City_Name = '';
    let Postal_Code = '';
    if (input) {
      let position = input.indexOf(' ');
      if (position > 0) {
        City_Name = input.substring(position + 1);
        Postal_Code = input.substring(0, position);
      } else {
        Postal_Code = input;
      }
      if (Postal_Code) this.args.onChange(Postal_Code, City_Name);
    }
  }
  @action
  Update_City() {
    this.args.onChange(this.selected_object.number, this.selected_object.city);
  }
}
