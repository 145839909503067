/* import __COLOCATED_TEMPLATE__ from './scheduler.hbs'; */
import Component from '@glimmer/component';
import DateUtils from 'boel/utils/DateUtils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';

import Field from 'boel/utils/field';

export default class AppSchedulerComponent extends Component {
  @service router;
  @tracked model;
  @tracked Header_Columns;

  @action
  create_data_structure() {
    let appointments = this.args.appointments; //appointsments_in_period_asc
    let rooms = this.args.rooms;
    const calendar_startdate = new Date(this.args.calendar_startdate);
    let number_of_days = this.args.days;
    let calendar_enddate = DateUtils.AddOrSubtractDays(
      calendar_startdate,
      number_of_days,
      true,
    );
    let current_date = calendar_startdate;

    let Header_Columns = [];
    while (current_date < calendar_enddate) {
      Header_Columns.push({
        date: current_date,
      });
      current_date = DateUtils.AddOrSubtractDays(current_date, 1, true);
    }
    this.Header_Columns = Header_Columns;

    let Room_Rows = [];
    rooms.forEach((room) => {
      current_date = calendar_startdate;

      let room_appointments = !appointments
        ? null
        : appointments.filter((appointment) => {
            return appointment.room && appointment.room.id === room.id;
          });

      let Room_Columns = [];
      if (room_appointments) {
        room_appointments.forEach((appointment, index, arr) => {
          while (
            current_date < DateUtils.getDateWithoutTime(appointment.time)
          ) {
            Room_Columns.push(
              new Field(
                current_date,
                true,
                null,
                DateUtils.getDateWithoutTime(appointment.time),
              ),
            );

            current_date = DateUtils.AddOrSubtractDays(current_date, 1, true);
          }
          let next_appointment = calendar_enddate;
          if (index + 1 < arr.length) {
            next_appointment = DateUtils.getDateWithoutTime(
              arr[index + 1].time,
            );
          }
          Room_Columns.push(
            new Field(current_date, false, appointment, next_appointment),
          );
          current_date = DateUtils.AddOrSubtractDays(
            current_date,
            appointment.bookingdays,
            true,
          );
        });
      }
      while (current_date < calendar_enddate) {
        Room_Columns.push(
          new Field(current_date, true, null, calendar_enddate),
        );
        current_date = DateUtils.AddOrSubtractDays(current_date, 1, true);
      }
      Room_Rows.push({
        room: room,
        columns: Room_Columns,
      });
    });
    this.model = Room_Rows;
  }
  @action
  create_appointment(column, row) {
    this.args.book(column, row);
  }
  @action
  edit_appointment(column, row) {
    this.args.book(column, row);
  }
}
