import Model, { attr } from '@ember-data/model';

export default class TreatmentModel extends Model {
  @attr('string', { defaultValue: '' })
  name;

  @attr('number', { defaultValue: 0 })
  amount;

  @attr('number', { defaultValue: 0 })
  amount2;

  @attr('number', { defaultValue: 0 })
  duration;

  @attr
  created;

  @attr
  updated;
}
