/* import __COLOCATED_TEMPLATE__ from './small-box.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class SmallBoxComponent extends Component {
  @tracked maximized;

  constructor() {
    super(...arguments);

    this.maximized = this.args.maximized ?? false;
  }
}
