import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthSuperbrugerLogController extends Controller
{
  queryParams = ['searchword', 'valgtStartdato', 'valgtSlutdato'];
  @service session;
  @service excelexport;

  @tracked valgtStartdatoObj = null;
  @tracked valgtSlutdatoObj = null;
  @tracked valgtStartdato = null;
  @tracked valgtSlutdato = null;
  @tracked searchword;
  @tracked errorMessage = '';
  @tracked loading = false;

  @action
  setDateRange(erStartdato, datoArray)
  {
    let dato = datoArray && datoArray.length > 0 ? datoArray[0] : null;

    if (erStartdato)
    {
      this.valgtStartdatoObj = dato;
      this.valgtStartdato = dato
        ? dato.getFullYear() +
        '-' +
        (dato.getMonth() + 1) +
        '-' +
        dato.getDate()
        : '';
    } else
    {
      this.valgtSlutdatoObj = dato;
      this.valgtSlutdato = dato
        ? dato.getFullYear() +
        '-' +
        (dato.getMonth() + 1) +
        '-' +
        dato.getDate()
        : '';
    }
  }
  @action
  exceleksport(klasseNavn, filnavn, titel)
  {
    return new Promise((resolve, reject) =>
    {
      try
      {
        this.excelexport.export_table_to_csv(klasseNavn, filnavn, titel);
        resolve(true);
      } catch (err)
      {
        reject(new Error('Fejl: ' + err));
      }
    });
  }
}
