import Model, { attr, belongsTo } from '@ember-data/model';

export default class FavoritterModel extends Model {
  @attr('number')
  favoritId;

  @attr('number')
  favorittypeId;

  @belongsTo('favorittyper', {
    inverse: null,
    async: false,
  })
  favoritType;

  @belongsTo('user', {
    inverse: null,
    async: false,
  })
  userId;
}
