import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthPatienterJournalEditRoute extends Route {
  @service store;
  @service currentUser;

  async model(params) {
    const outlet_id = this.currentUser.CurrentActiveOutletId;

    return hash({
      journal: this.store.findRecord('journal', params.journal_id, {
        include: 'therapist,journaltype,problem',
      }),
      problems: await this.store.findAll('problem'),
      journaltypes: await this.store.findAll('journaltype'),
      therapists: await this.store.findAll('therapist', {
        filter: "equals(outlet.id,'" + outlet_id + "')",
        sort: 'name',
      }),
    });
  }
}
