import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthChangeoutletRoute extends Route
{
  @service currentUser;
  @service router;

  async beforeModel(transition)
  {
    let p_id = '';
    try { p_id = transition.to.queryParams.pid } catch {}

    await this.currentUser.ChangeOutlet(transition.to.params.outlet_id);
    this.router.transitionTo('auth.aftaler', {
      queryParams: { pid: p_id }
    });
  }

}
