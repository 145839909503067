import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import ENV from 'boel/config/environment';
export default class AuthSuperbrugerIndstillingerAfdelingerOpeninghoursEditController extends Controller
{
  @service router;
  @service intl;
  @service notifications;
  @service currentUser;
  
  @action
  save()
  {
    this.model.starttime = this.model.starttime;
    this.model.endtime = this.model.endtime;
    this.model.save();
    if (ENV.APP.ShowNotifications) {
      this.notifications.success(this.intl.t('openinghours.edit_success'));
    }
    let outlet_id = this.router.currentRoute.parent.params.outlet_id;

    if (this.currentUser.Is_CurrentActiveOutlet(outlet_id))
    {
      this.currentUser.ChangeOutlet(outlet_id);
    }
    
    this.router.transitionTo(
      'auth.superbruger.indstillinger.afdelinger.openinghours',
      outlet_id,
      {
        queryParams: { refreshhours: Date.now() },
      },
    );
  }
  @action
  cancel()
  {
    let outlet_id = this.router.currentRoute.parent.params.outlet_id;

    this.router.transitionTo(
      'auth.superbruger.indstillinger.afdelinger.openinghours',
      outlet_id,
    );
  }
}
