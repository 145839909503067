import Route from '@ember/routing/route';
import { service } from '@ember/service';
export default class IndexRoute extends Route {
  @service session;
  @service router;
  @service currentUser;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
    if (transition.to.name === 'index') {
      this.router.transitionTo('auth.index');
    }
  }
}
