/* import __COLOCATED_TEMPLATE__ from './form-appointment-edit.hbs'; */
import Component from '@glimmer/component';
import calender from '../../utils/calender';
import { action } from '@ember/object';
import stringutils from '../../utils/stringutils';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
export default class AppFormAppointmentEditComponent extends Component {
  @service store;

  start_time_string;
  end_time_string;

  disabled_dates;
  @tracked ChosenDate;
  @tracked chosenOutlet;
  @tracked outletId;

  openinghours;
  closedperiods;

  @tracked refreshDatepicker;

  constructor(...args) {
    super(...args);
    this.openinghours = this.args.openinghours;
    this.closedperiods = this.args.closedperiods;
    if (this.args.appointment.outlet) {
      this.chosenOutlet = this.args.appointment.outlet;
      this.outletId = this.args.appointment.outlet.id;
    }
  }
  @action
  update() {
    let now = new Date();
    if (this.args.appointment && this.args.appointment.time) {
      this.ChosenDate = this.args.appointment.time;
    } else {
      this.ChosenDate = now;
    }
    let theCalendar = new calender();

    const outlet = this.args.appointment.outlet;
    const danishHolidays = outlet.danishHolidays;
    if (danishHolidays) {
      this.disabled_dates = theCalendar.Helligdage(now.getFullYear());
      this.disabled_dates = this.disabled_dates.concat(
        theCalendar.Helligdage(now.getFullYear() + 1),
      );
    } else {
      this.disabled_dates = [];
    }
    if (this.closedperiods) {
      this.closedperiods.forEach((element) => {
        let current = element.start;
        if (typeof current == 'string') {
          current = new Date(current);
        }
        while (current <= element.end) {
          this.disabled_dates.push(current);

          let dagen_efter_ms = current.getTime() + 86400000;
          current = new Date(dagen_efter_ms);
        }
      });
    }

    let starttime = null;
    let endtime = null;

    var relevant_opening_hours = this.openinghours.filter((element) => {
      var relevant = element.startdate == null || element.startdate < now;
      relevant = (relevant && element.enddate == null) || element.enddate > now;

      if (relevant) {
        let day_of_week = now.getDay();
        switch (day_of_week) {
          case 0:
            relevant = element.hassunday;
            break;
          case 1:
            relevant = element.hasmonday;
            break;
          case 2:
            relevant = element.hastuesday;
            break;
          case 3:
            relevant = element.haswednesday;
            break;
          case 4:
            relevant = element.hasthursday;
            break;
          case 5:
            relevant = element.hasfriday;
            break;
          case 6:
            relevant = element.hassaturday;
            break;
        }
      }
      return relevant;
    });

    relevant_opening_hours.forEach((element) => {
      if (!starttime || element.starttime < starttime) {
        starttime = element.starttime;
      }
      if (!endtime || element.endtime > endtime) {
        endtime = element.endtime;
      }
    });

    if (this.openinghours.length === 0) {
      this.start_time_string = '08:00';
      this.end_time_string = '18:00';
    } else {
      if (starttime) {
        if (typeof starttime == 'string') {
          starttime = new Date(starttime);
        }
        this.start_time_string = starttime.toLocaleTimeString('da-DK');
      }
      if (endtime) {
        if (typeof endtime == 'string') {
          endtime = new Date(endtime);
        }
        this.end_time_string = endtime.toLocaleTimeString('da-DK');
      }
    }
    this.refreshDatepicker = new Date().getTime();
  }

  @action
  setDate(a, b /*, c, d*/) {
    this.ChosenDate = b[0];
    a.setValue(b);
  }
  @action
  async chooseOutlet(outlet) {
    if (outlet != this.chosenOutlet) {
      this.chosenOutlet = outlet;
      this.args.appointment.outlet = this.chosenOutlet;

      let id = -1;

      if (outlet) {
        id = outlet.id;
      }
      this.outletId = id;

      this.openinghours = await this.store.query('closedperiod', {
        filter: "equals(outlet.id,'" + this.outletId + "')",
      });
      this.closedperiods = await this.store.query('openinghour', {
        filter: "equals(outlet.id,'" + this.outletId + "')",
      });
      this.init();
    }
  }
  @action
  disable(date) {
    let theDate = stringutils.StripTimezone(date.toDateString());

    let disabled = false;
    for (var i = 0; i < this.disabled_dates.length; i++) {
      let closedDate = stringutils.StripTimezone(
        this.disabled_dates[i].toDateString(),
      );

      if (theDate == closedDate) {
        disabled = true;
        break;
      }
    }
    if (disabled) {
      return true;
    }
    const outlet = this.args.appointment.outlet;

    const closedSaturday = outlet.closedSaturday;
    if (closedSaturday && date.getDay() === 6) {
      return true;
    }
    const closedSunday = outlet.closedSunday;
    if (closedSunday && date.getDay() === 0) {
      return true;
    }
    return false;
  }
}
