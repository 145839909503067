/* import __COLOCATED_TEMPLATE__ from './form-patient.hbs'; */
import Component from '@glimmer/component';
import * as countries from 'i18n-iso-countries';
import * as danish from 'i18n-iso-countries/langs/da.json';
import ENV from 'boel/config/environment';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked, cached } from '@glimmer/tracking';
export default class AppFormPatientComponent extends Component {
  Countries;
  Languages;

  @service store;
  @service currentUser;
  @service router;
  @service patienter;
  @service intl;

  @tracked chosenOutlet;
  @tracked outletId;
  @tracked found_patient;
  @tracked kan_tilknyttes;
  @tracked sdmedlem;
  @tracked sother;
  @tracked Show_CPR_Error;
  @tracked Show_Confirm;
  @tracked Open = true;

  @cached
  get CPR_Should_Be_Defined() {
    return this.args.isNew && (this.sdmedlem || this.sother);
  }
  @action
  hide() {
    if (confirm(this.intl.t('form_patient.warning'))) {
      this.Open = false;
    } else {
      this.Open = true;
      return false;
    }
  }
  @action
  choose_cpr(el, e) {
    if (el && e && e.target && e.target.value) {
      el.setValue(e.target.value);
    }
  }
  @action
  async chooseOutlet(outlet) {
    if (outlet != this.chosenOutlet) {
      this.chosenOutlet = outlet;
      this.args.patient.outlet = this.chosenOutlet;

      let id = -1;

      if (outlet) {
        id = outlet.id;
      }
      this.outletId = id;
    }
  }
  @action
  async save(changeset) {
    if (this.args.isNew) {
      changeset.set('outlet', this.chosenOutlet);

      let found_patient = null;
      let not_found = false;
      let cpr_nummer = changeset.get('cpr');
      let cellphone = changeset.get('cellphone');

      // Er forsikring valgt?
      if (this.CPR_Should_Be_Defined) {
        //Er CPR nummer udfyldt
        if (cpr_nummer && cpr_nummer.length === 10) {
          // Findes patient allerede?
          found_patient = await this.patienter.cpr_search(cpr_nummer);
          this.Show_CPR_Error = false;
        } else {
          this.Show_CPR_Error = true;
          return false;
        }
      } else if (cellphone && cellphone.length > 7) {
        // Findes patient allerede?
        found_patient = await this.patienter.cellphone_search(cellphone);
      }
      not_found = found_patient == null;

      if (not_found) {
        this.args.save(changeset);
      } else {
        this.found_patient = found_patient;
        this.kan_tilknyttes = this.patienter.kan_tilknyttes(
          this.found_patient,
          this.currentUser,
        );
      }
    } else {
      this.args.save(changeset);
    }
  }
  @action
  oncreate(a, b) {
    try {
      if (a) {
        a.setValue(b);
      }
    } catch (err) {}
  }
  @action
  async create_problem(search_term) {
    let problem = await this.store.createRecord('problem', {
      name: search_term,
    });
    await problem.save();
    this.args.patient.problem = problem;
  }
  @action
  customSuggestion(search_term) {
    return this.intl.t('form_payment.create_problem_suggestion', {
      search_term,
    });
  }
  @action
  Choose_Postal_Code(number, city) {
    this.args.patient.postalcode = number;
    this.args.patient.city = city;
  }
  constructor() {
    super(...arguments);
    this.Show_CPR_Error = false;
    this.sother = this.args.patient.sother;
    this.sdmedlem = this.args.patient.sdmedlem;

    if (this.args.outlet) {
      this.chosenOutlet = this.args.outlet;
      this.outletId = this.args.outlet.id;
    }
    countries.registerLocale(danish);

    this.Countries = Object.values(countries.getNames('da')).sort();

    let languageNames = new Intl.DisplayNames(['da'], { type: 'language' });
    this.Languages = [];
    ENV.supported_languages.forEach((element) => {
      this.Languages.push(languageNames.of(element));
    });
  }
}
