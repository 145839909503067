/* import __COLOCATED_TEMPLATE__ from './info-button.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'boel/config/environment';

export default class HelpInfoButtonComponent extends Component {
  @tracked IsLoaded;
  @tracked IsLoading;
  @tracked Teaser;
  @tracked ArticleId;
  @tracked Link;

  //@service store;
  @service articles;

  constructor() {
    super(...arguments);

    this.ArticleId = -1;
    this.IsLoading = true;
    this.IsLoaded = false;
    this.Link = this.args.link ?? 'help.view';

    let component = this.args.component ?? '';
    let category = this.args.category ?? '';
    let tags = this.args.tags ?? '';
    let route = this.args.route ?? '';
    
    if (ENV.APP.ShowInfobuttons)
    {
      this.FetchArticle(component, category, tags, route);
    }
  }
  async FetchArticle(component, category, tags, route) {
    let artikel = await this.articles.FindEn.perform(
      component,
      category,
      tags,
      route,
    );

    if (artikel) {
      this.ArticleId = artikel.id;
      this.Teaser = artikel.teaser;
      this.IsLoaded = true;
      this.IsLoading = false;
    }
  }
}
