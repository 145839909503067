/* import __COLOCATED_TEMPLATE__ from './info-kvittering.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import fetch from 'fetch';
import { debug } from '@ember/debug';
import { tracked } from '@glimmer/tracking';
import ENV from 'boel/config/environment';

export default class AppInfoKvitteringComponent extends Component {
  @tracked kvittering;

  @service router;
  @service store;
  @service currentUser;
  @service session;
  @service notifications;
  @service intl;

  async Fetch_Kvittering() {
    var printkvittering_link = ENV.APP.namespaceRoot;
    if (ENV.APP.namespace) {
      printkvittering_link += '/' + ENV.APP.namespace;
    }
    printkvittering_link += '/printkvittering';
    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };
    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    let response = await fetch(
      printkvittering_link + '?payment_id=' + this.args.payment.id,
      {
        dataType: 'text',
        method: 'GET',
        headers: headers,
        cache: 'no-cache',
      },
    );
    if (response.ok) {
      let returned_content = await response.json();
      this.kvittering = returned_content.content;
    } else {
      this.notifications.error(this.intl.t('form_payment.email_send_fejl'));
    }
  }
  constructor() {
    super(...arguments);

    this.Fetch_Kvittering();
  }
  @action
  print() {
    window.print();
  }
  @action
  async email() {
    const payment_id = this.args.payment.id;
    const kvittering_link =
      ENV.APP.namespaceRoot + '/' + ENV.APP.namespace + '/kvittering';

    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };
    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    return fetch(kvittering_link + '?payment_id=' + payment_id, {
      dataType: 'text',
      method: 'GET',
      headers: headers,
      cache: 'no-cache',
    })
      .then((response) => {
        if (response.ok) {
          if (ENV.APP.ShowNotifications) {
            this.notifications.success(
              this.intl.t('form_payment.email_send_success'),
            );
          }
          //  this.router.transitionTo(this.router.currentRoute.parent.name);
        } else {
          this.notifications.error(this.intl.t('form_payment.email_send_fejl'));
        }
      })
      .catch((arg1, arg2) => {
        this.notifications.error(this.intl.t('form_payment.email_send_fejl'));
        debug('arg1: ' + arg1);
        debug('arg2: ' + arg2);
      });
  }
}
