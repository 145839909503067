import { service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import ENV from 'boel/config/environment';
export default class AuthSuperbrugerIndstillingerMessagesDeleteController extends Controller
{
  @service notifications;
  @service intl;
  @service store;
  @service router;

  @action
  delete()
  {
    this.model.destroyRecord().then(() =>
    {
      if (ENV.APP.ShowNotifications) {
        this.notifications.success(this.intl.t('messages.delete_success'));
      }
      this.router.transitionTo('auth.superbruger.indstillinger.messages', {
        queryParams: { refresh: Date.now() },
      });
    });
  }

  @action
  cancel()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.messages');
  }
}
