/* import __COLOCATED_TEMPLATE__ from './bills-patients-list.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import betalinger from 'boel/utils/betalinger';
import fetch from 'fetch';
import {
  isServerErrorResponse,
  isUnauthorizedResponse,
} from 'ember-fetch/errors';
import ENV from 'boel/config/environment';
import error from 'boel/utils/error';
import { debug } from '@ember/debug';

export default class AppBillsPatientsListComponent extends Component {
  @service session;
  @service router;
  @service notifications;
  @service intl;

  @tracked patients_with_appointments;

  constructor() {
    super(...arguments);
    let insurancecases = this.args.insurancecases;
    let appointments;
    try {
      let appointments_unsorted = [...this.args.appointments.slice()];

      appointments = appointments_unsorted.sort((a, b) => {
        if (a.patient.id < b.patient.id) {
          return -1;
        }
        if (a.patient.id > b.patient.id) {
          return 1;
        }
        return 0;
      });
    } catch (err) {
      debug(err);
    }
    let current_patient = null;
    let current_appointments = null;
    let result = [];
    if (appointments) {
      appointments.forEach((appointment) => {
        let r = betalinger.get_is_insured(appointment, insurancecases, appointments, false);
        appointment.has_insurancecase = r.has_insurancecase;
        if (!appointment.insurancecase ||
            appointment.insurancecase.id != r.insurancecase.id) {
          appointment.ainsurancecase = r.insurancecase;
        }
        appointment.background_class = betalinger.get_background_color(appointment);

        if (current_patient == null) {
          current_appointments = [];
          current_patient = appointment.patient;
        }
        if (current_patient.id != appointment.patient.id) {
          let Patient_With_Appointments = {
            patient: current_patient,
            appointments: current_appointments,
            selected: tracked(new Map()),
          };
          result.push(Patient_With_Appointments);
          current_appointments = [];
          current_patient = appointment.patient;
        }
        current_appointments.push(appointment);
      });
      if (current_patient != null) {
        let Patient_With_Appointments = {
          patient: current_patient,
          appointments: current_appointments,
          selected: tracked(new Map()),
        };
        result.push(Patient_With_Appointments);
      }
    }
    this.patients_with_appointments = result;
  }
  get patients_with_appointments_get() {
    return this.patients_with_appointments;
  }
  fnGetFileNameFromContentDispostionHeader(header) {
    const save_header = header ? header : '';
    let contentDispostion = save_header.split(';');
    const fileNameToken = `filename*=UTF-8''`;

    let fileName = 'faktura.pdf';
    for (let thisValue of contentDispostion) {
      if (thisValue.trim().indexOf(fileNameToken) === 0) {
        fileName = decodeURIComponent(
          thisValue.trim().replace(fileNameToken, ''),
        );
        break;
      }
    }
    return fileName;
  }
  @action
  view_insurance()
  { 
    
  }
  @action
  async fetchPDF(appointment_id, is_draft) {
    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };
    let link = ENV.APP.namespaceRoot + '/' + ENV.APP.namespace;

    link += is_draft
      ? '/economic/getdraftedpdf?_id='
      : '/economic/getbookedpdf?_id=';
    link += appointment_id;

    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    await fetch(link, {
      dataType: 'text',
      method: 'GET',
      headers: headers,
      cache: 'no-cache',
    })
      .then(async (res) => ({
        filename: this.fnGetFileNameFromContentDispostionHeader(
          res.headers.get('content-disposition'),
        ),
        blob: await res.blob(),
      }))
      .then((resObj) => {
        // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
        const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });

        // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob, resObj.filename);
        } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = window.URL.createObjectURL(newBlob);

          let link = document.createElement('a');
          link.href = objUrl;
          link.download = resObj.filename;
          link.click();

          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
          }, 250);
        }
      })
      .catch((err) => {
        const Message = error.get_message(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          }),
        );
      });
  }
  @action
  update_paid(appointment) {
    appointment.paid = !appointment.paid;
    appointment.save();
    this.router.transitionTo('auth.superbruger.bills', {
      queryParams: { refresh: Date.now() },
    });
  }
  @action
  async opret_valgte_faktura(patient_with_appointments) {
    let fakturaer = [];
    for (const [key, value] of patient_with_appointments.selected) {
      fakturaer.push({
        AppointmentId: key,
        ToBeBooked: false,
      });
    }
    await this.opret_faktura(fakturaer);
    this.router.transitionTo('auth.superbruger.bills', {
      queryParams: { refresh: Date.now() },
    });
  }
  @action
  async opret_enkelt_faktura(appointment_id) {
    let fakturaer = [
      {
        AppointmentId: appointment_id,
        ToBeBooked: false,
      },
    ];
    await this.opret_faktura(fakturaer);
  }
  @action
  async opret_faktura(fakturaer) {
    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };
    let link =
      ENV.APP.namespaceRoot +
      '/' +
      ENV.APP.namespace +
      '/economic/sendMultiInvoiceDraft';

    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    await fetch(link, {
      dataType: 'json',
      method: 'POST',
      headers: headers,
      cache: 'no-cache',
      body: JSON.stringify(fakturaer),
    })
      .then((response) => {
        if (response.ok) {
          return true;
        } else if (isUnauthorizedResponse(response)) {
          this.notifications.info(this.intl.t('login.session_timeout'));
          this.session.invalidate();
        } else if (isServerErrorResponse(response)) {
          this.notifications.error(this.intl.t('login.server_error'));
        }
      })
      .catch((err) => {
        const Message = error.get_message(err);
        console.error(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          }),
        );
      });
  }
  @action
  mark_as_paid(patient_with_appointments) {
    for (const [key, appointment] of patient_with_appointments.selected) {
      appointment.paid = true;
      appointment.save();
    }
    this.router.transitionTo('auth.superbruger.bills', {
      queryParams: { refresh: Date.now() },
    });
  }
  @action
  Marker(appointment, patient_with_appointments) {
    if (patient_with_appointments.selected.has(appointment.id)) {
      patient_with_appointments.selected.delete(appointment.id);
    } else {
      patient_with_appointments.selected.set(appointment.id, appointment);
    }
  }
}
