import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import ENV from 'boel/config/environment';
import { task } from 'ember-concurrency';
export default class AuthSuperbrugerIndstillingerAfdelingerOpeninghoursNewController extends Controller
{
  @service router;
  @service intl;
  @service notifications;
  @service currentUser;
  
  @task
  *save()
  {
    this.model.starttime = this.model.starttime;
    this.model.endtime = this.model.endtime;

    let outlet_id = this.router.currentRoute.parent.params.outlet_id;

    yield this.model.save().then(() =>
    {
      if (ENV.APP.ShowNotifications) {
        this.notifications.success(this.intl.t('openinghours.opret_success'));
      }
      if (this.currentUser.Is_CurrentActiveOutlet(outlet_id))
      {
        this.currentUser.ChangeOutlet(outlet_id);
      }
      this.router.transitionTo(
        'auth.superbruger.indstillinger.afdelinger.openinghours',
        outlet_id,
        {
          queryParams: { refreshhours: Date.now() },
        },
      );
    });
  }
  @action
  cancel()
  {
    this.model.unloadRecord();

    let outlet_id = this.router.currentRoute.parent.params.outlet_id;

    this.router.transitionTo(
      'auth.superbruger.indstillinger.afdelinger.openinghours',
      outlet_id,
    );
  }
}
