import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'boel/config/environment';
import Model from '@ember-data/model';
//import 'ember-power-select/styles';
import 'ember-power-select/themes/bootstrap';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}
Model.modelName = '@ember-data/model';
import 'bootstrap/dist/css/bootstrap.css';
//import './css/favoritter.css';
import './css/app.css';
  import './css/fonts.css';
  import './css/sidemenu.css';
  import './css/navside.css';
  import './css/panel.css';
  import './css/filterbox.css';
  import './css/list.css';
  import './css/utility.css';
  import './css/searchResult.css';
  import './css/legend.css';
  import './css/selectAnimation.css';
  import './css/diagrammer.css';
  import './css/print.css';
  import './css/forms.css';
  import './css/cards.css';
  import './css/chart.css';
  import './css/usermenu.css';

  import './css/listitem.css';
  import './css/login.css';
  import './css/loading.css';
  import './css/spinner.css';
  import './css/datepicker.css';
  import './css/bootstrap4.css';
  import './css/artikler.css';

  import './css/buttons.css';
  import './css/yeti.css';
  import './css/fileupload.css';
  import './css/topnavigation.css';
  import './css/colorpicker.css';
  import './css/cursor.css';
  import './css/betalinger.css';
  import './css/calendartooltip.css';
loadInitializers(App, config.modulePrefix);
