/* import __COLOCATED_TEMPLATE__ from './inline-new-appointment.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AppInlineNewAppointmentComponent extends Component {
  @service notifications;
  @service store;
  @service currentUser;

  @tracked problem;
  @tracked status;
  @tracked states;

  constructor(...args) {
    super(...args);
    this.states = this.store.findAll('appointmentstate');
  }
  @action
  async update()
  {
    if (this.args.edit && this.args.patient) {
      this.problem = this.args.patient.problemtext;
      this.status = null;
      if (this.args.chosenAppointment)
      {
        this.status = this.args.chosenAppointment.appointmentstate;
      }
    }
    else
    {
      if (this.args.chosenAppointment)
        {
          this.status = this.args.chosenAppointment.appointmentstate;
        }
      if (this.args.patient)
      {
        this.problem = this.args.patient.problemtext;
       // this.status = null;
      }
      else if (this.args.chosenAppointment)
      {
        this.problem = this.args.chosenAppointment.patient.problemtext
      } 
      else
      { 
        this.problem = '';
        this.status = null;
      }
    }
  }
  @action
  async book() {
    try {
      let ChosenTime = this.args.date;
      ChosenTime = ChosenTime;
      const chosen_outlet = this.currentUser.CurrentActiveOutlet;

      const newAppointment = await this.store.createRecord('appointment', {
        outlet: chosen_outlet,
        problemtext: this.problem,
        time: ChosenTime,
        patient: this.args.patient,
        appointmentstate: this.status,
        amount: this.args.patient.amount
      });
      await newAppointment.save();
      this.args.create_appointment(newAppointment);
      this.status = null;

    } catch (err) {
      this.notifications.error(err);
    }
  }
}
