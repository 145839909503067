/* import __COLOCATED_TEMPLATE__ from './postnummer-chooser-simple.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import { service } from '@ember/service';

export default class AppPostnummerChooserSimpleComponent extends Component {
  @service store;
  @tracked postal_code;
  @tracked city;

  Postalcodes;

  @action
  update() {
    this.postal_code = this.args.postalcode;
    this.city = this.args.city;
  }
  @action
  async Find_PostalCodes() {
    const result = await this.store.findAll('postalcode');
    if (result) {
      this.Postalcodes = result.slice();
    }
  }

  @action insert() {
    this.Find_PostalCodes();
    this.update();
  }

  @action Choose_Postal() {
    if (this.Postalcodes) {
      for (let i = 0; i < this.Postalcodes.length; i++) {
        const code = this.Postalcodes[i];
        if (code.number == this.postal_code) {
          this.city = code.city;
          break;
        }
      }
    }
    this.args.onChange(this.postal_code, this.city);
  }
  @action Update_City() {
    this.args.onChange(this.postal_code, this.city);
  }
}
