import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerAfdelingerClosedperiodsController extends Controller
{
  @service router;

  @action
  cancel()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.afdelinger');
  }
  @action
  toggle(arg1)
  {
    this.model.outlet[arg1] = !this.model.outlet[arg1];
    this.model.outlet.save();
  }
}
