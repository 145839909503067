import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class AccountModel extends Model {
  @attr('string', { defaultValue: '' })
  userName;

  @attr('string', { defaultValue: '' })
  stringid;

  @attr('boolean', { defaultValue: false })
  treats;

  @attr('string', { defaultValue: '' })
  email;

  @attr('string', { defaultValue: '' })
  firstname;

  @attr('string', { defaultValue: '' })
  lastname;

  @attr('string', { defaultValue: '' })
  smartphone;

  @attr('string', { defaultValue: '' })
  password;

  @attr('string', { defaultValue: '' })
  confirmPassword;

  @attr('string', { defaultValue: '' })
  userRole;

  @attr('number', { defaultValue: false })
  outletid;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @hasMany('association', {
    inverse: null,
    async: false,
  })
  associations;

  @attr
  created;

  @attr
  updated;

  get fullname() {
    let fullname = '';
    fullname = this.firstname;
    if (this.lastname != '') {
      if (this.firstname != '') {
        fullname = fullname + ' ';
      }
      fullname = fullname + this.lastname;
    }
    return fullname == '' ? null : fullname;
  }
}
