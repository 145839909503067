/* import __COLOCATED_TEMPLATE__ from './button-with-confirmation.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class AppButtonWithConfirmationComponent extends Component {
  @tracked isConfirming = false;

  @action
  launchConfirmDialog() {
    this.isConfirming = true;
  }
  @action
  async submitConfirm() {
    if (this.args.onConfirm) {
      await this.args.onConfirm();
    }
    this.isConfirming = false;
  }
  @action
  cancelConfirm() {
    this.isConfirming = false;
  }
}
