import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthPatienterStamdataRoute extends Route {
  @service store;
  @service currentUser;

  model(params) {
    return this.store.findRecord('patient', params.patient_id);
  }
}
