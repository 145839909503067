export default class stringutils {
  static padLeadingZeros(num, size) {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }
  static StripTimezone(s) {
    let ar = s.split('GMT');
    if (Array.isArray(ar)) {
      return ar[0];
    } else {
      return ar;
    }
  }
  static Remove_Non_Numeric(str) {
   
    return str.replace(/[^\d]/g, '');

  }
  static TryParseInt(str, defaultValue) {
    var retValue = defaultValue;
    if (str !== null) {
      str = str.trim();
      if (str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseInt(str);
        }
      }
    }
    return retValue;
  }
  static Udfyld_Skabelon(skabelon, templateOptions) {
    let keys = Object.keys(templateOptions);
    for (var i = 0; i < keys.length; i++) {
      const placeholder = keys[i];
      if (placeholder) {
        const content = templateOptions[placeholder];
        skabelon = skabelon.replaceAll(placeholder, content);
      }
    }
    return skabelon;
  }
}
