/* import __COLOCATED_TEMPLATE__ from './form-payment-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked, cached } from '@glimmer/tracking';
import { service } from '@ember/service';

export default class AppFormPaymentListComponent extends Component {
  @tracked Visningstype = 1;
  @service router;
  @service store;
  @service currentUser;
  @service session;
  @service notifications;
  @service intl;

  constructor() {
    super(...arguments);
    this.Visningstype = 1;
  }
  @cached
  get total_count() {
    return this.args.payments.length;
  }
  @cached
  get paid_count() {
    return this.args.payments.filter((element) => {
      return element.paid;
    }).length;
  }
  @cached
  get unpaid_count() {
    return this.args.payments.filter((element) => {
      return !element.paid;
    }).length;
  }
  @cached
  get unpaid() {
    return this.args.payments.reduce(function (total, element) {
      let thousands_seps = '.';
      let decimal_sep = ',';

      let amount = 0;
      if (typeof element.amount == 'string') {
        let sanitizeValue = element.amount
          .replace(thousands_seps, '')
          .replace(decimal_sep, '.')
          .replace(/[^0-9.-]+/, '');
        amount = parseFloat(sanitizeValue);
      } else {
        amount = element.amount;
      }
      return total + (element.paid ? 0 : amount);
    }, 0);
  }

  @action
  UpdateAmount(payment) {
    payment.save();
  }
  @action
  TogglePaid(payment /*, newValue*/) {
    payment.paid = !payment.paid;
    payment.time = new Date();
    payment.save();

    payment.appointment.paid = payment.paid;
    payment.appointment.save();
  }
  @action
  ToggleManuel(payment /*, newValue*/) {
    payment.manuel = !payment.manuel;
    payment.save();
  }
}
