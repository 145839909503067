import Model, { attr } from '@ember-data/model';

export default class Sikkerhedslog extends Model {
  @attr('date')
  tidspunkt;

  @attr('number', { defaultValue: '' })
  brugerId;

  @attr('string', { defaultValue: '' })
  brugerNavn;

  @attr('string', { defaultValue: '' })
  brugerFuldtNavn;

  @attr('string', { defaultValue: '' })
  tilgangstype;

  @attr('string', { defaultValue: '' })
  resultat;

  @attr('string', { defaultValue: '' })
  databeskrivelse;

  @attr('date')
  filterPeriodeStart;

  @attr('date')
  filterPeriodeSlut;

  @attr('string', { defaultValue: '' })
  filterTekst;

  @attr('string', { defaultValue: '' })
  udvidetFilter1;

  @attr('string', { defaultValue: '' })
  udvidetFilter2;

  @attr('string', { defaultValue: '' })
  udvidetFilter3;

  @attr('string', { defaultValue: '' })
  udvidetFilter4;
}
