/* import __COLOCATED_TEMPLATE__ from './patient-liste.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AppPatientListeComponent extends Component {
  @service router;
  @service store;

  @action
  async Allow_Deletion(patient) {
    let used_appointments = await this.store.query('appointment', {
      filter:
        "and(not(equals(committed,'')),equals(patient.id,'" +
        patient.id +
        "'))",
      });
    return (
      used_appointments == null ||
      used_appointments == '' ||
      (Array.isArray(used_appointments.content) &&
        used_appointments.content.length === 0)
    );
  }
  @action
  navigate_row(patient) {
    this.router.transitionTo('auth.aftaler.patient', patient.id);
  }
}
