/* import __COLOCATED_TEMPLATE__ from './payment-history.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Sdk from 'boel/utils/sdk';

export default class AppPaymentHistoryComponent extends Component {
  @service store;
  @tracked log_entries;

  @action
  async update() {
    if (this.args.payment != null) {
      const id = this.args.payment.id;
      let log_entries = await this.store.query('log', {
        filter: "equals(reference,'" + id + "')",
        sort: '-tidspunkt',
      });
      this.log_entries = log_entries;
    }
  }
  get BackgroundColor() {
    const payment = this.args.payment;
    let result = '';

    if (payment && payment.lastStatusCode) {
      const is_error = Sdk.Is_Error(payment.lastStatusCode);
      if (is_error) {
        result = 'bg-danger text-white';
      } else {
        result = 'bg-success text-white';
      }
    }
    return result;
  }
  @action
  Log_BackgroundColor(log_entry) {
    let result = '';
    if (log_entry) {
      if (log_entry.statustype === 0) {
        result = 'bg-warning';
      } else if (log_entry.statustype === 1) {
        result = 'bg-success text-white';
      }
    }
    return result;
  }
}
