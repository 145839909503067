import { action } from '@ember/object';
import { service } from '@ember/service';
import ENV from 'boel/config/environment';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import fetch from 'fetch';
import
  {
    isServerErrorResponse,
    isUnauthorizedResponse,
    isAbortError,
  } from 'ember-fetch/errors';
import
  {
    validateConfirmation,
    validatePresence,
    validateLength,
    validateFormat,
  } from 'ember-changeset-validations/validators';

export default class AuthSkiftpasswordController extends Controller
{
  @service notifications;
  @service session;
  @service router;
  @service intl;

  @tracked serverError = [];
  @tracked errorMessage = '';

  validations = {
    OldPassword: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    NewPassword: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
      validateLength({
        min: 6,
        max: 1024,
        message: this.intl.t('validations.wrongLength', {
          description: this.intl.t('brugere.NewPassword_field'),
          is: 6,
        }),
      }),
      validateFormat({
        regex: '(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*',
        message: this.intl.t('validations.format'),
      }),
    ],
    ConfirmPassword: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
      validateConfirmation({
        on: 'NewPassword',
        message: this.intl.t('validations.confirmation', {
          description: this.intl.t('brugere.NewPassword_field'),
        }),
      }),
      validateFormat({
        regex: '(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*',
        message: this.intl.t('validations.format'),
      }),
    ],
  };

  @action
  save(changeset)
  {
    const OldPassword = changeset.get('OldPassword');
    const NewPassword = changeset.get('NewPassword');
    const ConfirmPassword = changeset.get('ConfirmPassword');

    if (this.session.isAuthenticated)
    {
      const headers = {
        'Content-Type': 'application/json',
      };
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;

      let link =
        ENV.APP.namespaceRoot +
        '/' +
        ENV.APP.namespace +
        '/auth/ChangePassword';

      fetch(link, {
        method: 'POST',
        cache: 'no-cache',
        headers: headers,
        body: JSON.stringify({
          OldPassword: OldPassword,
          NewPassword: NewPassword,
          ConfirmPassword: ConfirmPassword,
          UserName: this.session.data.authenticated.userName,
        }),
      })
        .then((response) =>
        {
          if (response.ok)
          {
            if (ENV.APP.ShowNotifications) {
              this.notifications.success(
                this.intl.t('login.password_changed_success'),
              );
            }
            this.session.invalidate();
          } else if (isUnauthorizedResponse(response))
          {
            // handle 401 response
            this.notifications.error(this.intl.t('login.session_timeout'));
            this.session.invalidate();
          } else if (isServerErrorResponse(response))
          {
            // handle 5xx respones
            this.notifications.error(this.intl.t('login.server_error'));
          }
        })
        .catch((error) =>
        {
          if (isAbortError(error))
          {
            // handle aborted network error
            this.notifications.error(this.intl.t('login.network_error'));
          }
        });
    }
  }
  @action
  cancel()
  {
    history.back();
  }
}
