/* import __COLOCATED_TEMPLATE__ from './fornybruger.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import { inject as service } from '@ember/service';
import ENV from 'boel/config/environment';
import fetch from 'fetch';
import {
  isServerErrorResponse,
  isUnauthorizedResponse,
} from 'ember-fetch/errors';
import { debug } from '@ember/debug';

export default class KnapFornybrugerComponent extends Component {
  @service session;
  @service router;
  @tracked isLoading = false;
  @tracked isSuccess = false;
  @tracked isError = false;
  @tracked errorMessage = '';

  @action
  clear() {
    this.isLoading = false;
    this.isSuccess = false;
    this.isError = false;
    this.errorMessage = '';
  }

  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(user) {
    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };

    if (user) {
      if (this.session.isAuthenticated) {
        headers[
          'Authorization'
        ] = `Bearer ${this.session.data.authenticated.access_token}`;
      }

      yield fetch(ENV.APP.host + '/' + ENV.APP.namespace + '/Account/Forny', {
        headers: headers,
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
          data: {
            type: 'FornyModel',
            attributes: {
              UserId: user,
            },
          },
        }),
      })
        .then((response) => {
          if (response.ok) {
            this.isLoading = false;
            this.isSuccess = true;
            window.setTimeout(this.clear, 2000);
          } else if (isUnauthorizedResponse(response)) {
            this.notifications.info(this.intl.t('login.session_timeout'));
            this.router.transitionTo('login');
          } else if (isServerErrorResponse(response)) {
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = this.intl.t('login.server_error');
            window.setTimeout(this.clear, 5000);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.isError = true;
          this.errorMessage = this.intl.t('login.network_error');
          window.setTimeout(this.clear, 5000);
          debug('Fejl under fetch: ');
          debug(error);
        });
    }
  }

  @action
  ExecuteRenewal() {
    this.isLoading = true;
    return this.saveTask.perform(this.args.bruger);
  }
}
