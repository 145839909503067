import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import
  {
    validatePresence,
    validateDate,
  } from 'ember-changeset-validations/validators';
import error from 'boel/utils/error';
import ENV from 'boel/config/environment';
export default class AuthSuperbrugerIndstillingerAfdelingerClosedperiodsEditController extends Controller
{
  @service router;
  @service intl;
  @service notifications;
  @service currentUser;
  
  validations = {
    name: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    start: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    end: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
  };
  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    let outlet_id = this.router.currentRoute.parent.params.outlet_id;
    let start = changeset.get('start');
    if (Array.isArray(start))
    {
      start = start[0];
    }
    changeset.set('start', start);

    let end = changeset.get('end');
    if (Array.isArray(end))
    {
      end = end[0];
    }
    changeset.set('end', end);

    yield changeset
      .save()
      .then(() =>
      {
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(this.intl.t('closedperiods.edit_success'));
        }
        if (this.currentUser.Is_CurrentActiveOutlet(outlet_id))
        {
          this.currentUser.ChangeOutlet(outlet_id);
        }
        this.router.transitionTo(
          'auth.superbruger.indstillinger.afdelinger.closedperiods',
          outlet_id,
          {
            queryParams: { refreshperiod: Date.now() },
          },
        );
      })
      .catch((err) =>
      {
        const Message = error.get_message(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          }),
        );
      });
  }
  @action
  cancel()
  {
    let outlet_id = this.router.currentRoute.parent.params.outlet_id;

    this.router.transitionTo(
      'auth.superbruger.indstillinger.afdelinger.closedperiods',
      outlet_id,
    );
  }
}
