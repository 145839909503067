import Model, { attr, belongsTo } from '@ember-data/model';

export default class DocumentModel extends Model {
  @attr('string', { defaultValue: '' })
  name;
  @attr('string', { defaultValue: '' })
  content;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @belongsTo('patient', {
    inverse: null,
    async: false,
  })
  patient;

  @attr
  created;

  @attr
  updated;
}
