/* import __COLOCATED_TEMPLATE__ from './periode.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class FilterPeriodeComponent extends Component {
  @service intl;
  @tracked start_maxDate;
  @tracked start_minDate;
  @tracked slut_maxDate;
  @tracked slut_minDate;
  @tracked besked;

  @tracked startdato = null;
  @tracked slutdato = null;

  @action
  inserted() {
    let start_maxdate, slut_maxdate, start_mindate, slut_mindate;

    this.startdato = !isEmpty(this.args.valgtStartdato)
      ? this.args.valgtStartdato
      : null;
    this.slutdato = !isEmpty(this.args.valgtSlutdato)
      ? this.args.valgtSlutdato
      : null;

    let now = new Date();
    let minDate = new Date(2000, 0, 0);

    // max date er idag + 1
    let maxDate = new Date(
      now.getFullYear,
      now.getMonth() + 1,
      now.getDate() + 1,
    );

    let harStartDato = !isEmpty(this.startdato);
    let harSlutDato = !isEmpty(this.slutdato);

    // Begr�ns de mulige datoer i datov�lgerne
    start_mindate = minDate;

    if (harSlutDato) {
      start_maxdate = this.slutdato;
    } else {
      start_maxdate = maxDate;
    }

    slut_maxdate = maxDate;

    if (harStartDato) {
      slut_mindate = this.startdato;
    } else {
      slut_mindate = minDate;
    }

    this.start_minDate = isEmpty(this.start_mindate)
      ? null
      : start_mindate.getTime();
    
    this.start_maxDate = isEmpty(this.start_maxdate)
      ? null
      : start_maxdate.getTime();

    this.slut_minDate = isEmpty(slut_mindate) ? null : slut_mindate.getTime();
    this.slut_maxDate = isEmpty(slut_maxdate) ? null : slut_maxdate.getTime();

    // Beskriv resultatet af den valgte periode
    if (harStartDato && !harSlutDato) {
      this.besked =
        'Alle data fra og med d. ' + this.intl.formatDate(this.startdato) + '.';
    } else if (!harStartDato && harSlutDato) {
      this.besked =
        'Alle data til og med d. ' + this.intl.formatDate(this.slutdato) + '.';
    } else if (!harStartDato && !harSlutDato) {
      this.besked = 'Alle data uanset tidspunkt.';
    } else if (harStartDato && harSlutDato) {
      this.besked =
        'Alle data fra og med d. ' +
        this.intl.formatDate(this.startdato) +
        ' til og med d. ' +
        this.intl.formatDate(this.slutdato) +
        '.';
    } else {
      this.besked = '';
    }
  }

  @action
  onReadyStart(_selectedDates, _dateStr, instance) {
    this.flatpickrRefStart = instance;
  }
  @action
  onReadySlut(_selectedDates, _dateStr, instance) {
    this.flatpickrRefSlut = instance;
  }
  @action
  clearCalendarStart() {
    this.flatpickrRefStart.clear();
  }
  @action
  clearCalendarSlut() {
    this.flatpickrRefSlut.clear();
  }
  @action
  nulstil() {
    this.flatpickrRefStart.clear();
    this.flatpickrRefSlut.clear();
  }
}
