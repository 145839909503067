/* import __COLOCATED_TEMPLATE__ from './day-appointment-list.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AppDayAppointmentListComponent extends Component {
  @service store;
  @service currentUser;

  @tracked appointments;

  @action
  fetch_appointments() {
    const outlet = this.args.outlet
      ? this.args.outlet
      : this.currentUser.CurrentActiveOutletId;

    let Selected_Date = this.args.date;

    if (!this.args.date) {
      Selected_Date = new Date();
    } else {
      Selected_Date = new Date(Selected_Date);
    }

    let Start_isoDate = Selected_Date.toISOString().substring(0, 10);
    Selected_Date.setDate(Selected_Date.getDate() + 1);
    var End_isoDate = Selected_Date.toISOString().substring(0, 10);

    this.appointments = this.store.query('appointment', {
      filter:
        "and(greaterOrEqual(time,'" +
        Start_isoDate +
        "'),lessThan(time,'" +
        End_isoDate +
        "'),equals(outlet.id,'" +
        outlet +
        "')" +
        ')',
      include: 'room,patient,problem,outlet,appointmentstate',
      sort: 'time',
    });
  }
}
