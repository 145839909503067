import Controller from '@ember/controller';
import { tracked, cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthSuperbrugerIndstillingerAftalestatusController extends Controller
{
  queryParams = ['refresh'];
  @service router;

  @tracked searchWord = null;
  @tracked errorMessage = '';
  @tracked refresh;

  @cached
  get create_params()
  {
    let Not_Important = this.refresh; // Trigger refresh

    let params = {
      sort: 'name',
    };
    if (this.searchWord)
    {
      let filterArg = '';

      if (this.searchWord)
      {
        filterArg = filterArg + "contains(name,'" + this.searchWord + "')";
      }
      if (filterArg)
      {
        Object.assign(params, { filter: filterArg });
      }
    }
    return params;
  }
  @action
  navigate_to_new()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.aftalestatus.new');
  }
}
