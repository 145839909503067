import stringutils from '../utils/stringutils';
export default class DateUtils
{
  static IsLeftGreaterWithoutMilliseconds(left, right)
  {
    let _left = new Date(left);
    _left.setMilliseconds(0);
    let _right = new Date(right);
    _right.setMilliseconds(0);

    return _left > _right;
  }
  static getDateWithoutTime(date)
  { 
    if (date instanceof Date && !isNaN(date.valueOf())) {
      return new Date(date.toDateString());
    }
    else
    {
      return date;
    }
  }
  static In_DateInterval(time, start, end)
  {
    let time_date = DateUtils.getDateWithoutTime(time);
    let periodestart_date = DateUtils.getDateWithoutTime(start);
    let periodeend_date = DateUtils.getDateWithoutTime(end);
    return time_date >= periodestart_date && time_date <= periodeend_date;
  }
  static Today_As_String()
  {
    let today = new Date();
    return today.toISOString();
  }
  static AddMinutes(date, minutes)
  {
    if (typeof date == 'string')
    {
      date = new Date(date);
    }
    const DateToMilliseconds = date.getTime();
    const AddedMinutes = DateToMilliseconds + 60000 * minutes;
    const NewDate = new Date(AddedMinutes);
    return NewDate;
  }
  static AddTimezoneOffset(date)
  {
    if (date)
    {
      if (typeof date == 'string')
      {
        date = new Date(date);
      }
      const offset = date.getTimezoneOffset();
      if (offset !== 0)
      {
        return DateUtils.AddMinutes(date, -offset);
      }
    }
    return date;
  }
  static RemoveTimezoneOffset(date)
  {
    if (date)
    {
      if (typeof date == 'string')
      {
        date = new Date(date);
      }
      const offset = date.getTimezoneOffset();
      if (offset !== 0)
      {
        return DateUtils.AddMinutes(date, offset);
      }
    }
    return date;
  }
  static AddOrSubtractSeconds(startingDate, number, add)
  {
    if (typeof startingDate == 'string')
    {
      startingDate = new Date(startingDate);
    }
    let Milliseconds = startingDate.getTime();
    const offset = number * 1000;
    if (add)
    {
      Milliseconds += offset;
    } else
    {
      Milliseconds -= offset;
    }
    return new Date(Milliseconds);
  }
  static AddOrSubtractDays(startingDate, number, add)
  {
    if (typeof startingDate == 'string')
    {
      startingDate = new Date(startingDate);
    }
    let Milliseconds = startingDate.getTime();
    const offset = number * 24 * 60 * 60 * 1000;
    if (add)
    {
      Milliseconds += offset;
    } else
    {
      Milliseconds -= offset;
    }
    return new Date(Milliseconds);
  }
  static Get_TimeString_No_Seconds(date)
  {
    const Time_String =
      date.getHours().toString().padStart(2, '0') +
      ':' +
      date.getMinutes().toString().padStart(2, '0');

    return Time_String;
  }
  static Get_Time_From_String(Time_String)
  {
    let hours = 0, minutes = 0;

    if (Time_String)
    {
      let parts = Time_String.split('.');
      if (parts.length === 1)
      {
        parts = Time_String.split(':');

        if (parts.length === 1)
        {
          // Handle missing separator
          parts = new Array(2).fill('');
          if (Time_String.length === 1)
          {
            parts[0] = Time_String;
          }
          else if (Time_String.length === 2)
          {
            parts[0] = Time_String.substring(0,1);
            parts[1] = Time_String.substring(1) + '0';
          }
          else if (Time_String.length === 3)
          {
            parts[0] = Time_String.substring(0, 1);
            parts[1] = Time_String.substring(1);
          }
          else if (Time_String.length >= 4)
          {
            parts[0] = Time_String.substring(0,2);
            parts[1] = Time_String.substring(2,4);
          }
        }
      }
      hours = stringutils.TryParseInt(parts[0],0);
      minutes = stringutils.TryParseInt(parts[1],0);
    }
    return [hours, minutes];
  }
  static Get_ISO_Date(date)
  { 
    if (!date)
    { 
      date = new Date();
    } 
    return '' +
      date.getFullYear() +
      '-' +
      stringutils.padLeadingZeros(date.getMonth() + 1, 2) +
      '-' +
      stringutils.padLeadingZeros(date.getDate(), 2);
  }
  static Get_Date_String(date)
  { 
    if (!date)
    { 
      date = new Date();
    }
    if (DateUtils.is_date(date))
    { 
      return '' +
        stringutils.padLeadingZeros(date.getDate(), 2) +
        '.' +
        stringutils.padLeadingZeros(date.getMonth() + 1, 2) +
        '.' +
        date.getFullYear();
    }
    else {
      return date;
    }
  }
  static is_date(date) {
    return date instanceof Date && !isNaN(date.valueOf());
  }
  // Handles iso and european date format but not us format
  static Get_Date_From_String(date_string) {
  
    if (!date_string)
    { 
      return null;
    } 
    
    // Sanitize separator
    let input = date_string.replace(/\//g, ".");
    input = date_string.replace(/\\/g, ".");
    input = input.replace(/-/g, ".");

    let parts = input.split(".");
    if (parts.length === 3)
    {
      // Sanitize parts
      parts[0] = stringutils.Remove_Non_Numeric(parts[0]).trim();
      parts[1] = stringutils.Remove_Non_Numeric(parts[1]).trim();
      parts[2] = stringutils.Remove_Non_Numeric(parts[2]).trim();

      if (parts[2].length === 4) // Year
      {
        // Not ISO
        return new Date(parts[2], parts[1] - 1, parts[0]);
      }
      else {
        
        if (parts[0].length === 4) // Year
        {
          // ISO
          return new Date(parts[0], parts[1] - 1, parts[2]); 
        }
        else
        {
          // Unable to recognize year
          return null;
        }
      }
    }
    else {
      // Date is incomplete
      return null;
    }
  }
}
