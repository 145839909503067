/* import __COLOCATED_TEMPLATE__ from './choose-email.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ChooseEmailComponent extends Component {
  val;

  constructor() {
    super(...arguments);
    this.val = this.args.current;
  }

  @action
  change(/*val*/) {
    this.args.onChange(this.val);
  }
}
