import Service from '@ember/service';
import { inject } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class ArticlesService extends Service {
  Articles;
  @inject store;

  constructor() {
    super(...arguments);

    this.Articles = [];
    this.FetchArticles.perform();
  }
  isEmpty(val) {
    return val === null || val === undefined || val === '';
  }
  match(component, category, tags, route_parm, element) {
    let isComponent =
      this.isEmpty(component) || element.component === component;
    let isCategory = this.isEmpty(category) || element.category === category;
    let isTags = this.isEmpty(tags) || element.tags === tags;
    let isRoute = this.isEmpty(route_parm) || element.route === route_parm;

    return isComponent & isCategory & isTags & isRoute;
  }
  @task
  *FindArtikler(component, category, tags, route_parm) {
    while (!this.FetchArticles.isIdle) {
      yield timeout(1000);
    }
    if (this.Articles) {
      return this.Articles.filter((element) => {
        return this.match(component, category, tags, route_parm, element);
      });
    }
  }
  @task
  *FindEn(component, category, tags, route_parm) {
    while (!this.FetchArticles.isIdle) {
      yield timeout(1000);
    }
    if (this.Articles) {
      return this.Articles.find((element) => {
        return this.match(component, category, tags, route_parm, element);
      });
    }
    return null;
  }
  @task({ drop: true })
  @waitFor
  *FetchArticles() {
    let result = yield this.store.findAll('article');
    if (result) {
      let tempResult = [];
      result = result.slice();
      for (let i = 0; i < result.length; i++) {
        let obj = result[i];
        tempResult.push(obj);
      }
      this.Articles = tempResult;
    }
  }
}
