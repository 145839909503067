import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthPatienterAftalerRoute extends Route {
  @service store;
  @service currentUser;
  queryParams = {
    refresh2: {
      refreshModel: true,
    },
  };
  model(params) {
    let patient_id = params.patient_id;

    return hash({
      aftaler: this.store.query('appointment', {
        filter: "equals(patient.id,'" + patient_id + "')",
        include: 'outlet,patient,appointmentstate',
        sort: 'time',
      }),
      patient: this.store.findRecord('patient', patient_id),
      patient_id: patient_id,
    });
  }
}
