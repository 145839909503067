import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import
  {
    validatePresence,
    validateNumber,
  } from 'ember-changeset-validations/validators';
import error from 'boel/utils/error';
import ENV from 'boel/config/environment';
export default class AuthSuperbrugerIndstillingerKabinerEditController extends Controller
{
  @service notifications;
  @service intl;
  @service router;
  @tracked chosenOutlet;

  validations = {
    name: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    seats: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
      validateNumber({
        integer: true,
        message: this.intl.t('validations.notANumber', {
          description: this.intl.t('form_room.felt_seats_label'),
        }),
      }),
      validateNumber({
        positive: true,
        message: this.intl.t('validations.positive', {
          description: this.intl.t('form_room.felt_seats_label'),
        }),
      }),
    ],
  };
  @action
  choose(outlet)
  {
    this.chosenOutlet = outlet;
  }
  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    changeset.set('outlet', this.chosenOutlet);

    yield changeset
      .save()
      .then(() =>
      {
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(this.intl.t('kabiner.edit_success'));
        }
        this.router.transitionTo('auth.superbruger.indstillinger.kabiner', {
          queryParams: { refresh: Date.now() },
        });
      })
      .catch((err) =>
      {
        const Message = error.get_message(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          }),
        );
      });
  }

  @action
  cancel()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.kabiner');
  }
}
