/* import __COLOCATED_TEMPLATE__ from './patient-appointments.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { debug } from '@ember/debug';
import ENV from 'boel/config/environment';

export default class AppPatientAppointmentsComponent extends Component {
  @service store;
  @service printThis;
  @tracked Visningstype;
  @tracked Appointments;
  @tracked Old_Appointments;
  @tracked Future_Appointments;
  @tracked Room_Rentals;
  @tracked future_count;
  @tracked past_count;
  @tracked Is_Loading;

  constructor() {
    super(...arguments);
    this.Visningstype = 0;
  }
  get aftale_list_titel() {
    let title = ENV.FirmaNavn + ' ';
    title += this.args.outlet.postalCode;
    title += ' ' + this.args.outlet.city;
    return title;
  }
  @action
  print_fremtidige_aftaler() {
    this.Visningstype = 0;

    const selector = '.patientensaftaler';
    const options = {
      printDelay: 500,
      loadCSS: "print_label.css"
    };
    this.printThis.print(selector, options);
  }
  @action
  async Fetch_Appointments()
  {
    let old = [];
    let future = [];
    let rentals = [];
    
    const Patient = this.args.patient;
    if (Patient) {
      try {
        this.Is_Loading = true;
        let appointments = await this.store.query('appointment', {
          filter: "equals(patient.id,'" + Patient.id + "')",
          include: 'outlet,problem,patient,appointmentstate',
          sort: 'time'
        });
        this.Appointments = appointments;
        const now = new Date().toISOString();
        if (this.Appointments)
        {
          this.Appointments.forEach(
            (element) =>
            {               
              let date = new Date(element.time);
              
              if (date.getDay() === 0)
              {
                rentals.push(element);
              }
              else if (date.toISOString() < now /* && element.committed != null*/)
              {
                old.push(element);
              }
              else
              { 
                future.push(element);
              }
          });
        }
        this.Is_Loading = false;
      } catch (err) {
        this.Is_Loading = false;
        debug(err);
      }
    } else {
      this.Appointments = [];
    }
    rentals = rentals.sort((a, b) => {
      return b.time.getTime() - a.time.getTime();
    });
    this.Room_Rentals = rentals;
    this.Old_Appointments = old;
    this.Future_Appointments = future;
  }
}
