/* import __COLOCATED_TEMPLATE__ from './other-insurance-info.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked, cached } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import betalinger from 'boel/utils/betalinger';
export default class AppOtherInsuranceInfoComponent extends Component
{
  @service intl;
  @service store;

  @tracked appointment;

  @action
  view_insurance(patient, insurancecase)
  {
    if (this.args.view_insurance)
    {
      this.args.view_insurance(patient, insurancecase);
    }
  }
  @action
  async update()
  {
    let payment = this.args.payment;
    let cases = this.args.insurancecases;
    let appointment = this.args.appointment;
    const patient = this.args.patient;
    const patient_id = patient.id;
    if (this.args.fetch_insurance_cases)
    {
      cases = await this.store.query('insurancecase', {
        filter: "equals(patient.id,'" + patient_id + "')",
        include: 'patient,company,problem',
        sort: '-start',
      });
    }

    if (payment == null)
    {
      const payment_list = await this.store.query('payment', {
        filter: "equals(appointment.id,'" + appointment.id + "')",
        include: 'patient,appointment',
      });
      if (payment_list && Array.isArray(payment_list) && payment_list.length === 1)
      {
        payment = payment_list[0];
      }
    }
    let payment_list = await this.store.query('payment', {
      filter: "equals(patient.id,'" + patient_id + "')",
      include: 'patient,appointment,insurancecase',
    });

    if (cases && payment_list)
    {
      let r = betalinger.get_is_insured(payment, cases, payment_list, false);
      appointment.has_insurancecase = r.has_insurancecase;
      appointment.insurancecase = r.insurancecase;
      if (!r.has_insurancecase)
      {
        appointment.insurancecase = null;
      }
    }
    this.appointment = appointment;
  }
  @cached
  get labelname()
  {
    let label = this.intl.t("insurance_companies.default_reference_name");
    if (this.appointment.insurancecase &&
      this.appointment.insurancecase.company &&
      this.appointment.insurancecase.company.referencenavn)
    {
      label = this.appointment.insurancecase.company.referencenavn;
    }
    return label;
  }
}
