export default class error {
  static get_message(err) {
    let Message = err;
    if (typeof err === 'object') {
      if (err.errors) {
        if (Array.isArray(err.errors)) {
          Message = '(' + err.errors[0].status + ') ' + err.errors[0].title;
        } else {
          Message = err.errors;
        }
      }
    }
    return Message;
  }
}
