/* import __COLOCATED_TEMPLATE__ from './inline-confirmation-form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { debug } from '@ember/debug';
import { htmlSafe } from '@ember/template';
export default class AppInlineConfirmationFormComponent extends Component {
  @service notifications;
  @service session;
  @service intl;
  @service router;
  @service store;
  @service confirmation;

  @tracked confirming = false;
  @tracked sms;
  @tracked email;
  @tracked send_confirmation_email = false;
  @tracked send_confirmation_sms = false;
  @tracked confirmation_email_content;
  @tracked confirmation_sms_content;
  @tracked content_shown = 0;
  @tracked loading_content_sms = true;
  @tracked loading_content_email = true;

  appointment_id;
  betaling;
  appointment_email_token = 'AFTALE_KVITTERING_EMAIL';
  appointment_sms_token = 'AFTALE_KVITTERING_SMS';
  payment_email_token = 'BETALINGS_KVITTERING_EMAIL';
  payment_sms_token = 'BETALINGS_KVITTERING_SMS';

  get Is_Loading_Content() {
    return this.loading_content_sms || this.loading_content_email;
  }
  get valid_email() {
    const mail_format =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const valid_email = this.email && this.email.match(mail_format);

    return valid_email;
  }
  get valid_sms() {
    //const cellphone_format = /^([+]\d{2}[+])?\d{8}$/;
  //  const valid_sms = this.sms && this.sms.match(cellphone_format);

    return this.sms;
  }
  get Can_Be_Confirmed() {
    let result = this.send_confirmation_email && this.valid_email;
    result = result || (this.send_confirmation_sms && this.valid_sms);

    return result;
  }
  async Read_Messages(email_token, sms_token, appointment, payment) {
    this.loading_content_email = true;
    try {
      this.confirmation
        .Read_Messages(appointment, email_token, payment)
        .then((content) => {
          this.confirmation_email_content = htmlSafe(content);
          this.loading_content_email = false;
        });
    } catch (err) {
      debug(err);
      this.loading_content_email = false;
    }
    this.loading_content_sms = true;
    try {
      this.confirmation
        .Read_Messages(appointment, sms_token, payment)
        .then((content) => {
          const result2 = content.replaceAll('\n', '<br>');
          this.confirmation_sms_content = htmlSafe(result2);
          this.loading_content_sms = false;
        });
    } catch (err) {
      debug(err);
      this.loading_content_sms = false;
    }
  }
  constructor() {
    super(...arguments);
    if (this.args.appointment && this.args.appointment.patient) {
      this.email = this.args.appointment.patient.email;
      this.sms = this.args.appointment.patient.cellphone;
      this.appointment_id = this.args.appointment.id;
      this.betaling = false;
      this.Read_Messages(this.appointment_email_token, this.appointment_sms_token, this.args.appointment, null);
    }
    if (this.args.payment && this.args.payment.patient) {
      this.betaling = true;
      this.email = this.args.payment.patient.email;
      this.sms = this.args.payment.patient.cellphone;
      this.appointment_id = this.args.payment.appointment.id;

      this.Read_Messages(this.payment_email_token, this.payment_sms_token,this.args.payment.appointment,this.args.payment);
    } 
  }
  @action
  async confirm() {
    let that = this;
    if (this.Can_Be_Confirmed) {
      const email = this.send_confirmation_email ? this.email : '';
      const sms = this.send_confirmation_sms ? this.sms : '';

      this.confirming = true;
      try {
        this.confirmation.MakeConfirm(this.appointment_id, email, sms, this.betaling).then(() => {
          that.send_confirmation_email = false;
          that.send_confirmation_sms = false;
          that.confirming = false;
          that.args.tilbage();
        });
      } catch (err) {
        this.confirming = false;
      }
    }
  }
}
