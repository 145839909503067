import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthAftalerStamdataRoute extends Route {
  @service store;
  @service currentUser;
  @service intl;

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);
    controller.set('chosenOutlet', model.patient.outlet);
  }
  async model(params) {
    const the_patient = await this.store.findRecord(
      'patient',
      params.patient_id,
      {
        include: 'outlet',
      },
    );
    let journal_med_problem_array = await this.store.query('journal', {
      filter:
        "and(equals(patient.id,'" +
        params.patient_id +
        "')" +
        ",not(or(equals(problemtext,null),equals(problemtext,''))))",
      sort: '-created',
    });
    let problem_text = this.intl.t(
      'listitem_patient.problem_udefineret_besked',
    );
    if (journal_med_problem_array) {
      journal_med_problem_array = journal_med_problem_array.slice();
      if (journal_med_problem_array && journal_med_problem_array.length > 0) {
        problem_text = journal_med_problem_array[0].problemtext;
      }
    }

    return hash({
      patient: the_patient,
      problemtext: problem_text,
    });
  }
}
