import Route from '@ember/routing/route';
import { debug } from '@ember/debug';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { UnauthorizedError, ForbiddenError } from '@ember-data/adapter/error';
export default class ApplicationRoute extends Route {
  @service intl;
  @service currentUser;
  @service session;
  @service router;
  @service previousRoute;
  
  async beforeModel() {
    await this.session.setup();
    this.intl.setLocale(['da-dk']);
    this.intl.setOnFormatjsError((error) => {
      switch (error.code) {
        case 'FORMAT_ERROR':
        case 'MISSING_TRANSLATION': {
          // Do nothing
          break;
        }

        default: {
          throw error;
        }
      }
    });
    const previousRoute = this.previousRoute.getPreviousRoute();
    const previousRouteInfo = this.previousRoute.getPreviousRouteInfo();

    return this._loadCurrentUser();
    
  }
  async _loadCurrentUser() {
    try {
      await this.currentUser.load();
    } catch (err) {
      debug('Load current user error: ' + err);
      await this.session.invalidate();
    }
  }
  isObject(objValue) {
    return (
      objValue &&
      typeof objValue === 'object' &&
      objValue.constructor === Object
    );
  }
  @action
  Log_Error(error, transition) {
    let now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset()); // Compensate for timezones

    let besked = '',
      status = '';
    if (this.isObject(error)) {
      besked = error.message || '';
      status = error.status || '';
    } else {
      besked = error || '';
    }
    let params = JSON.stringify(transition.to.params);
    let url = transition.to.name;

    // Try logging error
    try {
      let log = this.store.createRecord('log', {
        overskrift: 'ERROR',
        statusTitel: status,
        kilde: 'route',
        statustype: 0,
        besked: besked,
        tidspunkt: now,
        standerid: 1,
        input: url,
        templatevalues: params,
      });
      log.save();
    } catch (err) {
      // Logging to server is not possible
      debug('error:', err);
    }
  }
  @action
  error(error, transition) {
    try {
      if (error instanceof UnauthorizedError) {
        debug('session invalidate', error);
        this.session.invalidate();
      } else if (error instanceof ForbiddenError) {
        debug('session invalidate', error);
        this.session.invalidate();
      } else if (error.status === '403' || error.status === '401') {
        debug('session invalidate', error);
        this.session.invalidate();
      } else if (error.name === 'TypeError') {
        debug('session invalidate', error);
        this.session.invalidate();
      } else {
        debug(error);
        this.Log_Error(error, transition);
      }
    } catch (error) {
      debug('Application error', error);
      this.session.invalidate();
    }
  }
}
