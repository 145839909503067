import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
export default class JournalModel extends Model {
  @service intl;

  @attr('string', { defaultValue: '' })
  entry;

  @attr('string', { defaultValue: '' })
  result;

  created;

  updated;

  @attr('date')
  time;

  @belongsTo('patient', {
    inverse: null,
    async: false,
  })
  patient;

  @belongsTo('problem', {
    inverse: null,
    async: false,
  })
  problem;

  @attr('string', { defaultValue: '' })
  problemtext;

  @belongsTo('journaltype', {
    inverse: null,
    async: false,
  })
  journaltype;

  @belongsTo('therapist', {
    inverse: null,
    async: false,
  })
  therapist;
}
