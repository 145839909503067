import { service } from '@ember/service';
import Controller from '@ember/controller';
import ENV from 'boel/config/environment';
import { debug } from '@ember/debug';
export default class ErrorController extends Controller
{
  @service store;
  logentry = null;

  get isDeveloping()
  {
    return ENV.environment === 'development';
  }
  get logError()
  {
    let options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };
    let status = this.model.status || '';
    let now = new Date();
    let tidspunkt = new Intl.DateTimeFormat('da-DK', options).format(now);
    let log = this.store.createRecord('log', {
      overskrift: this.model.message,
      kilde: 'JS',
      status: status,
      besked: this.model.stack,
      tidspunkt: tidspunkt,
      input: 'JS',
      statusType: 0,
      standerid: -1,
      templatevalues: '',
    });

    let isTest = ENV.environment === 'test';

    if (isTest)
    {
      debug(tidspunkt + ': ' + status);
      debug(this.model.message);
      debug(this.model.stack);

      return log;
    } else
    {
      return log
        .save()
        .then((logentry) =>
        {
          this.logentry = logentry;
          return true;
        })
        .catch(function (/*error*/)
        {
          return false;
        });
    }
  }
}
