/* import __COLOCATED_TEMPLATE__ from './dag.hbs'; */
import Component from '@glimmer/component';
import calender from '../../utils/calender';
import { service } from '@ember/service';
import { action } from '@ember/object';
import stringutils from '../../utils/stringutils';
import DateUtils from 'boel/utils/DateUtils';

export default class FilterDagComponent extends Component {
  
  @service router;
  @service currentUser;
  disabled_dates;

  day;

  get ChosenDay() {
    return this.args.chosenDay;
  }

  @action
  change_date(dato) {
    let tidspunkt;
    if (dato) {
      if (Array.isArray(dato)) {
        tidspunkt = dato[0];
      } else {
        tidspunkt = new Date(dato);
      }
    } else {
      tidspunkt = new Date();
    }
    this.args.onChange(tidspunkt);
  }

  @action
  async fetch_disable_dates()
  {
    this.disabled_dates = [];
    let now = new Date();
    let theCalendar = new calender();

    const outlet = this.currentUser.CurrentActiveOutlet;

    let danishHolidays = null;
    if (outlet) {
        danishHolidays = outlet.danishHolidays;
      
      if (danishHolidays) {
        this.disabled_dates = theCalendar.Helligdage(now.getFullYear());
        this.disabled_dates = this.disabled_dates.concat(
          theCalendar.Helligdage(now.getFullYear() + 1),
        );
      } else {
        this.disabled_dates = [];
      }
      
      const Closed_Periods = this.currentUser.CurrentClosedPeriods;

      if (Closed_Periods) {
        Closed_Periods.forEach((element) =>
        {
          let current = element.start;
          let end = element.end;
          if (typeof current == 'string') {
            current = new Date(current);
          }
          if (typeof end == 'string')
          {
            end = new Date(end);
          }
    
          while (current <= end) {
            this.disabled_dates.push(current);
            let dagen_efter_ms = current.getTime() + 86400000;
            current = new Date(dagen_efter_ms);
          }
        });

      }
    }
    else {
      this.router.transitionTo("auth.logout");
    }
  }
  @action
  onDayCreate(dObj, dStr, fp, dayElem) {
    let theDateOriginal = dayElem.dateObj;
    if (!this.args.disableDays ||!theDateOriginal) {
      return false;
    }
    let theDate = DateUtils.Get_ISO_Date(theDateOriginal);
    const Closed_Periods = this.currentUser.CurrentClosedPeriods;

    if (Closed_Periods) {
      for (let i = 0; i < Closed_Periods.length; i++) {
        let element = Closed_Periods[i];
        let start = element.start;
        let end = element.end;
        if (typeof start == 'string') {
          start = new Date(start);
        }
        let startDate = DateUtils.Get_ISO_Date(start)
 
        if (typeof end == 'string')
        {
          end = new Date(end);
        }
        let endDate = DateUtils.Get_ISO_Date(end);

        if (theDate >= startDate && theDate <= endDate) {
          dayElem.className += " lukkeperiode";
          var toolTip = document.createElement('div');
          toolTip.innerHTML = element.name;
          dayElem.appendChild(toolTip);
          break;
        }
      }      
    }
  }
  @action
  disable(date) {
    if (!this.args.disableDays) {
      return false;
    }
    let theDate = stringutils.StripTimezone(date.toDateString());

    let disabled = false;

    for (var i = 0; i < this.disabled_dates.length; i++) {
      let closedDate = stringutils.StripTimezone(
        this.disabled_dates[i].toDateString(),
      );

      if (theDate == closedDate) {
        disabled = true;
        break;
      }
    }
    if (disabled) {
      return true;
    }
    const outlet = this.currentUser.CurrentActiveOutlet;
    if (outlet)
    {
      const closedSaturday = outlet.closedSaturday;
      if (closedSaturday && date.getDay() === 6) {
        return true;
      }
      const closedSunday = outlet.closedSunday;
      if (closedSunday && date.getDay() === 0) {
        return true;
      }
      return false;
    }
    else {
      this.router.transitionTo("auth.logout");
    }
  }
}
