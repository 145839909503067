import Service from '@ember/service';

export default class ExcelexportService extends Service {
  download_csv(csv, filename /*, titel*/) {
    var csvFile;
    var downloadLink;

    // CSV FILE
    csvFile = new Blob(['\ufeff', csv], {
      type: 'text/csv;charset=ISO-8859-1;',
    });

    // Download link
    downloadLink = document.createElement('a');

    // File name
    downloadLink.download = filename;

    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Make sure that the link is not displayed
    downloadLink.style.display = 'none';

    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    downloadLink.click();
  }
  export_table_to_csv(klasseNavn, filename, titel) {
    var csv = [];
    let klasser = klasseNavn.split(',');
    klasser.forEach((element) => {
      var rows = document.querySelectorAll('.' + element + ' tr');

      for (var i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll('td, th');

        for (var j = 0; j < cols.length; j++) {
          row.push(cols[j].innerText);
        }

        csv.push(row.join(';'));
      }
    });
    // Download CSV
    this.download_csv(csv.join('\n'), filename, titel);
  }
}
