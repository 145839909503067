/* import __COLOCATED_TEMPLATE__ from './form-kabinestyring-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked, cached } from '@glimmer/tracking';
import DateUtils from 'boel/utils/DateUtils';
export default class AppFormKabinestyringListComponent extends Component {

  @service kabinestyringhub;
  @service currentUser;
  @service notifications;
  @service router;
  @service intl;

  counter;
  timeout;

  @tracked Visningstype = 1;
  constructor() {
    super(...arguments);
    this.Visningstype = 1;
    this.counter = 0;
    var state = this.kabinestyringhub.Current_State; // Make sure hub is active
  }
  @cached
  get Committed() {
    return this.args.aftaler.filter((appointment) => {
      return appointment.committed && appointment && !appointment.outside;
    }).length;
  }
  @cached
  get NotCommitted() {
    return this.args.aftaler.filter((appointment) => {
      return !appointment.committed;
    }).length;
  }
  @cached
  get Outside() {
    return this.args.aftaler.filter((appointment) => {
      return appointment.outside;
    }).length;
  }
  @cached
  get Inside() {
    return this.args.aftaler.filter((appointment) => {
      return !appointment.outside;
    }).length;
  }
  @action
  update() {
  //  this.aftaler = this.args.aftaler;
    this.searchword = this.args.searchword;
  }
  @action
  Check_Progress(appointment) {
    var result = '';

    if (appointment.room && appointment.committed && !appointment.finished) {
      var now = new Date();
      var Previous = DateUtils.AddMinutes(now, -10);
      if (Previous >= new Date(appointment.committed)) {
        result = 'bg-danger';
      }
    }
    return result;
  }
  @action
  UpdateOutside(appointment) {
    appointment.outside = !appointment.outside;
    appointment.save();
    this.Visningstype = this.Visningstype;
  }
  @action
  UpdateAction(appointment, selected) {
    appointment.action = selected;
    if (selected) {
      if (selected.unsetfinished) {
        appointment.finished = null;
      }
      if (selected.setdonetime) {
        appointment.done = new Date();
      }
      if (selected.timeout > 0) {
        const Now = DateUtils.AddMinutes(new Date(), selected.timeout / 60);
        appointment.done = Now;

        let Room = '';
        if (appointment.room) {
          Room = appointment.room.nameShort;
        }
        const Message = Room + ': ' + selected.message;
        this.kabinestyringhub.invoke_action(
          selected.timeout,
          Message,
          this.currentUser.user.outletid,
        );
      }
    } else {
      appointment.done = null;
    }
    appointment.save();
  }
  @action
  UpdateRoom(appointment, selected) {
    appointment.room = selected;
    appointment.save();
  }
  @action
  UpdateTime(appointment, field_name, selected) {
    let NewTime;
    if (Array.isArray(selected)) {
      NewTime = selected.firstObject;
    } else {
      NewTime = selected.value;
      if (!NewTime) {
        NewTime = selected.target;
        if (NewTime) {
          NewTime = NewTime.value;
        }
      }
    }

    if (NewTime) {
      if (!appointment[field_name]) {
        appointment[field_name] = new Date();
      }
      if (typeof appointment[field_name] === 'string') {
        appointment[field_name] = new Date(appointment[field_name]);
      }
      const Time = DateUtils.Get_Time_From_String(NewTime);
      appointment[field_name].setHours(Time[0]);
      appointment[field_name].setMinutes(Time[1]);
      appointment.save();
    } else {
      appointment[field_name] = null;
      appointment.save();
    }
  }
  @action
  GetTime(appointment, field_name) {
    var time_options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    let current = appointment[field_name];
    let time = '';
    if (current) {
      try {
        time = this.intl.formatTime(current, time_options);
      } catch {}
    }
    return time;
  }
  @action
  SetNowPlus30Time(appointment, field_name) {
    let Now = new Date();
    Now = DateUtils.AddMinutes(Now, 30);
    appointment[field_name] = Now;
    appointment.save();
  }
  @action
  SetNowTime(appointment, field_name) {
    let Now = new Date();
    appointment[field_name] = Now;
    appointment.save();
  }
  @action
  UpTime(appointment, field_name) {
    let current = appointment[field_name];
    if (!current) {
      current = new Date();
    }

    current = DateUtils.AddOrSubtractSeconds(current, 30 * 60, true);
    appointment[field_name] = current;
    appointment.save();
  }
  @action
  Has_Insurance(appointment) {
    var result = false;

    this.args.insurancecases.forEach((aCase) => {
      if (
        appointment.time >= aCase.start &&
        appointment.time <= aCase.end &&
        appointment.patient.id === aCase.patient.id
      ) {
        result = true;
      }
    });

    return result;
  }
}
