import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import
  {
    validatePresence,
  } from 'ember-changeset-validations/validators';
import error from 'boel/utils/error';
import ENV from 'boel/config/environment';

export default class AuthAftalerNewpatientController extends Controller
{
  @service notifications;
  @service intl;
  @service router;
  @tracked chosenOutlet;

  validations = {
    lastname: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    /* cpr: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
      validateLength({
        min: 8,
        max: 10,
        allowBlank: false,
        message: this.intl.t('validations.wrongLength', {
          description: 'CPR nummer eller telefonnummer',
          is: 10,
        }),
      }),
    ],*/
    /* email: [
      validateFormat({
        type: 'email',
        allowBlank: true,
        message: this.intl.t('validations.email', {
          description: 'Emailen',
        }),
      }),
    ],*/
    /*   postalcode: [
      validateNumber({
        integer: true,
        message: this.intl.t('validations.notANumber', {
          description: this.intl.t('form_patient.felt_postalCode_label'),
        }),
      }),
    ],*/
    /*
    cellphone: [
      validateNumber({
        allowBlank: true,
        message: this.intl.t('validations.present'),
      }),
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
      validateLength({
        min: 8,
        max: 8,
        allowBlank: true,
        message: this.intl.t('validations.wrongLength', {
          description: 'Telefonnummeret',
          is: 8,
        }),
      }),
    ],*/
  };

  @action
  choose(outlet)
  {
    this.chosenOutlet = outlet;
  }
  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    changeset.set('outlet', this.chosenOutlet);

    yield changeset
      .save()
      .then((patient) =>
      {
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(this.intl.t('patienter.create_success'));
        }
        this.router.transitionTo('auth.aftaler.newappointment', patient.id, {
          queryParams: { refresh: Date.now() },
        });
      })
      .catch((err) =>
      {
        const Message = error.get_message(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          }),
        );
      });
  }

  @action
  cancel()
  {
    this.router.transitionTo('auth.aftaler');
  }
}
