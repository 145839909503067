import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
export default class AuthKabinstyringJournalController extends Controller
{
  @service router;

  @tracked Show_List_View = '0';

  @action
  cancel()
  {
    this.router.transitionTo('auth.kabinestyring');
  }
}
