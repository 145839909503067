import Model, { attr } from '@ember-data/model';

export default class ActionModel extends Model {
  @attr('string', { defaultValue: '' })
  name;

  @attr('number', { defaultValue: 100 })
  order;

  @attr('number', { defaultValue: 0 })
  offset;

  @attr('number', { defaultValue: 0 })
  timeout;

  @attr('string', { defaultValue: '' })
  message;

  @attr('boolean', { defaultValue: false })
  unsetfinished;

  @attr('boolean', { defaultValue: false })
  setdonetime;

  @attr
  created;

  @attr
  updated;
}
