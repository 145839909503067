import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerBrugereEditRoute extends Route {
  @service store;

  async model(params) {
    const account = await this.store.findRecord('account', params.account_id);

    let outlet;
    const outlet_id = account.outletid;
    if (outlet_id != -1) {
      outlet = await this.store.findRecord('outlet', outlet_id);
    }
    account.outlet = outlet;

    const associations = await this.store.query('association', {
      filter: "equals(bruger,'" + account.stringid + "')",
      include: 'outlet',
      sort: 'outlet.name',
    });
    return hash({
      account_id: params.account_id,
      editRecord: account,
      associations: associations,
    });
  }
}
