/* import __COLOCATED_TEMPLATE__ from './form-payment-saldo.hbs'; */
import Component from '@glimmer/component';

export default class AppFormPaymentSaldoComponent extends Component {
  get total() {
    return this.args.payments.reduce(function (total, element) {
      return total + element.amount;
    }, 0);
  }
  get paid() {
    return this.args.payments.reduce(function (total, element) {
      return total + (element.paid ? element.amount : 0);
    }, 0);
  }
  get unpaid() {
    return this.args.payments.reduce(function (total, element) {
      return total + (element.paid ? 0 : element.amount);
    }, 0);
  }
}
