import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import ENV from 'boel/config/environment';
export default class AuthSuperbrugerIndstillingerLinkDeleteController extends Controller
{
  @service notifications;
  @service router;
  @service intl;

  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *deleteTask()
  {
    yield this.model.destroyRecord().then(() =>
    {
      if (ENV.APP.ShowNotifications) {
        this.notifications.success(this.intl.t('link.delete'));
      }
      this.router.transitionTo('auth.superbruger.indstillinger.link', {
        queryParams: { refresh: Date.now() },
      });
    });
  }

  @action
  cancel()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.link');
  }
}
