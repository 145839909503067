import Route from '@ember/routing/route';

export default class AuthSkiftpasswordRoute extends Route {
  async model() {
    return {
      OldPassword: '',
      NewPassword: '',
      ConfirmPassword: '',
    };
  }
}
