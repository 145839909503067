import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';
export default class AuthKabinestyringAftalerEditRoute extends Route {
  @service store;
  @service currentUser;

  async model(params) {
    let user_outlet = await this.currentUser.CurrentActiveOutletId;

    const states = await this.store.findAll('appointmentstate');
    const treatments = await this.store.findAll('treatment');
    const problems = await this.store.findAll('problem');

    return hash({
      problems: problems,
      states: states,
      treatments: treatments,

      appointment: this.store.findRecord('appointment', params.appointment_id, {
        include:
          'outlet,patient,treatment,problem,action,appointmentstate,room',
      }),

      closedperiods: this.store.query('closedperiod', {
        filter: "equals(outlet.id,'" + user_outlet.id + "')",
      }),

      openinghours: this.store.query('openinghour', {
        filter: "equals(outlet.id,'" + user_outlet.id + "')",
      }),
    });
  }
}
