import Controller from '@ember/controller';
import { tracked, cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthSuperbrugerIndstillingerTherapistsController extends Controller
{
  queryParams = ['searchword', 'refresh'];
  @service router;

  @tracked searchWord;
  @tracked errorMessage = '';
  @tracked chosenOutlet = null;
  @tracked outletId = -1;
  @tracked refresh;

  @cached
  get create_params()
  {
    let Not_Important = this.refresh; // Trigger refresh

    let params = {
      include: 'outlet',
      sort: 'name',
    };
    if (this.outletId != -1 || this.searchWord)
    {
      let filterArg = '';
      let Filter_Count = 0;

      if (this.outletId && this.outletId != -1)
      {
        filterArg = filterArg + "equals(outlet.id,'" + this.outletId + "')";
        Filter_Count = Filter_Count + 1;
      }

      if (this.searchWord)
      {
        if (Filter_Count > 0)
        {
          filterArg = filterArg + ',';
        }
        filterArg = filterArg + "contains(name,'" + this.searchWord + "')";
        Filter_Count = Filter_Count + 1;
      }
      if (Filter_Count > 1)
      {
        filterArg = 'and(' + filterArg + ')';
      }
      if (filterArg)
      {
        Object.assign(params, { filter: filterArg });
      }
    }
    return params;
  }
  @action
  navigate_to_new()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.therapists.new');
  }
  @action
  chooseOutlet(outlet)
  {
    this.chosenOutlet = outlet;

    let id = -1;

    if (outlet)
    {
      id = outlet.id;
    }
    this.outletId = id;
  }
}
