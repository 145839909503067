import Controller from '@ember/controller';
import { debug } from '@ember/debug';
import { action } from '@ember/object';
import { service } from '@ember/service';
import ENV from 'boel/config/environment';
import fetch from 'fetch';
import {
  isServerErrorResponse,
  isUnauthorizedResponse,
} from 'ember-fetch/errors';

export default class AuthPatienterAftalerController extends Controller {
  @service router;
  @service store;
  @service currentUser;
  @service session;
  @service notifications;
  @service intl;


  @action
  async email() {
    const patient_id = this.model.patient_id;

    var kvittering_link = ENV.APP.namespaceRoot;
    if (ENV.APP.namespace) {
      kvittering_link += '/' + ENV.APP.namespace;
    }
    kvittering_link += '/appointment_list';

    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };
    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    return fetch(kvittering_link + '?patient_id=' + patient_id, {
      dataType: 'text',
      method: 'GET',
      headers: headers,
      cache: 'no-cache',
    })
      .then((response) => {
        if (response.ok) {
          if (ENV.APP.ShowNotifications) {
            this.notifications.success(
              this.intl.t('form_appointment.email_send_success')
            );
          }
          this.router.transitionTo(this.router.currentRoute.parent.name);
        } else if (isUnauthorizedResponse(response)) {
           this.notifications.info(this.intl.t('login.session_timeout'));
            this.session.invalidate();
        } else if (isServerErrorResponse(response)) {
          this.notifications.error(this.intl.t('login.server_error'));
          
        }else {
          this.notifications.error(
            this.intl.t('form_appointment.email_send_fejl')
          );
        }
      })
      .catch(function (arg1, arg2) {
        this.notifications.error(
          this.intl.t('form_appointment.email_send_fejl')
        );
        debug('arg1: ' + arg1);
        debug('arg2: ' + arg2);
      });
  }
  @action
  print() {
    window.print();
  }
  @action
  cancel() {
    this.router.transitionTo('auth.patienter');
  }
}
