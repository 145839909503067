import RESTAdapter from '@ember-data/adapter/rest';
import { service } from '@ember/service';
import ENV from 'boel/config/environment';
import { isPresent } from '@ember/utils';

export default class AccountAdapter extends RESTAdapter {
  @service session;

  host = ENV.APP.host;
  namespace = ENV.APP.namespace;

  get headers() {
    let headers = {};
    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    return headers;
  }

  handleResponse(status, headers, payload /*, requestData */) {
    if (status === 401 || status === 403) {
      this.session.invalidate();
    }
    return payload;
  }
  authorize(xhr) {
    let { access_token } = this.session.data.authenticated;
    if (isPresent(access_token)) {
      xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
    }
  }
}
