/* import __COLOCATED_TEMPLATE__ from './card-diagram.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import ENV from 'boel/config/environment';
import { task } from 'ember-concurrency';
import { tracked, cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import fetch from 'fetch';
import {
  isServerErrorResponse,
  isUnauthorizedResponse,
} from 'ember-fetch/errors';

import error from 'boel/utils/error';

export default class AppCardDiagramComponent extends Component {
  @service session;
  @service notifications;
  @service intl;
  @service excelexport;

  @tracked Show_List_View = '2';
  @tracked isPdf = false;
  @tracked valgtStartdato = null;
  @tracked valgtSlutdato = null;

  @cached
  get options() {
    return JSON.parse(this.args.options);
  }
  @task
  *fetchData() {
    let rapportIDStr = this.args.rapport;
    let rapportID = parseInt(rapportIDStr, 10);

    let outlet_str = this.args.outlet;
    let outlet_id = parseInt(outlet_str, 10);
    let patient_str = this.args.patient;
    let patient_id = parseInt(patient_str, 10);

    let status_str = this.args.status;
    let status_id = parseInt(status_str, 10);
    
    let insurance_str = this.args.insurance;
    let insurance_id = parseInt(insurance_str, 10);
    
    let valgtStartdato = null;
    if (Date.parse(this.args.valgtStartdato)) {
      valgtStartdato = this.args.valgtStartdato;
    }
    let valgtSlutdato = null;
    if (Date.parse(this.args.valgtSlutdato)) {
      valgtSlutdato = this.args.valgtSlutdato;
    }
    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };
    let link =
      ENV.APP.namespaceRoot + '/' + ENV.APP.namespace + '/data/chartdata';

    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    return fetch(link, {
      dataType: 'text',
      method: 'POST',
      headers: headers,
      cache: 'no-cache',
      body: JSON.stringify({
        outlet: outlet_id,
        patient: patient_id,
        status: status_id,
        insurance: insurance_id,
        rapport: rapportID,
        version: ENV.APP.version,
        startdato: valgtStartdato,
        slutdato: valgtSlutdato,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((resolved) => {
            return resolved;
          });
        } else if (isUnauthorizedResponse(response)) {
          this.notifications.info(this.intl.t('login.session_timeout'));
          this.session.invalidate();
        } else if (isServerErrorResponse(response)) {
          this.notifications.error(this.intl.t('login.server_error'));
        }
      })
      .catch((err) => {
        const Message = error.get_message(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          }),
        );
      });
  }
  @cached
  get linedata() {
    return this.fetchData.perform();
  }

  @action
  exceleksport(klasseNavn, filnavn, titel) {
    return new Promise((resolve, reject) => {
      try {
        this.excelexport.export_table_to_csv(klasseNavn, filnavn, titel);
        resolve(true);
      } catch (err) {
        reject(new Error('Fejl: ' + err));
      }
    });
  }
  @action
  export_canvas_to_png(downloadid, canvasid) {
    var download = document.getElementById(downloadid);
    var image = document
      .getElementsByClassName(canvasid)[0]
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    download.setAttribute('href', image);
    //download.setAttribute("download","archive.png");
  }
}
