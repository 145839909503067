import Controller from '@ember/controller';
import { inject as service } from '@ember/service'
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { debug } from '@ember/debug';
import { action } from '@ember/object';

export default class AuthUdlejningKeyController extends Controller
{
  @service router;
  
  @action
  cancelConfirm()
  { 
    this.router.transitionTo('auth.udlejning');
  }
  
}
