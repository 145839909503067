/* import __COLOCATED_TEMPLATE__ from './patient-appointment-list.hbs'; */
import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import betalinger from 'boel/utils/betalinger';
import { service } from '@ember/service';
import DateUtils from 'boel/utils/DateUtils';
export default class AppPatientAppointmentListComponent extends Component {
  @service store;

  @tracked appointments;
  @tracked insurance_cases;
  @tracked loading = false;

  @action
  scroll_into_view() {
    const element= document.querySelector('#patientens_aftaler_container .active');
    if (element) {
      element.scrollIntoView(false);
    }
  }
  @action
  async update() {
    this.loading = true;
    let appointment_id_strings = [];
    let results = this.args.appointments;
    if (results) {
      let appointment_ider = '';
      let counter = 0;
      results.forEach((element) => {
        element.roomnr = element.time.getHours();
        element.background_class = betalinger.get_background_color(element);

        if (counter < 50) {
          if (appointment_ider != '') {
            appointment_ider += ',';
          }
        }
        else {
          appointment_id_strings.push(appointment_ider);
          appointment_ider = "";
          counter = 0;
        }
        appointment_ider += "'" + element.id + "'";
        counter = counter + 1;
      });
      if (counter > 0)
      {
        appointment_id_strings.push(appointment_ider);
      }
      let cases = await this.store.query('insurancecase', {
        filter: "equals(patient.id,'" + this.args.patient.id + "')",
        include: 'patient,company,problem',
        sort: 'start',
      });
      cases = cases.slice();
      cases = cases.sort((a, b) => {
        return a.start > b.start;
      });
      let payments = [];
      for (let i = 0; i < appointment_id_strings.length; i++)
      {
        let results = await this.store.query('payment', {
          filter: 'any(appointment.id,' + appointment_id_strings[i] + ')',
          include: 'appointment,insurancecase,patient,outlet',
        });
        payments = [...payments, ...(results.flat())];
      }

      for (let i2 = 0; i2 < results.length; i2++) {
        let aid =  results[i2].id;
        for (let i3 = 0; i3 < payments.length; i3++) {
          let p_aid = payments[i3].appointment.id;
          if (p_aid == aid) {
            results[i2].payment = payments[i3];
            results[i2].insurancecase = results[i2].payment.insurancecase;
            break;
          }
        }
        results[i2].has_insurancecase = false;
        if (results[i2].payment) {
          results[i2].payment.has_insurancecase = false;
        }
      }
      payments = payments.slice();

      if (cases && results) {
        let antal = 0;
        let forsikring;

        for (let i = 0; i < cases.length; i++) {
          forsikring = cases[i];
          antal = 0;
          for (let i2 = 0; i2 < results.length; i2++) {
            if (results[i2].payment && results[i2].payment.insurancecase) {
              if (results[i2].payment && results[i2].payment.insurancecase.id == forsikring.id) {
                results[i2].payment.has_insurancecase = true;
                results[i2].has_insurancecase = true;
                results[i2].payment.insurancecase = forsikring;
                antal++;
              }
            }
          }
          for (let i2 = 0; i2 < results.length; i2++) {
            if (antal >= forsikring.amount) {
              break;
            }
            // Gratis betalinger tæller ikke som forsikret
            if (results[i2].payment.amount != 0) {
              if (results[i2].payment && results[i2].payment.insurancecase == null) {
                let inside_interval = DateUtils.In_DateInterval(
                  results[i2].time,
                  forsikring.start,
                  forsikring.end,
                );
                
                if (
                  !results[i2].has_insurancecase &&
                  inside_interval &&
                  !results[i2].payment.paid
                ) {
                  results[i2].has_insurancecase = true;
                  results[i2].payment.has_insurancecase = true;
                  results[i2].payment.insurancecase = forsikring;
                  results[i2].insurancecase = forsikring;
                  antal = antal + 1;

                  if (!results[i2].payment.manuel) {
                    if (forsikring.price == 0) {
                      results[i2].payment.amount = this.args.patient.amount;
                    } else if (forsikring.price > 0) {
                      results[i2].payment.amount = forsikring.price;
                    }
                  }
                }
              }
            }
          }
        }
        results.forEach((appointment) => {
          appointment.background_class = betalinger.get_background_color(appointment.payment);
        });
        results = results.slice();
        results.sort((a, b) => {
          return a.time < b.time;
        });

        this.appointments = results;        
        this.loading = false;
        setTimeout(this.scroll_into_view, 250);
      }
    }
  }
}
