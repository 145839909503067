import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerArtiklerNewRoute extends Route {
  @service store;

  model() {
    return this.store.createRecord('article');
  }
}
