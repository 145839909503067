/* import __COLOCATED_TEMPLATE__ from './pager-element.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class PagerElement extends Component {
  @tracked page = 0;
  @tracked totalItems;
  @tracked perPage = 0;

  constructor() {
    super(...arguments);

    this.perPage = this.args.perPage;
  }

  get elementbetegnelse() {
    return this.args.elementbetegnelse ?? 'styk';
  }
  get total() {
    return this.totalItems;
  }
  get showPerPageChooser() {
    return this.total > 0;
  }
  get currentPage() {
    return this.page + 1;
  }
  get endPage() {
    let endPage = 1;

    if (this.total) {
      endPage = Math.trunc(this.total / this.perPage);
      if (this.total % this.perPage > 0) {
        endPage++;
      }
    }
    return endPage;
  }
  get showPager() {
    return true; // this.total > this.perPage;
  }

  get showTop() {
    return this.showPager && (this.args.showTopPager ?? true);
  }

  get showBottom() {
    return this.showPager && (this.args.showBottomPager ?? true);
  }

  get showStart() {
    let result = this.args.showStartButton ?? true;
    result = result && this.page !== 0;
    return result;
  }
  get showPrev() {
    return this.page !== 0;
  }

  get showNext() {
    return this.currentPage != this.endPage;
  }

  get showEnd() {
    let result = this.args.showEndButton ?? true;
    result = result && this.currentPage != this.endPage;
    return result;
  }

  get showTotal() {
    return this.total;
  }
  @action
  setPerPage(faktor) {
    let perPage = Math.trunc(this.perPage * faktor);

    if (perPage > 0) {
      this.perPage = perPage;
      if (this.page != 0) {
        this.page = 0;
      }
    }
  }

  @action
  updateTotal(newTotal) {
    if (!newTotal) {
      newTotal = 0;
    }
    if (this.total !== newTotal) {
      if (this.page != 0) {
        this.page = 0;
      }
      this.totalItems = newTotal;
    }
  }

  @action
  startPage() {
    if (this.page != 0) {
      this.page = 0;
    }
    if (this.args.notifyer)
    {
      this.args.notifyer(this.page);
    }
  }

  @action
  goEndPage() {
    this.page = this.endPage - 1;
    if (this.args.notifyer)
    {
      this.args.notifyer(this.page);
    }
  }

  @action
  prevPage() {
    if (this.page > 0) {
      this.page = this.page - 1;
      if (this.args.notifyer)
      { 
        this.args.notifyer(this.page);
      }
    }
  }

  @action
  nextPage() {
    this.page = this.page + 1;
    if (this.args.notifyer)
    {
      this.args.notifyer(this.page);
    }
  }
}
