import Controller from '@ember/controller';
import { action } from '@ember/object';
import ENV from 'boel/config/environment';
import fetch from 'fetch';
import { inject as service } from '@ember/service';
import
  {
    isServerErrorResponse,
    isUnauthorizedResponse,
  } from 'ember-fetch/errors';
import error from 'boel/utils/error';

export default class AuthSygeforsikringendanmarkController extends Controller
{
  @service session;
  @service router;
  @service notifications;
  @service intl;

  @action
  async test()
  {
    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };
    let link =
      ENV.APP.namespaceRoot +
      '/' +
      ENV.APP.namespace +
      '/sygeforsikringendanmark/invoice_status';
    link = link + '?' + 'uuid=sdfsf';
    if (this.session.isAuthenticated)
    {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    await fetch(link, {
      dataType: 'json',
      method: 'GET',
      headers: headers,
      cache: 'no-cache',
    })
      .then((response) =>
      {
        if (response.ok)
        {
          return response.json().then((resolved) =>
          {
            return resolved;
          });
        } else if (isUnauthorizedResponse(response))
        {
          this.notifications.info(this.intl.t('login.session_timeout'));
          this.session.invalidate();
        } else if (isServerErrorResponse(response))
        {
          this.notifications.error(this.intl.t('login.server_error'));
        }
      })
      .catch((err) =>
      {
        const Message = error.get_message(err);
        console.error(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          }),
        );
      });
  }
}
