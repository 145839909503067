/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class UiModalComponent extends Component {
  @tracked is_open = false;

  @action
  launchConfirmDialog() {
    this.is_open = true;
  }
  @action
  cancelConfirm() {
    this.is_open = false;
  }
}
