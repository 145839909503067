import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked, cached } from '@glimmer/tracking';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerBehandlingerController extends Controller
{
  queryParams = ['refresh'];

  @service router;

  @tracked Show_List_View = '1';
  @tracked refresh;
  @tracked searchWord = null;

  @cached
  get create_params()
  {
    let Not_Important = this.refresh; // Trigger refresh

    let params = {
      sort: 'name',
    };
    if (this.searchWord)
    {
      let filterArg = '';
      let Filter_Count = 0;

      if (this.searchWord)
      {
        filterArg = filterArg + "contains(name,'" + this.searchWord + "')";
        Filter_Count = Filter_Count + 1;
      }
      if (Filter_Count > 1)
      {
        filterArg = 'and(' + filterArg + ')';
      }
      if (filterArg)
      {
        Object.assign(params, { filter: filterArg });
      }
    }
    return params;
  }
  @action
  navigate_to_new()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.behandlinger.new');
  }
  @action
  setSearchValue(searchText)
  {
    this.searchWord = searchText;
  }
}
