import Controller from '@ember/controller';
import { tracked, cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthSuperbrugerInsurancecompaniesController extends Controller
{
  queryParams = ['refresh'];

  @service router;
  @service store;
  @service currentUser;

  @tracked refresh;
  @tracked searchWord = null;

  @cached
  get create_params()
  {
    let Not_Important = this.refresh; // Trigger refresh

    let params = {
      sort: 'name',
    };

    let filterArg = '',
      Filter_Count = 0;

    // String filter
    if (this.searchWord)
    {
      if (Filter_Count > 0)
      {
        filterArg = filterArg + ',';
      }
      filterArg =
        filterArg +
        "or(contains(name,'" +
        this.searchWord +
        "'),contains(economicid,'" +
        this.searchWord +
        "'))";
      Filter_Count = Filter_Count + 1;
    }

    if (Filter_Count > 1)
    {
      filterArg = 'and(' + filterArg + ')';
    }
    if (filterArg)
    {
      Object.assign(params, { filter: filterArg });
    }

    return params;
  }
  @action
  setSearchValue(searchText)
  {
    this.searchWord = searchText;
  }
  @action
  navigate_to_new()
  {
    this.router.transitionTo('auth.superbruger.insurancecompanies.new');
  }
}
