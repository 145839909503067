/* import __COLOCATED_TEMPLATE__ from './favorit-filterliste.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class FavoritFilterlisteComponent extends Component {
  @service store;
  @service favoritter;

  Last_Modified;
  @tracked Filter_Favoritter;

  constructor() {
    super(...arguments);
    this.FetchFavoritesInfo(this.args.favoritType, this.args.modelName);

    setTimeout(this.maintain, 60000);
  }
  @action
  maintain() {
    if (this.favoritter.Last_Modified > this.Last_Modified) {
      this.FetchFavoritesInfo(this.args.favoritType, this.args.modelName);
    }
    setTimeout(this.maintain, 3000);
  }
  async FetchFavoritesInfo(favorittype, modelName) {
    let fav = await this.favoritter.FetchFavoritesInfo.perform(favorittype);

    if (fav) {
      fav.forEach((element) => {
        this.store.findRecord(modelName, element.favoritId).then((kursist) => {
          let temp_liste = [];
          temp_liste.push(kursist);
          this.Filter_Favoritter = temp_liste;
        });
      });
    }
    this.Last_Modified = new Date().getTime();
  }
  @action
  UpdateList() {
    this.favoritter.Update();
    this.FetchFavoritesInfo(this.args.favoritType, this.args.modelName);
  }
  @action
  Select_Favorit(Selected_Item_Id) {
    let Selected_Item_Id_Int = parseInt(Selected_Item_Id, 10);
    let Selected_Item = this.Filter_Favoritter.find(
      (item) => item.get('id') == Selected_Item_Id_Int,
    );

    this.args.onChange(Selected_Item);
  }
}
