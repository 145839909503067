import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';

export default class ApplicationController extends Controller
{
  @service session;
  @service router;
  @service currentUser;
  
  get isNotIndex()
  {
    let route = this.router.currentRouteName;
    return route != 'index';
  }

  @action
  invalidateSession()
  {
    this.session.invalidate();
  }
}
