/* import __COLOCATED_TEMPLATE__ from './form-payment-edit.hbs'; */
import Component from '@glimmer/component';

export default class AppFormPaymentEditComponent extends Component {
  Valuta;

  constructor() {
    super(...arguments);

    this.Valuta = Intl.supportedValuesOf('currency').sort();
  }
}
