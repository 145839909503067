export default class calender {
  addDays(datein, days) {
    let date = new Date(datein);
    date.setDate(date.getDate() + days);
    return date;
  }
  EasterSunday(Y) {
    var C = Math.floor(Y / 100);
    var N = Y - 19 * Math.floor(Y / 19);
    var K = Math.floor((C - 17) / 25);
    var I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
    I = I - 30 * Math.floor(I / 30);
    I =
      I -
      Math.floor(I / 28) *
        (1 -
          Math.floor(I / 28) *
            Math.floor(29 / (I + 1)) *
            Math.floor((21 - N) / 11));
    var J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
    J = J - 7 * Math.floor(J / 7);
    var L = I - J;
    var M = 3 + Math.floor((L + 40) / 44);
    var D = L + 28 - 31 * Math.floor(M / 4);

    return new Date(Y, M - 1, D);
  }
  sammenlignUdenAar(et, to) {
    return et.getDate() == to.getDate() && et.getMonth() == to.getMonth();
  }
  erHelligdag(dato) {
    let påskeSøndag = this.EasterSunday(dato.Year);
    let erPåskedag = this.sammenlignUdenAar(påskeSøndag, dato);

    let førsteJanuar = dato.Month == 1 && dato.Day == 1;
    let Juledag = dato.Month == 12 && dato.Day == 25;
    let AndenJuledag = dato.Month == 12 && dato.Day == 26;

    // Skærtorsdag: torsdagen før påskedag.
    let SkærTorsdag = this.sammenlignUdenAar(
      this.addDays(påskeSøndag, -3),
      dato,
    );

    // Langfredag: fredagen før påskedag.
    let Langfredag = this.sammenlignUdenAar(
      this.addDays(påskeSøndag, -2),
      dato,
    );

    // Anden påskedag: dagen efter påskedag.
    let AndenPåskedag = this.sammenlignUdenAar(
      this.addDays(påskeSøndag, 1),
      dato,
    );

    // Store bededag: fredagen 3 uger og 5 dage efter påskedag.
    let StoreBededag = this.sammenlignUdenAar(
      this.addDays(påskeSøndag, 26),
      dato,
    );

    // Kristi himmelfartsdag: torsdagen 5 uger og 4 dageefter påskedag.
    let KristiHimmelfart = this.sammenlignUdenAar(
      this.addDays(påskeSøndag, 39),
      dato,
    );

    // Pinsedag (til minde om Helligåndens komme):søndagen 7 uger efter påskedag.
    let Pinsedag = this.sammenlignUdenAar(this.addDay(påskeSøndag, 49), dato);

    // Anden pinsedag: dagen efter pinsedag.
    let AndenPinsedag = this.sammenlignUdenAar(
      this.addDays(påskeSøndag, 50),
      dato,
    );

    return (
      førsteJanuar ||
      Juledag ||
      AndenJuledag ||
      SkærTorsdag ||
      Langfredag ||
      erPåskedag ||
      AndenPåskedag ||
      StoreBededag ||
      KristiHimmelfart ||
      Pinsedag ||
      AndenPinsedag
    );
  }
  Helligdage(Year) {
    let helligdagsliste = [];

    const påskeSøndag = this.EasterSunday(Year);

    helligdagsliste.push(påskeSøndag);

    //  let erPåskedag = this.sammenlignUdenAar(påskeSøndag, dato);

    //  let førsteJanuar = dato.Month == 1 && dato.Day == 1;
    helligdagsliste.push(new Date(Year, 0, 1));

    //  let Juledag = dato.Month == 11 && dato.Day == 25;
    helligdagsliste.push(new Date(Year, 11, 25));

    //  let AndenJuledag = dato.Month == 11 && dato.Day == 26;
    helligdagsliste.push(new Date(Year, 11, 26));

    // Skærtorsdag: torsdagen før påskeSøndag.
    // let Skærtorsdag = this.sammenlignUdenAar(påskeSøndag.AddDays(-3), dato);
    helligdagsliste.push(this.addDays(påskeSøndag, -3));

    // Langfredag: fredagen før påskeSøndag.
    // let Langfredag = this.sammenlignUdenAar(påskeSøndag.AddDays(-2), dato);
    helligdagsliste.push(this.addDays(påskeSøndag, -2));

    // Anden påskedag: dagen efter påskeSøndag.
    // let AndenPåskedag = this.sammenlignUdenAar(påskeSøndag.AddDays(1), dato);
    helligdagsliste.push(this.addDays(påskeSøndag, 1));

    // Store bededag: fredagen 3 uger og 5 dage efter påskeSøndag.
    // let StoreBededag = this.sammenlignUdenAar(påskeSøndag.AddDays(26), dato);
    helligdagsliste.push(this.addDays(påskeSøndag, 26));

    // Kristi himmelfartsdag: torsdagen 5 uger og 4 dageefter påskedag.
    /*   let KristiHimmelfart = this.sammenlignUdenAar(
       påskeSøndagAddDays(39),
       dato
     );*/
    helligdagsliste.push(this.addDays(påskeSøndag, 39));

    // Pinsedag (til minde om Helligåndens komme):søndagen 7 uger efter påskedag.
    /*  let Pinsedag = this.sammenlignUdenAar(påskeSøndag.AddDay(49), dato);*/
    helligdagsliste.push(this.addDays(påskeSøndag, 49));

    // Anden pinsedag: dagen efter pinsedag.
    /*    let AndenPinsedag = this.sammenlignUdenAar(påskeSøndag(50), dato); */
    helligdagsliste.push(this.addDays(påskeSøndag, 50));

    return helligdagsliste;
  }
}
