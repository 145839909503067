import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerBrugereController extends Controller
{
  queryParams = ['refresh'];

  @service router;

  @tracked Show_List_View = '1';
  @tracked chosenOutlet = null;
  @tracked outletId = -1;
  @tracked refresh;
  @tracked searchWord = null;
  @tracked pageSize = 6;
  @service currentUser;

  @action
  setSearchValue(searchText)
  {
    this.searchWord = searchText;
  }
  @action
  navigate_to_new()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.brugere.new');
  }
  @action
  chooseOutlet(outlet)
  {
    this.chosenOutlet = outlet;

    let id = -1;

    if (outlet)
    {
      id = outlet.id;
    }
    this.outletId = id;
  }
}
