export const PRIMITIVE  = 0;
export const ARRAY      = 1;
export const OBJECT     = 2;
export const DATE       = 3;
export const REGEXP     = 4;
export const MAP        = 5;
export const SET        = 6;
export const ERROR      = 7;
export const BIGINT     = 8;
// export const SYMBOL = 9;
