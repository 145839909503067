import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthPatienterInsurancecasesDeleteRoute extends Route {
  @service store;

  model(params) {
    return this.store.findRecord('insurancecase', params.insurancecase_id);
  }
}
