import Controller from '@ember/controller';
import { tracked, cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';
import DateUtils from 'boel/utils/DateUtils';
import ENV from 'boel/config/environment';

export default class AuthAftalerController extends Controller
{
  queryParams = [
    'pid',
    'date',
    'datetime',
    'edita',
    'stamdata',
    'journal',
    'payments',
    'insurance',
    'chosen'
  ];

  @service router;
  @service store;
  @service intl;
  @service confirmation;
  @service previousRoute;

  @service currentUser;
  @service notifications;

  @tracked pid;
  @tracked date;
  @tracked edita;
  @tracked editp;
  @tracked stamdata;
  @tracked journal;
  @tracked confirm_appointment;
  @tracked confirm_payment;
  @tracked payments;
  @tracked insurance;
  @tracked searchWord = '';
  @tracked inactive = false;
  @tracked selectedScope = 'Navn';
  @tracked chosen_journal;
  @tracked merge;
  @tracked chosenOutlet;
  @tracked outletId;
  @tracked merge;
  @tracked All_Outlets = false;
  @tracked tidspunkt;
  @tracked tidspunkt2;

  @tracked ChosenTime;
  @tracked ChosenDay;
  @tracked ChosenPatient;
  @tracked ChosenAppointment;
  @tracked ChosenPayment;
  @tracked ChosenInsurancecase;
  @tracked NewInsurancecase;

  @tracked Time_Is_Valid = false;
  @tracked Visningstype = 1;
  @tracked current_patient_page = 1;
  @tracked patients_per_page = 100;
  @tracked selected_appointment;

  search_scopes = [
    "Navn",
    "CPR",
    "Email",
    "Postnummer",
    "Telefonnummer"
  ]
  constructor(...args)
  {
    super(...args);
    this.currentUser.subscribe(this.chooseOutlet);
    this.currentUser.load().then();
    
  }
  @action
  selectScope(scope)
  {
    if (this.selectedScope != scope.target.value)
    {
      this.selectedScope = scope.target.value;
      if (this.State != 'Default')
      {
        this.searchClicked();
      }
    }
    let search_Field = document.getElementsByClassName('filter_search_input');
    if (search_Field && search_Field.length > 0)
    {
      search_Field[0].focus();
    }
  }
  @action
  paging_changed(page)
  {
    this.current_patient_page = page;
  }
  @cached
  get lastAppointment()
  {
    if (this.pid && this.ChosenPatient)
    {
      return this.store.query('appointment', {
        filter: "equals(patient.id,'" + this.pid + "')",
        include: 'patient,problem',
        sort: '-time',
        "page[size]": 1,
        "page[number]": 1
      }).then((appointments) =>
      {
        if (appointments && appointments.length > 0)
        {
          return appointments[0];
        }
        else
        {
          return null;
        }
      });
    }
    else
    {
      return null;
    }
  }
  @cached
  get aftale_list_titel()
  {
    let title = ENV.FirmaNavn + ', ';
    title += this.chosenOutlet.postalCode;
    title += ' ' + this.chosenOutlet.city;
    return title;
  }
  @cached
  get State()
  {
    if (this.edita)
    {
      if (this.confirm_appointment)
      {
        return 'ConfirmAppointment';
      }
      else
      {
        return 'EditAppointment';
      }
    }
    if (this.confirm_payment)
    {
      return 'ConfirmPayment';
    }
    if (this.pid)
    {
      if (this.merge && this.merge === "true")
      {
          return "ViewMerge";
      }
      else if (this.stamdata && this.stamdata === "true")
      {
        return 'ViewStamdata';
      }
      else if (this.journal && this.journal === "true")
      {
        return 'ViewJournal';
      }
      else if (this.payments && this.payments === "true")
      {
        return 'ViewPayment';
      }
      else if (this.insurance && this.insurance === "true")
      {
        return 'ViewInsurance';
      }
      else
      {
        return 'ViewPatient';
      }
    }
    if (this.stamdata && this.stamdata === "true")
    {
      return 'ViewStamdata';
    }
    else if (
      this.ChosenPatient &&
      this.ChosenPatient.dirtyType == 'created')
    {
      return 'NewPatient';
    }
    else
    {
      if (this.merge)
      {
        return "Merge";
      }
      else
      {
        return 'Default';
      }
    }
  }
  @cached
  get Valid_Patient()
  {
    return this.ChosenPatient && this.ChosenPatient.dirtyType !== 'created';
  }
  @cached
  get create_params_patienter()
  {
    let params = {
      include: 'outlet',
      sort: 'fullname',
    };
    let filterArg = '';
    let Filter_Count = 0;

    let scope = this.selectedScope;
    if (!scope)
    {
      scope = "Navn";
    }
    if (this.searchWord)
    {
      const Search_Terms = this.searchWord.split(' ');

      if (Filter_Count > 0)
      {
        filterArg = filterArg + ',';
      }
      let Search_Filter = '';
      const prefix = 'and(';

      let terms = 0;
      Search_Terms.forEach((term) =>
      {
        if (term)
        {
          terms += 1;
          if (Search_Filter)
          {
            Search_Filter += ',';
          }
          if (scope == "Navn")
          {
            Search_Filter += "contains(fullname,'" +
              term + "')";
          }
          else if (scope == "CPR")
          {
            Search_Filter += "contains(cpr,'" +
              term + "')";
          }
          else if (scope == "Postnummer")
          {
            Search_Filter += "contains(postalcode,'" +
              term + "')";
          }
          else if (scope == "Email")
          {
              Search_Filter += "contains(email,'" +
                term + "')";
          }
          else if (scope == "Telefonnummer")
          {
            Search_Filter += "or(contains(cellphone,'" +
              term + "'),contains(telephone,'" +
              term + "'))";
          }
        }
      });
      if (terms > 1)
      {
        Search_Filter = prefix + Search_Filter + ')';
      }
      filterArg = filterArg + Search_Filter;
      Filter_Count = Filter_Count + 1;
    }
    let inactive_filter = this.inactive ? "equals(inactive,'true')" : "equals(inactive,'false')"
    if (Filter_Count > 0)
    {
      filterArg += ",";
    }
    filterArg = filterArg + inactive_filter;
    Filter_Count = Filter_Count + 1;

    if (!this.All_Outlets) {
      if (Filter_Count > 0)
      {
        filterArg += ",";
      }    
      filterArg = filterArg + "equals(outlet.id,'" + this.outletId + "')";
      Filter_Count = Filter_Count + 1;
    }
    if (this.State == 'ViewMerge' && this.pid) 
    {
      let filter_target_patient_out = "not(equals(id,'"+this.pid+"'))"
      if (Filter_Count > 0)
        {
          filterArg += ",";
        }
        filterArg = filterArg + filter_target_patient_out;
        Filter_Count = Filter_Count + 1;
    }
    if (Filter_Count > 1)
    {
      filterArg = 'and(' + filterArg + ')';
    }
  
    if (filterArg)
    {
      Object.assign(params, { filter: filterArg });
    }
    return params;
  }
  @cached
  get create_params()
  {
    let params = {
      include: 'outlet,problem,patient,appointmentstate',
      sort: 'time',
    };

    let filterArg = '',
      Filter_Count = 0;
  
    // Outlet filter
    if (this.chosenOutlet && this.chosenOutlet.id != -1)
    {
      filterArg =
        filterArg + "equals(outlet.id,'" + this.outletId + "')";
      Filter_Count = Filter_Count + 1;
    }

    // Date filter
    let date_filter_string =
      "and(greaterOrEqual(time,'" +
      this.tidspunkt +
      "'),lessThan(time,'" +
      this.tidspunkt2 +
      "'),equals(isroombooking,'false'))";

    if (Filter_Count > 0)
    {
      filterArg = filterArg + ',';
    }
    filterArg = filterArg + date_filter_string;
    Filter_Count = Filter_Count + 1;
    // End date filter

    if (Filter_Count > 1)
    {
      filterArg = 'and(' + filterArg + ')';
    }
    if (filterArg)
    {
      Object.assign(params, { filter: filterArg });
    }
    return params;
  }

  @action
  async confirm(appointment)
  {
    this.confirm_appointment = true;
    this.ChosenAppointment = appointment;
    this.edita = appointment.id;
    //await this.confirmation.confirm(appointment);
  }
  @action
  async confirm_payments(payment)
  {
    this.confirm_payment = true;
    this.ChosenPayment = payment;
    this.editp = payment.id;
    //await this.confirmation.confirm(appointment);
  }
  @action
  glny(aftaler)
  {
    return aftaler.filter((appointment) =>
    {
      return (
        appointment.appointmentstate && appointment.appointmentstate.id == 3
      );
    }).length;
  }
  @action
  ny(aftaler)
  {
    return aftaler.filter((appointment) =>
    {
      return (
        appointment.appointmentstate && appointment.appointmentstate.id == 2
      );
    }).length;
  }
  @action
  changeDate(change)
  {
    var d = new Date(this.ChosenDay);

    if (d && change)
    {
      var add = change > 0;
      var amount = Math.abs(change);
      d = DateUtils.AddOrSubtractDays(d, amount, add);
      this.chooseDay(d);
    }
  }
  @action
  async NavigateTo_NewPatient()
  {
    this.searchWord = '';
    this.pid = null;
    this.ChosenPatient = null;
    this.stamdata = "true";
  }

  @action
  setSearchValue(searchText, inactive, alloutlets)
  {
    if (searchText != this.searchWord || this.inactive != inactive  || this.All_Outlets != alloutlets)
    {
      this.searchWord = searchText;
      this.inactive = inactive;
      this.All_Outlets = alloutlets;
      if (this.State != 'Default' && this.State != 'ViewMerge')
      {
        this.searchClicked();
      }
    }
  }
  @action
  patientDeleted()
  {
    this.patient = null;
    this.setSearchValue("", this.inactive, this.All_Outlets);
  }
  @action
  async tilbage_fra_stamdata(target,patient)
  {
    this.stamdata = null;
    if (target === "journal")
    {
      this.journal = "true"
    }
    else if (target === "payments") {
      this.payments = "true"
    }
    if (this.pid) {
      this.ChosenPatient = await this.store.findRecord('patient', this.pid);
    }
  }
  @action
  async chooseOutlet(outlet)
  {
    if (outlet != this.chosenOutlet)
    {
      this.chosenOutlet = outlet;
      let id = -1;

      if (outlet)
      {
        id = outlet.id;
      }
      this.outletId = id;
      this.refresh = new Date().getTime();
    }
    if (this.ChosenAppointment)
    {
      if (this.ChosenAppointment.outlet != outlet)
      {
        this.ChosenAppointment.outlet = outlet;
        await this.ChosenAppointment.save();
      }
    }
  }
  @action
  async Update_Appointment(problem, status)
  {
    if (this.ChosenAppointment)
    {
      this.ChosenAppointment.time = this.ChosenTime;
      this.ChosenAppointment.problemtext = problem;
      this.ChosenAppointment.appointmentstate = status;
      await this.ChosenAppointment.save();
      this.Dis_Choose_Appointment();
    }
  }
  @action
  validateTime(Is_Valid)
  {
    this.Time_Is_Valid = Is_Valid;
  }
  @action
  chooseTime(dato)
  {
    this.ChosenTime = dato;
  }
  @action
  chooseDay(dato)
  {
    const Start_isoDate = DateUtils.Get_ISO_Date(dato);

    if (this.date != Start_isoDate)
    {
      this.date = Start_isoDate;
      this.tidspunkt = Start_isoDate;
    }
    if (this.ChosenDay != dato)
    {
      this.ChosenDay = dato;
      this.datetime = dato;
      this.ChosenTime = dato;
    }
  }
  @cached
  get Show_Save_Appointment()
  {
    return (
      this.ChosenAppointment && this.ChosenAppointment.time != this.ChosenDay
    );
  }
  @action
  navigate_to_new()
  {
    this.router.transitionTo('auth.aftaler.newnopatient');
  }
  @action
  cancel_new_appointment()
  {
    if (this.ChosenPatient)
    {
      this.ChosenPatient.unloadRecord();
      this.ChosenPatient = null;
    }
    this.stamdata = null;
  }
  @action
  navigate_appointment_row(patient, appointment)
  {
    this.router.transitionTo({ queryParams: { chosen: undefined } });
    this.selectedScope = "Navn";
    this.searchWord = appointment.patient.fullname;
    this.selected_appointment = appointment;
    this.view_patient(patient);
  }
  @action
  async merge_patient_row(patient)
  {
    let original_id = this.pid;
    let to_be_merged_id = patient.id;
     
    let Is_Valid = original_id && to_be_merged_id;

    if (Is_Valid)
    {
      this.router.transitionTo("auth.aftaler.merge", original_id, to_be_merged_id);
    }
  }
  @action
  async navigate_patient_row(patient)
  {
    this.selectedScope = "Navn";
    if (this.pid != patient.id)
    {
      this.pid = patient.id;
    }
    if (this.ChosenPatient != patient)
    {
      this.ChosenPatient = patient;
      this.searchWord = patient.fullname;
      this.selected_appointment = null;
    }
    if (this.edita != null)
    {
      this.edita = null;
    }
    if (this.chosenOutlet != patient.outlet)
    {
      this.chosenOutlet = patient.outlet;
      this.outletId = patient.outlet.id;
      await this.currentUser.ChangeOutlet(this.outletId);
    }
  }
  @action
  Dis_Choose_Appointment()
  {
    this.edita = null;
    this.ChosenAppointment = null;
  }
  @action
  Dis_Choose_Confirm()
  {
    this.edita = null;
    this.ChosenAppointment = null;
    this.confirm_appointment = null;
    this.confirm_payment = null;
    this.editp = null;
  }
  @action
  Choose_Journal(journal)
  {
    if (journal && this.chosen_journal != journal)
    {
      this.chosen_journal = journal;
    }
  }
  @action
  tilbage_fra_journal()
  {
    let previousRoute = this.previousRoute.getPreviousRoute();

    if (previousRoute)
    {
      previousRoute = '' + previousRoute;
      previousRoute = previousRoute.toLowerCase();

      if (previousRoute.includes('kabinestyring'))
      {
        this.router.transitionTo('auth.kabinestyring');
      }
    }
    this.journal = null;
    this.chosen_journal = null;
  }
  @action
  tilbage_til_appointments()
  {
    this.journal = null;
    this.chosen_journal = null;
  }
  @action
  unchoose_journal()
  {
    this.chosen_journal = null;
  }
  @action
  async Edit_Appointment(appointment)
  {
    this.edita = appointment.id;
    this.ChosenAppointment = appointment;
    let tidspunkt = new Date(appointment.time.getTime());
    let minutes = tidspunkt.getMinutes();

    while (minutes % 5 > 0)
    {
      minutes += 1;
    }
    if (minutes > 59)
    {
      minutes = null;
    }
    if (minutes != tidspunkt.getMinutes())
    {
      tidspunkt.setMinutes(minutes);
    }
    this.chosenTime = tidspunkt;
    this.chooseDay(tidspunkt);
    if (this.chosenOutlet != appointment.outlet)
    {
      this.chosenOutlet = appointment.outlet;
      this.outletId = appointment.outlet.id;
      await this.currentUser.ChangeOutlet(this.outletId);
    }
  }
  @action
  async Appointment_Created(/*appointment*/)
  {
    this.ChosenDay = this.ChosenDay;
    if (ENV.APP.ShowNotifications) {
      this.notifications.success(this.intl.t('inline_new_appointment.created'));
    }
    this.ChosenPatient = this.ChosenPatient;
    this.chosenOutlet = this.chosenOutlet;
  }
  @action
  journal_opdateret(previousRoute)
  {
    if (previousRoute && previousRoute == "kabinestyring")
    {
      this.router.transitionTo("auth.kabinestyring");
    }
    else
    {
      this.ChosenPatient = this.ChosenPatient;
    }
  }
  @action
  searchClicked()
  {
    if (this.merge != null)
      {
        this.merge = null;
      }
    if (this.payments != null)
    {
      this.payments = null;
    }
    if (this.stamdata != null)
    {
      this.stamdata = null;
    }
    if (this.journal != null)
    {
      this.journal = null;
    }
    if (this.edita != null)
    {
      this.edita = null;
    }
    if (this.edita != null)
    {
      this.edita = null;
    }
    if (this.insurance != null)
    {
      this.insurance = null;
    }
    if (this.NewInsurancecase != null)
    {
      this.NewInsurancecase = null;
    }
    if (this.confirm_appointment)
    {
      this.confirm_appointment = null;      
    }
    if (this.confirm_payment)
    {
      this.confirm_payment = null;  
      this.editp = null;    
    }
    this.pid = null;
    this.ChosenPatient = null;
  }
  @action
  view_patient_back()
  {
    this.pid = null;
    this.ChosenPatient = null;
    this.merge = null;
  }
  @action
  view_insurance_back()
  {
    this.ChosenInsurancecase = null;
    this.insurance = null;
    this.NewInsurancecase = null;
  }
  @action
  view_journal(patient)
  {
    if (patient)
    {
      if (this.merge != null)
      {
        this.merge = null;
      }
      if (this.edita != null)
      {
        this.edita = null;
      }
      if (this.pid != patient.id)
      {
        this.pid = patient.id;
      }
      this.journal = true;

      if (this.payments != null)
      {
        this.payments = null;
      }
      if (this.stamdata != null)
      {
        this.stamdata = null;
      }
      if (this.insurance != null)
      {
        this.insurance = null;
      }
      if (this.confirm_appointment)
      {
        this.confirm_appointment = null;      
      }
      if (this.confirm_payment)
      {
        this.confirm_payment = null;  
        this.editp = null;    
      }
    }
  }
  @action
  choosepatient(patient)
  { 
    this.searchWord = patient.fullname.trim();
  }
  @action
  view_patient(patient)
  {
    if (patient)
    {
      if (this.merge != null)
      {
        this.merge = null;
      }
      if (this.edita != null)
      {
        this.edita = null;
      }
      if (this.payments != null)
      {
        this.payments = null;
      }
      if (this.journal != null)
      {
        this.journal = null;
      }
      if (this.insurance != null)
      {
        this.insurance = null;
      }
      if (this.stamdata != null)
      {
        this.stamdata = null;
      }
       if (this.pid != patient.id)
      {
        this.pid = patient.id;
      } 
      if (this.ChosenPatient != patient)
      {
        this.ChosenPatient = patient;
      }
      if (this.confirm_appointment)
      {
        this.confirm_appointment = null;      
      }
      if (this.confirm_payment)
      {
        this.confirm_payment = null;  
        this.editp = null;    
      }
    }
  }
  @action
  view_stamdata(patient)
  {
    if (patient)
    {
      if (this.merge != null)
        {
          this.merge = null;
        }
      if (this.edita != null)
      {
        this.edita = null;
      }
      if (this.pid != patient.id)
      {
        this.pid = patient.id;
      }
      if (this.payments != null)
      {
        this.payments = null;
      }
      if (this.journal != null)
      {
        this.journal = null;
      }
      if (this.insurance != null)
      {
        this.insurance = null;
      }
      if (this.confirm_appointment)
        {
          this.confirm_appointment = null;      
        }
        if (this.confirm_payment)
        {
          this.confirm_payment = null;  
          this.editp = null;    
        }
      this.stamdata = "true";
    }
  }
  @action
  view_payments(patient)
  {
    if (patient)
    {
      if (this.merge != null)
        {
          this.merge = null;
        }
      if (this.edita != null)
      {
        this.edita = null;
      }
      if (this.journal != null)
      {
        this.journal = null;
      }
      if (this.stamdata != null)
      {
        this.stamdata = null;
      }
      if (this.insurance != null)
      {
        this.insurance = null;
      }
      if (this.confirm_appointment)
        {
          this.confirm_appointment = null;      
        }
        if (this.confirm_payment)
        {
          this.confirm_payment = null;  
          this.editp = null;    
        }
      this.payments = "true";
    }
  }
  @action
  view_insurance(patient, insurancecase, new_insurance)
  {
    if (patient)
    {
      if (this.merge != null)
      {
        this.merge = null;
      }
      if (this.edita != null)
      {
        this.edita = null;
      }
      if (this.journal != null)
      {
        this.journal = null;
      }
      if (this.stamdata != null)
      {
        this.stamdata = null;
      }
      if (this.payments != null)
      {
        this.payments = null;
      }
      if (this.pid != patient.id)
      {
        this.pid = patient.id;
      }
      if (this.ChosenPatient != patient)
      {
        this.ChosenPatient = patient;
      }
      if (this.confirm_appointment)
        {
          this.confirm_appointment = null;      
        }
        if (this.confirm_payment)
        {
          this.confirm_payment = null;  
          this.editp = null;    
        }
      this.insurance = "true";
      this.ChosenInsurancecase = insurancecase;

      this.NewInsurancecase = new_insurance;
    }
  }
  @action
  view_merge()
  {
      this.merge = "true";

  }
}
