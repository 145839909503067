import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerInsurancecompaniesRoute extends Route {
  queryParams = {
    refresh: {
      refreshModel: true,
    },
  };

  @service currentUser;
  @service store;

  async setupController(controller, model, transition) {
    super.setupController(controller, model, transition);
  }
}
