import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import DateUtils from 'boel/utils/DateUtils';
export default class AuthKabinestyringController extends Controller
{
  queryParams = [
    'isodate',
  ];

  @service clock;
  @service currentUser;
  @service router;
  @service kabinestyringhub;

  @tracked isodate;
  @tracked chosenOutlet;
  @tracked outletId;
  @tracked flatpickrRef = null
  
  ViewDateChooser;
  constructor(...args)
  {
    super(...args);
    this.ViewDateChooser = false;
    this.currentUser.subscribe(this.chooseOutlet);
  }
  get iso()
  {
    return this.clock.date.toLocaleTimeString('da-DK', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });
  }
  @action
  onReady(_selectedDates, _dateStr, flatpickrRef)
  {
    this.flatpickrRef = flatpickrRef
  }
  @action
  toggleCalendar(e)
  {
    e.stopPropagation()
    this.flatpickrRef.toggle();
  }
  @action
  clearCalendar(e)
  {
    e.stopPropagation()
    this.flatpickrRef.clear();
  }
  @action
  onChange(value_array)
  {
    if (value_array &&
      Array.isArray(value_array) &&
      value_array.length > 0)
    { 
      let selected_date = value_array[0];
      if (selected_date)
      { 
        this.ChosenDay = selected_date;
        this.isodate = DateUtils.Get_ISO_Date(selected_date);
      }
    }
  }
  @action
  changeDate(change)
  {
    var d = new Date(this.ChosenDay);

    if (d && change)
    {
      var add = change > 0;
      var amount = Math.abs(change);
      d = DateUtils.AddOrSubtractDays(d, amount, add);
      this.isodate = DateUtils.Get_ISO_Date(d);
    }
  }
  @action
  async chooseOutlet(outlet)
  {
    if (outlet != this.chosenOutlet)
    {
      this.router.refresh();
    }
  }
}
