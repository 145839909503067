import Model, { attr, belongsTo } from '@ember-data/model';

export default class AssociationModel extends Model {
  @attr('string', { defaultValue: '' })
  bruger;

  @attr('number', { defaultValue: 100 })
  order;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @attr
  created;

  @attr
  updated;
}
