/* import __COLOCATED_TEMPLATE__ from './inline-journal.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ENV from 'boel/config/environment';
export default class AppInlineJournalComponent extends Component {

  @service notifications;
  @service intl;
  @service store;
  @service previousRoute;
  
  @tracked new;
  @tracked editj;
  @tracked journal;
    
  get State() {
    if (this.editj) {
      return 'edit';
    }
    if (this.new) {
      return 'new';
    } else {
      return 'Default';
    }
  }
  get LinkTo_Kabinestyring() {
    const get_previousRoute = this.previousRoute.getPreviousRoute();
    console.log(get_previousRoute);
    return (get_previousRoute && get_previousRoute === "auth.kabinestyring.index")
  }
  @action
  update()
  { 
    if (this.args.chosen_journal && this.args.chosen_journal != this.journal)
    { 
      this.journal = this.args.chosen_journal;
      this.editj = this.journal != null;
    }
  }
  @action
  async Fetch_journals()
  {
    if (this.args.patient)
    {
      const patient_id = this.args.patient.id;

      if (patient_id)
      {
        this.Is_Loading = true;
        let j = await this.store.query('journal', {
          filter: "equals(patient.id,'" + patient_id + "')",
          include: 'patient,problem,therapist,journaltype',
          sort: '-created',
        });
        this.journals = j;
      }
    }
    this.Is_Loading = false;
  }
  @action
  async delete(journal) {
    if (journal)
    {
      const has_measurement = (journal.result != '' && journal.result != null); 
      await journal.destroyRecord();
      await this.Fetch_journals();
      this.journal = null;
      this.patient = this.patient;
      this.editj = false;
      if (has_measurement)
      {
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(this.intl.t('inline_journal.delete_success_data'));
        }
      }
      else
      { 
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(this.intl.t('inline_journal.delete_success'));
        }
      }
      this.args.journalOpdateret();
    }
  }
  @action
  async edit(journal) {

    await this.Fetch_journals();
    
    this.new = null;
    this.patient = this.patient;
    this.journal = journal;
    this.editj = journal != null;

    this.args.journalOpdateret();
  }
  @action
  async tilbage()
  {
    this.new = null;
    this.journal = null;
    this.editj = null;
    this.args.unchoose_journal();
    await this.Fetch_journals();  
    
    this.patient = this.patient;
  
    this.args.journalOpdateret(this.previousRoute.getPreviousRoute());
  }
}
