/* import __COLOCATED_TEMPLATE__ from './choose-date.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ChooseDateComponent extends Component {
  @tracked selectedDate = null;
  @tracked flatpickrReference;

  constructor() {
    super(...arguments);

    if (this.args.selected) {
      this.selectedDate = this.args.selected;
    }
  }

  @action
  change(date) {
    let selected = null;
    if (date) {
      selected = date;
    }
    this.selectedDate = selected;
    if (this.args.setDirty) {
      this.args.setDirty();
    }
    this.args.update(selected);
  }
  @action
  onReady(_selectedDates, _dateStr, instance) {
    this.flatpickrReference = instance;
  }
  @action
  clearCalendar() {
    this.flatpickrReference.clear();
  }
}
