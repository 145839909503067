import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import ENV from 'boel/config/environment';
export default class AuthSuperbrugerIndstillingerAfdelingerOpeninghoursDeleteController extends Controller
{
  @service router;
  @service notifications;
  @service intl;

  @task
  *deleteTask()
  {
    let outlet_id = this.router.currentRoute.parent.params.outlet_id;

    yield this.model.destroyRecord().then(() =>
    {
      if (ENV.APP.ShowNotifications) {
        this.notifications.success(this.intl.t('openinghours.delete_success'));
      }
      this.router.transitionTo(
        'auth.superbruger.indstillinger.afdelinger.openinghours',
        outlet_id,
        {
          queryParams: { refreshhours: Date.now() },
        },
      );
    });
  }
  @action
  cancel()
  {
    let outlet_id = this.router.currentRoute.parent.params.outlet_id;

    this.router.transitionTo(
      'auth.superbruger.indstillinger.afdelinger.openinghours',
      outlet_id,
    );
  }
}
