/* import __COLOCATED_TEMPLATE__ from './form-knaptidsstyring.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class FormKnaptidsstyringComponent extends Component {
  @tracked redigerPeriodeCollapsed;
  @tracked selectedPeriod;
  @tracked rediger;

  constructor() {
    super(...arguments);
    this.rediger = false;
    let harPerioder = !isEmpty(this.args.perioder);
    this.redigerPeriodeCollapsed = harPerioder;
  }
  @action
  gemPeriode(nyPeriode) {
    this.rediger = false;
    this.redigerPeriodeCollapsed = true;
    this.selectedPeriod = null;
  }
  @action
  editPeriod(item) {
    this.selectedPeriod = item;
    this.redigerPeriodeCollapsed = false;
    this.rediger = true;
  }
  @action
  lukDialog() {
    this.redigerPeriodeCollapsed = true;
    this.rediger = false;
    this.selectedPeriod = null;
  }
  @action
  addPeriod(item) {
    this.rediger = false;
    this.selectedPeriod = null;
    this.args.add(item);
  }
  @action
  removePeriod(item) {
    this.args.remove(item);
  }
}
