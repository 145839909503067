import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthSuperbrugerIndstillingerBrugereNewRoute extends Route {
  @service store;
  @service currentUser;

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('chosenOutlet', this.currentUser.CurrentActiveOutlet);
  }
  async model() {
    const outlet = await this.currentUser.CurrentActiveOutlet;

    return hash({
      newRecord: this.store.createRecord('account', {
        userRole: 'Normal',
        outlet: outlet,
      }),
    });
  }
}
