import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthPatienterInsurancecasesNewRoute extends Route {
  @service store;

  async model(params) {
    const patient = await this.store.findRecord('patient', params.patient_id);

    const all_insurancefirms = await this.store.findAll('insurancefirm');

    const all_problems = await this.store.findAll('problem');

    const appointment = await this.store.findRecord(
      'appointment',
      params.appointment_id,
      {
        include: 'problem',
      },
    );

    const newInsuranceCase = await this.store.createRecord('insurancecase', {
      patient: patient,
      problem: appointment.problem,
      start: appointment.time,
      end: appointment.time,
    });

    return hash({
      insurancefirms: all_insurancefirms,
      problems: all_problems,
      newInsurancecase: newInsuranceCase,
    });
  }
}
