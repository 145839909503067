import Helper from '@ember/component/helper';

export default class convertednumber extends Helper {
  compute(positional) {
    const Format_Styles = new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency: 'DKK',
    });

    if (positional && positional.length > 0) {
      let number = positional[0];
      return Format_Styles.format(number);
    } else {
      return '';
    }
  }
}
