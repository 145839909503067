import Model, { attr, belongsTo } from '@ember-data/model';

export default class RoomModel extends Model {
  @attr('string', { defaultValue: '' })
  name;

  @attr('string', { defaultValue: '' })
  nameShort;

  @attr('string', { defaultValue: '' })
  color;

  @attr('boolean', { defaultValue: true })
  showOnStaffView;

  @attr('boolean', { defaultValue: false })
  bookable;

  @attr('number', { defaultValue: 1 })
  seats;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @attr
  created;

  @attr
  updated;
}
