import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerLinkNewRoute extends Route {
  @service store;

  model() {
    return this.store.createRecord('link', {
      titel: '',
      url: '',
      ikon: '',
      order: 99,
    });
  }
}
