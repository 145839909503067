import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import ENV from 'boel/config/environment';
import fetch from 'fetch';
import { debug } from '@ember/debug';
export default class AuthAftalerController extends Controller
{
  @service router;
  @service store;
  @service currentUser;
  @service session;
  @service notifications;
  @service intl;

  @action
  confirm_all()
  {
    this.model.aftaler.forEach((element) =>
    {
      if (!element.confirmed)
      {
        element.confirmed = true;
        element.save();
      }
    });
  }
  @action
  async email()
  {
    const patient_id = this.model.patient_id;

    var kvittering_link = ENV.APP.namespaceRoot;
    if (ENV.APP.namespace)
    {
      kvittering_link += '/' + ENV.APP.namespace;
    }
    kvittering_link += '/appointment_list';

    var headers = {
      'Content-Type': 'application/vnd.api+json',
    };
    if (this.session.isAuthenticated)
    {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    return fetch(kvittering_link + '?patient_id=' + patient_id, {
      dataType: 'text',
      method: 'GET',
      headers: headers,
      cache: 'no-cache',
    })
      .then((response) =>
      {
        if (response.ok)
        {
          if (ENV.APP.ShowNotifications) {
            this.notifications.success(
              this.intl.t('form_appointment.email_send_success'),
            );
          }
          this.router.transitionTo(this.router.currentRoute.parent.name);
        } else
        {
          this.notifications.error(
            this.intl.t('form_appointment.email_send_fejl'),
          );
        }
      })
      .catch((arg1, arg2) =>
      {
        this.notifications.error(
          this.intl.t('form_appointment.email_send_fejl'),
        );
        debug('arg1: ' + arg1);
        debug('arg2: ' + arg2);
      });
  }
  @action
  print()
  {
    window.print();
  }
  @action
  cancel()
  {
    this.router.transitionTo('auth.aftaler');
  }
}
