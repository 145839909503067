import Model, { attr, belongsTo } from '@ember-data/model';

export default class TherapistModel extends Model {
  @attr('string', { defaultValue: '' })
  name;

  @attr
  created;

  @attr
  updated;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;
}
