import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import ENV from 'boel/config/environment';

export default class AuthKabinestyringBetalingerDeleteController extends Controller
{
  @service router;
  @service notifications;
  @service intl;

  @task
  *deleteTask()
  {
    let patient_id = this.router.currentRoute.parent.params.patient_id;

    yield this.model.destroyRecord().then(() =>
    {
      if (ENV.APP.ShowNotifications) {
        this.notifications.success(this.intl.t('form_payment.delete_success'));
      }
      this.router.transitionTo('auth.kabinestyring.betalinger', patient_id, {
        queryParams: { refresh: Date.now() },
      });
    });
  }
  @action
  cancel()
  {
    let patient_id = this.router.currentRoute.parent.params.patient_id;
    this.router.transitionTo('auth.kabinestyring.betalinger', patient_id);
  }
}
