import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthPatienterAftalerNewRoute extends Route {
  @service store;
  @service currentUser;
  @service aftalestatus;
  
  async model(/* params */) {
    let patient_obj = this.modelFor('auth/patienter/aftaler').patient;
    let user_outlet = await this.store.findRecord(
      'outlet',
      this.currentUser.CurrentActiveOutletId
    );
    const State = await this.aftalestatus.FindAftaleStatus(patient_obj.id);

    let amount = 0;

    let newAppointment = await this.store.createRecord('appointment', {
      outlet: user_outlet,
      patient: patient_obj,
      time: new Date(),
      appointmentstate: State,
      amount: patient_obj.amount,
    });

    const states = await this.store.findAll('appointmentstate');
    const treatments = await this.store.findAll('treatment');
    const problems = await this.store.findAll('problem');

    return hash({
      problems: problems,
      states: states,
      treatments: treatments,
      newAppointment: newAppointment,
      closedperiods: this.store.query('closedperiod', {
        filter: "equals(outlet.id,'" + user_outlet.id + "')",
      }),
      openinghours: this.store.query('openinghour', {
        filter: "equals(outlet.id,'" + user_outlet.id + "')",
      }),
    });
  }
}
