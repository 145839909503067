import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { service } from '@ember/service';
import ENV from 'boel/config/environment';
import { isPresent } from '@ember/utils';

export default class ApplicationAdapter extends JSONAPIAdapter {
  @service session;

  host = ENV.APP.host;
  namespace = ENV.APP.namespace;

  get headers() {
    let headers = {};
    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    return headers;
  }
  handleResponse(status, headers, payload /*, requestData */) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
    } else {
      return payload;
    }
  }
  authorize(xhr) {
    let { access_token } = this.session.data.authenticated;
    if (isPresent(access_token)) {
      xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
    }
  }
}
