import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerAfdelingerClosedperiodsNewRoute extends Route {
  @service store;
  @service currentUser;

  async model()
  {
    let parent_model = this.modelFor('auth/superbruger/indstillinger/afdelinger/closedperiods');
    
    if (parent_model)
    {
      const now = new Date();

      let newRecord = await this.store.createRecord('closedperiod', {
        outlet: parent_model.outlet,
        start: now,
        end: now
      });
      return newRecord;
    }
  }
}
