/* import __COLOCATED_TEMPLATE__ from './inline-new-booking.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked, cached } from '@glimmer/tracking';
import DateUtils from 'boel/utils/DateUtils';

export default class AppInlineNewBookingComponent extends Component {
  @service notifications;
  @service store;
  @service currentUser;

  @tracked status;
  @tracked states;
  @tracked day_count;
  @tracked modified;

  constructor(...args) {
    super(...args);
    this.states = this.store.findAll('appointmentstate');
  }
  @cached
  get days() {
    const start_date = this.args.column.date;
    const end_date = this.args.column.next_appointment;
    let current_date = start_date;
    let count = 0;
    let possible_values = [];
    while (current_date < end_date) {
      count++;
      possible_values.push('' + count);
      current_date = DateUtils.AddOrSubtractDays(current_date, 1, true);
    }
    return possible_values;
  }
  get has_valid_input() {
    return this.args.column != null && this.args.row != null;
  }
  @action
  ChangeDays(newVal)
  { 
    this.modified = true;
    this.day_count = newVal;
  }
  @action
  ChangeStatus(newVal)
  { 
    this.modified = true;
    this.status = newVal;
  }
  @action
  update()
  {
    let count = 1;
    let modified = false;
    if (this.args.column != null)
    {
      if (!this.args.column.available)
      {
        count = this.args.column.appointment.bookingdays;
      }
      else
      { 
        modified = true;
      }
    }
    this.modified = modified;
    this.day_count = count;
  }
}
