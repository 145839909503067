import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthSuperbrugerIndstillingerMessagesNewRoute extends Route {
  @service store;
  @service currentUser;

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    controller.set('chosenOutlet', this.currentUser.CurrentActiveOutlet);
  }
  model() {
    let user_outlet = this.currentUser.CurrentActiveOutlet;

    return this.store.createRecord('message', {
      outlet: user_outlet,
    });
  }
}
