import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';

export default class PaymentModel extends Model {
  @service intl;

  @attr('string', { defaultValue: 'DKK' })
  valuta;

  @attr('boolean', { defaultValue: false })
  manuel;

  @attr('boolean', { defaultValue: false })
  paid;

  @attr('number', { defaultValue: 0 })
  amount;

  @attr('number', { defaultValue: 0 })
  patientamount;

  @attr('string', { defaultValue: '' })
  background_class;
   
  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @belongsTo('patient', {
    inverse: null,
    async: false,
  })
  patient;

  @belongsTo('appointment', {
    inverse: null,
    async: false,
  })
  appointment;

  @belongsTo('insurancecase', {
    inverse: null,
    async: false,
  })
  insurancecase;

  @attr
  time;

  @attr
  created;

  @attr
  updated;

  @attr('boolean', { defaultValue: false })
  sendtosygeforsikringendanmark;

  @attr('date')
  sendtosygeforsikringendanmarktime;

  @attr('string', { defaultValue: '' })
  lastStatusCode;
}
