/* import __COLOCATED_TEMPLATE__ from './outlet-chooser.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class AppOutletChooserComponent extends Component {
  @service currentUser;
  @service router;
  
  get PatientID()
  { 
    const qp = this.router.currentRoute?.queryParams;
    const pid = qp['pid'];
    return pid ?? '';
  }
}
