import EmberRouter from '@ember/routing/router';
import config from 'boel/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}
Router.map(function () {
  this.route('auth', function () {
    this.route('udlejning', function () {
      this.route('new');
      this.route('edit');
      this.route('delete');
      this.route('key', { path: '/key/:room_id/:appointment_id' });
    });
    this.route('superbruger', function () {
      this.route('indstillinger', function () {
        this.route('brugere', function () {
          this.route('new');
          this.route('delete', { path: '/delete/:account_id' });
          this.route('edit', { path: '/edit/:account_id' });
          this.route('reopen', { path: '/reopen/:account_id' });
          this.route('kodebrev', { path: '/kodebrev/:account_id' });
          this.route('associations', { path: '/associations/:account_id' });
        });
        this.route('artikler', function () {
          this.route('edit', { path: '/edit/:article_id' });
          this.route('new');
          this.route('delete', { path: '/delete/:article_id' });
        });
        this.route('settings', function () {
          this.route('new');
          this.route('edit', { path: '/edit/:setting_id' });
          this.route('delete', { path: '/delete/:setting_id' });
        });
        this.route('messages', function () {
          this.route('new');
          this.route('edit', { path: '/edit/:message_id' });
          this.route('delete', { path: '/delete/:message_id' });
        });
        this.route('link', function () {
          this.route('new');
          this.route('edit', { path: '/edit/:link_id' });
          this.route('delete', { path: '/delete/:link_id' });
        });
        this.route('afdelinger', function () {
          this.route('new');
          this.route('edit', { path: '/edit/:outlet_id' });
          this.route('delete', { path: '/delete/:outlet_id' });
          this.route(
            'openinghours',
            { path: '/openinghours/:outlet_id' },
            function () {
              this.route('edit', { path: '/edit/:hours_id' });
              this.route('delete', { path: '/delete/:hours_id' });
              this.route('new');
            },
          );
          this.route(
            'closedperiods',
            { path: '/closedperiods/:outlet_id' },
            function () {
              this.route('edit', { path: '/edit/:period_id' });
              this.route('new');
              this.route('delete', { path: '/delete/:period_id' });
            },
          );
        });
        this.route('kabiner', function () {
          this.route('new');
          this.route('edit', { path: '/edit/:room_id' });
          this.route('delete', { path: '/delete/:room_id' });
        });
        this.route('problemer', function () {
          this.route('edit', { path: '/edit/:problem_id' });
          this.route('new');
          this.route('delete', { path: '/delete/:problem_id' });
        });
        this.route('handlinger', function () {
          this.route('edit', { path: '/edit/:action_id' });
          this.route('new');
          this.route('delete', { path: '/delete/:action_id' });
        });
        this.route('aftalestatus', function () {
          this.route('new');
          this.route('edit', { path: '/edit/:state_id' });
          this.route('delete', { path: '/delete/:state_id' });
        });
        this.route('behandlinger', function () {
          this.route('edit', { path: '/edit/:treatment_id' });
          this.route('new');
          this.route('delete', { path: '/delete/:treatment_id' });
        });
        this.route('journaltype', function () {
          this.route('edit', { path: '/edit/:journaltype_id' });
          this.route('new');
          this.route('delete', { path: '/delete/:journaltype_id' });
        });
        this.route('therapists', function () {
          this.route('delete', { path: '/delete/:therapist_id' });
          this.route('edit', { path: '/edit/:therapist_id' });
          this.route('new');
        });
      });
      this.route('rapporter', function () {
        this.route('eksport');
        this.route('diagrammer');
      });
      this.route('log');
      this.route('bills');
      this.route('insurancecompanies', function () {
        this.route('edit', { path: '/edit/:company_id' });
        this.route('new');
        this.route('delete', { path: '/delete/:company_id' });
      });
      this.route('insurancecases', function () {
        this.route('edit', { path: '/edit/:patient_id/:insurancecase_id' });
        this.route('new', { path: '/new/:patient_id/:appointment_id' });
        this.route('delete', {
          path: '/delete/:patient_id/:insurancecase_id',
        });
      });
    });
    this.route('skiftpassword');
    this.route('kabinestyring', function () {
      this.route('aftaler', { path: '/aftaler/:patient_id' }, function () {
        this.route('new', { path: '/new/:appointment_id' });
        this.route('edit', { path: '/edit/:appointment_id' });
        this.route('delete', { path: '/delete/:appointment_id' });
      });
      this.route(
        'betalinger',
        { path: '/betalinger/:patient_id' },
        function () {
          this.route('new');
          this.route('edit', { path: '/edit/:payment_id' });
          this.route('delete', { path: '/delete/:payment_id' });
          this.route('kvittering', { path: '/kvittering/:payment_id' });
        },
      );
      this.route('journal', { path: '/journal/:patient_id' }, function () {
        this.route('new');
        this.route('edit', { path: '/edit/:journal_id' });
        this.route('delete', { path: '/delete/:journal_id' });
      });
    });
    this.route('aftaler', function () {
      this.route('newappointment', { path: '/newappointment/:patient_id' });

      this.route('new', { path: '/:patient_id/new/:appointment_id' });
      this.route('edit', { path: '/:patient_id/edit/:appointment_id' });
      this.route('delete', { path: '/delete/:appointment_id' });
      this.route('newnopatient');

      this.route('journal', { path: '/journal/:patient_id' }, function () {
        this.route('new');
        this.route('edit', { path: '/edit/:journal_id' });
        this.route('delete', { path: '/delete/:journal_id' });
      });
      this.route('aftaler', { path: '/aftaler/:patient_id/:chosen' });
      this.route(
        'betalinger',
        { path: '/betalinger/:patient_id' },
        function () {
          this.route('new');
          this.route('edit', { path: '/edit/:payment_id' });
          this.route('delete', { path: '/delete/:payment_id' });
          this.route('kvittering', { path: '/kvittering/:payment_id' });
        },
      );
      this.route('stamdata', { path: '/stamdata/:patient_id' });
      this.route('patient', { path: '/patient/:patient_id' });
      this.route('newpatient');
      this.route('deletepatient', { path: '/deletepatient/:patient_id' });
      this.route('key', { path: '/key/:room_id/:appointment_id' });
      this.route('merge', { path: '/merge/:patient_id/:to_be_merged_id' });
    });
    this.route('changeoutlet', { path: '/changeoutlet/:outlet_id' });
    this.route('sygeforsikringendanmark');
    this.route('logout');
  });
  this.route('login');
  this.route('help', function ()
  {
    this.route('view', { path: '/view/:article_id' });
    this.route('viewguide', { path: '/viewguide/:bundle_id' });
    this.route('highscore');
  });
});
