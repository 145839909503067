import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthAftalerNewRoute extends Route {
  @service store;
  @service currentUser;
  @service aftalestatus;

  async model(params) {
    let current_appointment = await this.store.findRecord(
      'appointment',
      params.appointment_id,
    );
    let user_outlet = await this.store.findRecord(
      'outlet',
      this.currentUser.CurrentActiveOutletId,
    );
    let patient = await this.store.findRecord('patient', params.patient_id);

    const State = await this.aftalestatus.FindAftaleStatus(params.patient_id);

    let amount = 0;
    let today = new Date();

    let newAppointment = await this.store.createRecord('appointment', {
      time: today,
      outlet: user_outlet,
      patient: patient,
      appointmentstate: State,
      problem: current_appointment.problem,
      treatment: current_appointment.treatment,
      amount: patient.amount,
    });

    const states = await this.store.findAll('appointmentstate');
    const treatments = await this.store.findAll('treatment');
    const problems = await this.store.findAll('problem');

    return hash({
      problems: problems,
      states: states,
      treatments: treatments,

      newAppointment: newAppointment,
      closedperiods: this.store.query('closedperiod', {
        filter: "equals(outlet.id,'" + user_outlet.id + "')",
      }),
      openinghours: this.store.query('openinghour', {
        filter: "equals(outlet.id,'" + user_outlet.id + "')",
      }),
    });
  }
}
