import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import ENV from 'boel/config/environment';
import {
  validatePresence,
  validateNumber,
  validateFormat,
  validateLength,
} from 'ember-changeset-validations/validators';
import error from 'boel/utils/error';
export default class AuthPatienterNewController extends Controller {
  @service notifications;
  @service intl;
  @service router;
  @tracked chosenOutlet;

  validations = {
    lastname: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    /*
    cellphone: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
      validateLength({
        min: 8,
        message: this.intl.t('validations.wrongLength', {
          description: 'Telefonnummeret',
          is: 8,
        }),
      }),
    ],*/
    /*
    email: [
      validateFormat({
        type: 'email',
        allowBlank: true,
        message: this.intl.t('validations.email', {
          description: 'Emailen',
        }),
      }),
    ],
    cpr: [
      validatePresence({
        presence: true,
        on: ['sdmedlem', 'sother'],
        message: this.intl.t('validations.present'),
      }),
      validateLength({
        max: 10,
        message: this.intl.t('validations.wrongLength', {
          description: 'CPR',
          is: 10,
        }),
      })
    ],*/
  };
  @action
  choose(outlet) {
    this.chosenOutlet = outlet;
  }
  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset) {
    changeset.set('outlet', this.chosenOutlet);

    yield changeset
      .save()
      .then(() => {
        if (ENV.APP.ShowNotifications) {
          this.notifications.success(this.intl.t('patienter.create_success'));
        }
        this.router.transitionTo('auth.patienter', {
          queryParams: { refresh: Date.now() },
        });
      })
      .catch((err) => {
        const Message = error.get_message(err);
        this.notifications.error(
          this.intl.t('error.generic', {
            message: Message,
          })
        );
      });
  }

  @action
  cancel() {
    this.router.transitionTo('auth.patienter');
  }
}
