import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
export default class AuthSuperbrugerIndstillingerManualController extends Controller {
  queryParams = ['refresh'];

  @service router;

  @tracked searchWord = '';
  @tracked refresh;

  get create_params() {
    let Not_Important = this.refresh; // Trigger refresh

    let params = {
      sort: 'order,titel',
    };
    if (this.outletId != -1 || this.searchWord) {
      let filterArg = '';
      let Filter_Count = 0;

      if (this.searchWord) {
        if (Filter_Count > 0) {
          filterArg = filterArg + ',';
        }
        filterArg = filterArg + "contains(titel,'" + this.searchWord + "')";
        Filter_Count = Filter_Count + 1;
      }
      if (Filter_Count > 1) {
        filterArg = 'and(' + filterArg + ')';
      }
      if (filterArg) {
        Object.assign(params, { filter: filterArg });
      }
    }
    return params;
  }
  @action
  setSearchValue(searchText) {
    this.searchWord = searchText;
  }
  @action
  navigate_to_new() {
    this.router.transitionTo('auth.superbruger.indstillinger.manual.new');
  }
}
