import Model, { attr, belongsTo } from '@ember-data/model';

export default class OrderlineModel extends Model {
  @attr('string', { defaultValue: '' })
  quantity;

  @attr('string', { defaultValue: '' })
  backside;

  @attr('string', { defaultValue: '' })
  frontside;

  @attr('string', { defaultValue: '' })
  edge;

  @attr('string', { defaultValue: '' })
  surface;

  @belongsTo('product', { async: false })
  product;

  @belongsTo('order', { async: false })
  order;
}
