/* import __COLOCATED_TEMPLATE__ from './inline-insurance.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AppInlineInsuranceComponent extends Component
{
  @service store;

  @tracked all_insurance_firms;
  @tracked all_problems;
  
  @tracked new_insurancecase;
  @tracked edit_insurancecase;
  @tracked insurancecase;
  @tracked all_insurancecase;
  @tracked redraw = 1;
  get State()
  {
    if (this.edit_insurancecase)
    {
      return 'edit';
    }
    if (this.new_insurancecase)
    {
      return 'new';
    } else
    {
      return 'Default';
    }
  }
  
  @action
  async update(event, arg2, arg3, tilbage_til_listen)
  { 
    this.all_insurancecase = await this.store.query('insurancecase', {
      include: 'company,patient,problem',
      sort: '-start'
    });
    this.all_insurance_firms = await this.store.query('insurancefirm', {
      sort: 'name'
    });

    this.all_problems = await this.store.query('problem', {
      sort: 'name'
    });

    if (this.args.insurancecase)
    {
      this.edit(this.args.insurancecase);
    }
    else if (this.args.isnew && tilbage_til_listen == undefined)
    {
      this.new_insurancecase = true;
    }
  }
  @action
  edit(insurancecase)
  {
    this.new_insurancecase = null;
    this.insurancecase = insurancecase;
    this.edit_insurancecase = insurancecase != null;
  }

  @action
  async tilbage_til_liste()
  {
    this.new = null;
    this.insurancecase = null;
    this.new_insurancecase = null;
    this.edit_insurancecase = null;
  
    this.redraw = new Date().getTime();
    this.all_insurancecase = await this.store.query('insurancecase', {
      include: 'company,patient,problem',
      sort: '-start'
    });
    this.patient = this.patient;
  }
  @action
  tilbage()
  {
    this.new = null;
    this.insurancecase = null;
    this.new_insurancecase = null;
    this.edit_insurancecase = null;
    
    this.args.tilbage();
  }
}
