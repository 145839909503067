import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class RapporterRoute extends Route {
  @service session;
  @service router;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');

    if (
      transition &&
      transition.to.name === 'auth.superbruger.rapporter.index'
    ) {
      this.router.transitionTo('auth.superbruger.rapporter.diagrammer');
    }
  }
}
