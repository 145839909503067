import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthSuperbrugerIndstillingerAfdelingerClosedperiodsRoute extends Route {
  @service store;
  queryParams = {
    refreshperiod: {
      refreshModel: true,
    },
  };

  model(params) {
    return hash({
      closed_periods: this.store.query('closedperiod', {
        filter: "equals(outlet.id,'" + params.outlet_id + "')",
        include: 'outlet',
        sort: 'start',
      }),
      outlet: this.store.findRecord('outlet', params.outlet_id),
    });
  }
}
