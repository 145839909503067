import Model, { attr, belongsTo } from '@ember-data/model';

export default class PatientsOutletsModel extends Model {
  @attr
  created;

  @attr
  updated;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @belongsTo('patient', {
    inverse: null,
    async: false,
  })
  patient;
}
