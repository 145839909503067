import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked, cached } from '@glimmer/tracking';
import { service } from '@ember/service';

export default class AuthKabinerController extends Controller
{
  queryParams = ['refresh'];

  @service router;

  @tracked chosenKabinetype = 1;
  @tracked Show_List_View = '1';
  @tracked chosenOutlet = null;
  @tracked outletId = -1;
  @tracked refresh;
  @tracked searchWord = null;

  @cached
  get create_params()
  {
    let Not_Important = this.refresh; // Trigger refresh

    let params = {
      include: 'outlet',
      sort: 'name',
    };
    if (this.outletId != -1 || this.searchWord || this.chosenKabinetype != 2)
    {
      let filterArg = '';
      let Filter_Count = 0;

      if (this.outletId && this.outletId != -1)
      {
        filterArg = filterArg + "equals(outlet.id,'" + this.outletId + "')";
        Filter_Count = Filter_Count + 1;
      }
      if (this.chosenKabinetype != 2)
      { 
        if (Filter_Count > 0)
        {
          filterArg = filterArg + ',';
        }
        let isbookable = this.chosenKabinetype == 0 ? "true" : "false";
        filterArg = filterArg + "equals(bookable,'"+isbookable+"')";
        Filter_Count = Filter_Count + 1;
      }
      if (this.searchWord)
      {
        if (Filter_Count > 0)
        {
          filterArg = filterArg + ',';
        }
        filterArg =
          filterArg +
          "or(contains(name,'" +
          this.searchWord +
          "'),contains(name-short,'" +
          this.searchWord +
          "'))";
        Filter_Count = Filter_Count + 1;
      }
      if (Filter_Count > 1)
      {
        filterArg = 'and(' + filterArg + ')';
      }
      if (filterArg)
      {
        Object.assign(params, { filter: filterArg });
      }
    }
    return params;
  }
  @action
  chooseKabinetype(kabinetype)
  {
    this.chosenKabinetype = kabinetype;
  }
  @action
  setSearchValue(searchText)
  {
    this.searchWord = searchText;
  }
  @action
  navigate_to_new()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.kabiner.new');
  }
  @action
  chooseOutlet(outlet)
  {
    this.chosenOutlet = outlet;

    let id = -1;

    if (outlet)
    {
      id = outlet.id;
    }
    this.outletId = id;
  }
}
