import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { debug } from '@ember/debug';
import error from 'boel/utils/error';
import ENV from 'boel/config/environment';

export default class AuthAftalerNewController extends Controller
{
  @service router;
  @service intl;
  @service notifications;
  @service store;

  validation = {};

  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    let problem = changeset.get('problem');
    if (problem != null)
    {
      if (Array.isArray(changeset.get('time')))
      {
        changeset.set('time', changeset.get('time')[0]);
      }
      // Server does not handle timezone in date
      // and thus the timezoneoffset needs to be
      // added to the datetime.
      changeset.set('time', changeset.get('time'));

      try
      {
        yield changeset
          .save()
          .then(() =>
          {
            if (ENV.APP.ShowNotifications) {
              this.notifications.success(this.intl.t('aftaler.new_success'));
            }
            this.router.transitionTo('auth.aftaler', {
              queryParams: { refresh2: Date.now() },
            });
          })
          .catch((err) =>
          {
            const Message = error.get_message(err);
            this.notifications.error(
              this.intl.t('error.generic', {
                message: Message,
              }),
            );
          });
      } catch (Err)
      {
        debug(Err);
      }
    }
  }
  @action
  cancel()
  {
    this.model.newAppointment.unloadRecord();
    this.router.transitionTo('auth.aftaler');
  }
}
