/* import __COLOCATED_TEMPLATE__ from './measurement-list.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AppMeasurementListComponent extends Component
{
  @service store;
  @service currentUser;
  @service intl;
  @service printThis;

  @tracked journals;
  @tracked Is_Loading = false;

  @action
  print_measurements()
  { 
    const selector = '#measurement-list';
    const options = {
      printDelay: 500,
    };
    this.printThis.print(selector, options);
  }
  @action
  async Fetch_journals()
  {
    if (this.args.patient)
    {
      const patient_id = this.args.patient.id;

      if (patient_id)
      {
        this.Is_Loading = true;
        let j = await this.store.query('journal', {
          filter: "equals(patient.id,'" + patient_id + "')",
          include: 'patient,problem,therapist,journaltype',
          sort: '-time',
        });
        j.forEach((journal) =>
        {
          journal.has_measurement = (journal.result != '' && journal.result != null);
        });
        this.journals = j;
        this.Is_Loading = false;
      }
    }
  }
}
